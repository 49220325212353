

import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import spain from '../../../../assets/img/spaintourist.jpg';
import bank from '../../../../assets/img/bank-account.png';
import attestation from '../../../../assets/img/companydocument.png';
import salary from '../../../../assets/img/receipt.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const SpainTouristVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('spain.touristvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('spain.touristvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, Spain Visa Services, Spanish Visa Assistance, Visa Requirements, Spain Visa Application, Visa Guidance, Travel to Spain, Spanish Tourist Visa, Spain Business Visa, Spain Tourist Visa Services, Spanish Tourist Visa Assistance, Tourist Visa Requirements, Spain Tourist Visa Application, Visa Guidance, Travel to Spain, Spanish Tourist Visa Process, International Tourism in Spain, خدمات تأشيرة السياحة في إسبانيا, مساعدة في تأشيرة السياحة في إسبانيا, متطلبات تأشيرة السياحة في إسبانيا, طلب تأشيرة السياحة في إسبانيا, إرشادات تأشيرة السياحة في إسبانيا, السفر إلى إسبانيا, عملية تأشيرة السياحة الإسبانية, السياحة الدولية في إسبانيا, تونس للإستشارات, خدمات تأشيرة إسبانيا, مساعدة في تأشيرة إسبانيا, متطلبات تأشيرة إسبانيا, طلب تأشيرة إسبانيا, إرشادات تأشيرة إسبانيا, السفر إلى إسبانيا, تأشيرة سياحية إسبانية, تأشيرة عمل إسبانية, تونس للإستشارات و المساعدة'
          />
      </Helmet>
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${spain})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('spain.touristvisa.title')}</h1>
            <NavLink to='/Visa/spain/tourist-visa/application' className="nav-link btn" style={{background: "#0e2b52"}}><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('spain.touristvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('spain.touristvisa.sub1')}</p>
        <p>{t('spain.touristvisa.sub2')}</p>
        <h5 className='req'>{t('spain.touristvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.touristvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('spain.touristvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('spain.touristvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('spain.touristvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${attestation})`}} onClick={() => handleImageClick(attestation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.touristvisa.attravail')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${salary})`}} onClick={() => handleImageClick(salary)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('spain.touristvisa.salary')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bank})`}} onClick={() => handleImageClick(bank)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.touristvisa.bank1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('spain.touristvisa.bank2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${attestation})`}} onClick={() => handleImageClick(attestation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.touristvisa.cnss')}
                </h5>
                </div>
          </div>
            </Col>
            </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </div>
    );
};
export default SpainTouristVisa;