import React from 'react';
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../../../languageUtils';
import Cover from '../../../sections/Cover/Cover';
import {Col, Row} from 'react-bootstrap';
import { NavLink} from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import univ from '../../../../assets/img/university.jpg';
import work_visa from '../../../../assets/img/work_visa.jpg';
import tourist from '../../../../assets/img/spaintourist.jpg';
import residence from '../../../../assets/img/spainresidence.jpg';
import { Helmet } from 'react-helmet-async';

const Spain = () => {
    const {t} = useTranslation();

    function handleClick() {
        window.scrollTo({ top: 0 });
      }

      return (
        <div id='country'>
          <Helmet>
        <title>{t('spain.seoTitle')}</title>
        <meta
          name='description'
          content={t('spain.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, Spain Visa Services, Spanish Visa Assistance, Visa Requirements, Spain Visa Application, Visa Guidance, Travel to Spain, Spanish Tourist Visa, Spain Business Visa, تونس للإستشارات, خدمات تأشيرة إسبانيا, مساعدة في تأشيرة إسبانيا, متطلبات تأشيرة إسبانيا, طلب تأشيرة إسبانيا, إرشادات تأشيرة إسبانيا, السفر إلى إسبانيا, تأشيرة سياحية إسبانية, تأشيرة عمل إسبانية, تونس للإستشارات و المساعدة'
          />
      </Helmet>
            <Element>
                <Animated animationIn="jackInTheBox" animationOut="fadeOut">
                <Cover bgImage={require('../../../../assets/img/spaincover.jpg')}
              title={t('registration.spain')}
              subtitle={t('spain.subtitle')}/>
            <div className='container mt-4 animated bounceInLeft' style={{textAlign: isArabic() ? 'right' : 'left'}}>
                <div className='details '>
                <h6 className='mb-3'>{t('spain.h6')}</h6>
                <p>{t('spain.description1')}</p>
                <p>{t('spain.description2')}</p>
                </div>
        <Row className='g-4 justify-content-center mt-4' xs={1} xl={4} md={4}>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={work_visa} alt={t('spain.businesstitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/spain/business-visa'>{t('spain.businesstitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={univ} alt={t('spain.studenttitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/spain/student-visa'>{t('spain.studenttitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={residence} alt={t('spain.residencetitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/spain/residence-visa'>{t('spain.residencetitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={tourist} alt={t('spain.touristtitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/spain/tourist-visa'>{t('spain.touristtitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
        </Row>
    </div>
                </Animated>
            </Element>
        </div>
      );
};
export default Spain;