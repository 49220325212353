import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row, Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import bank from '../../../../assets/img/financial.png';
import close from '../../../../assets/img/close.png';
import letter from '../../../../assets/img/letter.png';
import visaChina from '../../../../assets/img/Chinese-Universities.jpeg';
import diploma from '../../../../assets/img/diploma.png';
import note from '../../../../assets/img/note.jpg';
import { Helmet } from 'react-helmet-async';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import ReactGA from 'react-ga';



const ChinaStudentVisa = () => {
    const [key, setKey] = useState('tab1');
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='visachina'>
          <Helmet>
        <title>{t('chinastudentvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('chinastudentvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Visa China, Student Visa, Student Visa China, Visa X1 China, Visa X2 China, Visa Assistance, Visa requirements, Tunis Consulting, China, Visa Assistance, China Visa Requirements, Student Visa Process, Visa Application Support, Tunis Consulting Services,Student Visa to China, China Study Visa, Chinese Universities, Visa Application Process, Study Abroad, Tunis Consulting, China Student Visa Requirements, Visa Assistance, Study in China, Student Visa Documents, Visa Application Support, China Education, تأشيرة دراسية إلى الصين, تأشيرة الدراسة في الصين, الجامعات الصينية, عملية تقديم تأشيرة الدراسة, دراسة في الخارج, تونس للإستشارات, متطلبات تأشيرة الدراسة في الصين, مساعدة في تأشيرة الطالب, الدراسة في الصين, وثائق تأشيرة الدراسة, دعم تقديم تأشيرة الدراسة, التعليم في الصين,  تأشيرة الصين, إجراءات التأشيرة, قائمة الوثائق, تأشيرة الأعمال, خدمات الاستشارة, تونس للاستشارات والمساعدة, متطلبات التأشيرة, عملية التقديم, طلب تأشيرة الدراسة في الصين, دعم تقديم التأشيرة, تأشيرة الدراسة الصينية'
        />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
            <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
            <div className='section-header pt-4 pb-3'>
            <div className='base_header mb-4'><span>{t('chinastudentvisa.title')}</span></div>
            <Row xl={2} xs={1} md={2}>
                <Col className='mb-4'><p className='mt-4'>
                {t('chinastudentvisa.sub1')}
          </p>
          <p>
           {t('chinastudentvisa.sub2')}
          </p>
          <p>
           {t('chinastudentvisa.sub3')}
          </p>
        <NavLink to='/Visa/china/student-visa/application' className="nav-link btn">{t('registration.button')}</NavLink>
        </Col>
                <Col>
                <img src={visaChina} alt='Student Visa to China' style={{width: '100%', height: '300px'}}/>
                </Col>
            </Row>
        </div>
<Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="tab1" title={t('chinastudentvisa.visaX1.title')}>
        <h2>{t('chinastudentvisa.visaX1.title')}</h2>
        <p>{t('chinastudentvisa.visaX1.def')}</p>
        <h5 className='req'>{t('chinastudentvisa.visaX1.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinastudentvisa.visaX1.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinastudentvisa.visaX1.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinastudentvisa.visaX1.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinastudentvisa.visaX1.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${letter})`}} onClick={() => handleImageClick(letter)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinastudentvisa.visaX1.letter1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinastudentvisa.visaX1.letter2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${diploma})`}} onClick={() => handleImageClick(diploma)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinastudentvisa.visaX1.diploma')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bank})`}} onClick={() => handleImageClick(bank)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinastudentvisa.visaX1.bank1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinastudentvisa.visaX1.bank2')}
                    </p>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>

      </Tab>
      <Tab eventKey="tab2" title={t('chinastudentvisa.visaX2.title')}>
      <h2>{t('chinastudentvisa.visaX2.title')}</h2>
        <p>{t('chinastudentvisa.visaX2.def')}</p>
        <h5 className='req'>{t('chinastudentvisa.visaX2.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinastudentvisa.visaX2.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinastudentvisa.visaX2.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinastudentvisa.visaX2.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinastudentvisa.visaX2.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${letter})`}} onClick={() => handleImageClick(letter)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinastudentvisa.visaX2.letter1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinastudentvisa.visaX2.letter2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${diploma})`}} onClick={() => handleImageClick(diploma)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinastudentvisa.visaX1.diploma')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bank})`}} onClick={() => handleImageClick(bank)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinastudentvisa.visaX2.bank1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinastudentvisa.visaX2.bank2')}
                    </p>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
      </Tab>
    </Tabs>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default ChinaStudentVisa;