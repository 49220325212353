import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import passport from '../../../../assets/img/passport.png';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import bankAccount from '../../../../assets/img/bank-account.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import india from '../../../../assets/img/india1.jpg';
import hospitalInv from '../../../../assets/img/hospitalInv.png';
import cert from '../../../../assets/img/medical-certificate.png';
import evidence from '../../../../assets/img/evidence.png';
import note from '../../../../assets/img/note.jpg';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const IndiaMedicalVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('india.medicalvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('india.medicalvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, India Medical Visa, Medical Visa Assistance, India Medical Visa Requirements, Medical Visa Application Process, India Healthcare Visa, Tunis Consulting Services, India Medical Visa Documents, Medical Visa Application Support, India Medical Treatment Visa, تونس للإستشارات, تأشيرة العلاج الطبي إلى الهند, مساعدة في تأشيرة العلاج الطبي إلى الهند, متطلبات تأشيرة العلاج الطبي إلى الهند, عملية تقديم تأشيرة العلاج الطبي إلى الهند, تأشيرة الرعاية الصحية في الهند, خدمات تونس للإستشارات, وثائق تأشيرة العلاج الطبي إلى الهند, دعم تقديم تأشيرة العلاج الطبي إلى الهند, تأشيرة العلاج الطبي في الهند, تونس للإستشارات و المساعدة,'
        />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${india})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('india.medicalvisa.title')}</h1>
            <NavLink to='/Visa/india/medical-visa/application' className="nav-link btn"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('india.medicalvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('india.medicalvisa.sub1')}</p>
        <p>{t('india.medicalvisa.sub2')}</p>
        <h5 className='req'>{t('india.medicalvisa.requirements')}</h5>
        <h5 className='req2'>{t('india.medicalvisa.req1')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.medicalvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('india.medicalvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('india.medicalvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('india.medicalvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${hospitalInv})`}} onClick={() => handleImageClick(hospitalInv)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('india.medicalvisa.hospitalInv')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${cert})`}} onClick={() => handleImageClick(cert)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('india.medicalvisa.cert1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('india.medicalvisa.cert2')}
                    </p>
                </div>
          </div>
            </Col>
        </Row>
        <h5 className='req2'>{t('india.medicalvisa.req2')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${passport})`}} onClick={() => handleImageClick(passport)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.medicalvisa.comppass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('india.medicalvisa.comppass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('india.medicalvisa.compphoto1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('india.medicalvisa.compphoto2')}
                    </p>
                </div>
          </div>
            </Col>
            </Row>
            <h5 className='req2'>{t('india.medicalvisa.req3')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <p>{t('india.medicalvisa.own')}</p>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bankAccount})`}} onClick={() => handleImageClick(bankAccount)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.medicalvisa.bank')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <p>{t('india.medicalvisa.comp')}</p>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bankAccount})`}} onClick={() => handleImageClick(bankAccount)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('india.medicalvisa.compba')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2 mt-4'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${evidence})`}} onClick={() => handleImageClick(evidence)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('india.medicalvisa.compev')}
                </h5>
                </div>
          </div>
            </Col>
            </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default IndiaMedicalVisa;