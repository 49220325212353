import React from 'react';
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../../../languageUtils';
import Cover from '../../../sections/Cover/Cover';
import {Col, Row} from 'react-bootstrap';
import { NavLink} from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import univ from '../../../../assets/img/university.jpg';
import work_visa from '../../../../assets/img/work_visa.jpg';
import medical from '../../../../assets/img/medical.jpg';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';


const India = () => {
    const {t} = useTranslation();

    function handleClick() {
        window.scrollTo({ top: 0 });
      }
  
      ReactGA.pageview(window.location.pathname + window.location.search);

      return (
        <div id='country'>
          <Helmet>
        <title>{t('india.seoTitle')}</title>
        <meta
          name='description'
          content={t('india.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA,Tunis Consulting, Visa to India, India Visa Assistance, India Visa Requirements, Visa Application Process, India Travel, Tunis Consulting Services, India Visa Documents, Visa Application Support, India Visit Visa, تونس للإستشارات,شركة تونس للاستشارات و المساعدة, تأشيرة الى الهند,شركة تونس للاستشارات و المساعدة, مساعدة في تأشيرة الهند,شركة تونس للاستشارات و المساعدة, متطلبات تأشيرة الهند,شركة تونس للاستشارات و المساعدة, عملية تقديم تأشيرة الهند,شركة تونس للاستشارات و المساعدة, السفر الى الهند,شركة تونس للاستشارات و المساعدة, خدمات تونس للإستشارات,شركة تونس للاستشارات و المساعدة, وثائق تأشيرة الهند,شركة تونس للاستشارات و المساعدة, دعم تقديم تأشيرة الهند,شركة تونس للاستشارات و المساعدة, تأشيرة زيارة الهند'
        />
      </Helmet>
            <Element>
                <Animated animationIn="jackInTheBox" animationOut="fadeOut">
                <Cover bgImage={require('../../../../assets/img/india1.jpg')}
              title={t('registration.india')}
              subtitle={t('india.subtitle')}/>
            <div className='container mt-4 animated bounceInLeft' style={{textAlign: isArabic() ? 'right' : 'left'}}>
                <div className='details '>
                <h6 className='mb-3'>{t('india.h6')}</h6>
                <p>{t('india.description1')}</p>
                <p>{t('india.description2')}</p>
                </div>
        <Row className='g-4 justify-content-center mt-4' xs={1} xl={3} md={3}>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={work_visa} alt={t('india.businesstitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/india/business-visa'>{t('india.businesstitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={univ} alt={t('india.studenttitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/india/student-visa'>{t('india.studenttitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={medical} alt={t('india.medicaltitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/india/medical-visa'>{t('india.medicaltitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
        </Row>
    </div>
                </Animated>
            </Element>
        </div>
      );
};
export default India;