import React, { useState } from "react";
import AccordionItem from "./AccordionItem";
import { isArabic } from '../../../languageUtils';
import { useTranslation } from 'react-i18next';


const Accordion = ({ questionsAnswers }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const {t} = useTranslation();
  const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
    const showDescription = index === activeIndex ? "show-description" : "";
    const fontWeightBold = index === activeIndex ? "font-weight-bold" : "";
    const ariaExpanded = index === activeIndex ? "true" : "false";
    return (
      <AccordionItem
        showDescription={showDescription}
        fontWeightBold={fontWeightBold}
        ariaExpanded={ariaExpanded}
        item={item}
        index={index}
        onClick={() => {
            setActiveIndex(index === activeIndex ? -1 : index);
        }}
      />
    );
  });

  return (
    <div className="faq m-2">
      <div className='base_header text-center mb-3'><span><small className='bor_header_left'></small>{t('about.faq.title')}<small className='bor_header_right'></small></span></div>
      <dl className="faq__list" style={{
            textAlign: isArabic() ? 'right' : 'left',
          }}>{renderedQuestionsAnswers}</dl>
    </div>
  );
};

export default Accordion;