import React from 'react';
import { useTranslation } from 'react-i18next';
import Cover from '../../../sections/Cover/Cover';
import { isArabic } from '../../../../languageUtils';
import china_univ from '../../../../assets/img/china_univ.jpg';
import {Col, Row} from 'react-bootstrap';
import work_china from '../../../../assets/img/work_china.jpg';
import china_other from '../../../../assets/img/china_other.jpg';
import { NavLink} from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';



const China = () => {
    const {t} = useTranslation();

    ReactGA.pageview(window.location.pathname + window.location.search);

    function handleClick() {
      window.scrollTo({ top: 0 });
    }

    return(
    <div id='country'>
      <Helmet>
        <title>{t('china.seoTitle')}</title>
        <meta
          name='description'
          content={t('china.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting, China Visa, Visa Assistance, China Visa Requirements, Visa Application Process, China Travel, Tunis Consulting Services, China Visa Documents, Visa Application Support, China Visit Visa, تونس للإستشارات, تأشيرة الى الصين, مساعدة في تأشيرة الصين, متطلبات تأشيرة الصين, عملية تقديم تأشيرة الصين, السفر الى الصين, خدمات تونس للإستشارات, وثائق تأشيرة الصين, دعم تقديم تأشيرة الصين, تأشيرة زيارة الصين'        
          />
      </Helmet>
      <Element>
      <Animated animationIn="jackInTheBox" animationOut="fadeOut">
            <Cover bgImage={require('../../../../assets/img/china-cover.png')}
              title={t('registration.china')}
              subtitle={t('china.subtitle')}/>
            <div className='container mt-4 animated bounceInLeft' style={{textAlign: isArabic() ? 'right' : 'left'}}>
                <div className='details '>
                <h6 className='mb-3'>{t('china.h6')}</h6>
                <p>{t('china.description1')}</p>
                <p>{t('china.description2')}</p>
                </div>
        <Row className='g-4 justify-content-center mt-4' xs={1} xl={3} md={3}>
        <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={work_china} alt={t('china.businesstitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/china/business-visa'>{t('china.businesstitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={china_univ} alt={t('china.studenttitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/china/student-visa'>{t('china.studenttitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={china_other} alt={t('china.othertitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/china/personal-visa'>{t('china.othertitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
        </Row>
    </div>
    </Animated>
      </Element>
      </div>
    );
};
export default China;