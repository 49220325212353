import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import logo from '../../../assets/img/logo.png'
import { isArabic } from '../../../languageUtils';

const Nav = () => {
  const [navClass, setNavClass] = useState('');
  const [toggeledNav, settoggeledNav] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let navClass = '';
      if (window.scrollY >= 200) {
        navClass = 'scrolled';
      }
      setNavClass(navClass);
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  function handleClick() {
    settoggeledNav(false);
    window.scrollTo({ top: 0 });
  }

  const handleLanguageToggle = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(newLanguage);
  }

  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className='container'>
        <a className='navbar-brand' href='/'>
          <img src={logo} alt={t('description.logo')} />
          <span>
          {t('description.logo')}
          </span>
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return 'open';
            return '';
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>

        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return 'show';
            return '';
          })()}`}
          style={{
            textAlign: isArabic() ? 'center' : '',
          }}
        >
          <ul className={`navbar-nav ${isArabic() ? 'mr-auto' : 'ml-auto'}`}>
            <li className='nav-item'>
              <NavLink onClick={handleClick} to="/" className="nav-link">
                {t('nav.home')}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink onClick={handleClick} to="/About" className="nav-link">
              {t('nav.about')}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink onClick={handleClick} to="/Services" className="nav-link">
              {t('nav.services')}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink onClick={handleClick} to="/Visa" className="nav-link">
                {t('nav.visa')}
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink onClick={handleClick} to="/Contact" className="nav-link">
                {t('nav.contact')}
              </NavLink>
            </li>
            <li className='nav-item'>
            <NavLink className="nav-link">
            <button className={`language-button ${isArabic() ? 'rtl' : ''}`} onClick={handleLanguageToggle}>
    {i18n.language === 'ar' ? 'English' : 'العربية'}
  </button>
  </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
