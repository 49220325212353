import React from 'react';
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../../../languageUtils';
import Cover from '../../../sections/Cover/Cover';
import {Col, Row} from 'react-bootstrap';
import { NavLink} from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import work_visa from '../../../../assets/img/francebusiness.jpg';
import medical from '../../../../assets/img/medical.jpg';
import tourist from '../../../../assets/img/francetourist.jpg';
import { Helmet } from 'react-helmet-async';

const France = () => {
    const {t} = useTranslation();

    function handleClick() {
        window.scrollTo({ top: 0 });
      }

      return (
        <div id='country'>
          <Helmet>
            <title>{t('france.seoTitle')}</title>
            <meta
              name='description'
              content={t('france.seodescription')}
            />
            <meta
              name='keywords'
              content='Tunis Consulting & Assisting, Tunis Consulting and Assisting,TCA, Tunis Consulting, France Visa Services, French Visa Assistance, Visa Requirements, France Visa Application, Visa Guidance, French Travel Visa, Study in France, Work in France, تونس للإستشارات, خدمات تأشيرة فرنسا, مساعدة في تأشيرة فرنسية, متطلبات التأشيرة, طلب تأشيرة فرنسا, تأشيرة السفر الفرنسية, دراسة في فرنسا, العمل في فرنسا, تونس للاستشارات و المساعدة'
            />
          </Helmet>
            <Element>
                <Animated animationIn="jackInTheBox" animationOut="fadeOut">
                <Cover bgImage={require('../../../../assets/img/francecover.jpg')}
              title={t('registration.france')}
              subtitle={t('france.subtitle')}/>
            <div className='container mt-4 animated bounceInLeft' style={{textAlign: isArabic() ? 'right' : 'left'}}>
                <div className='details '>
                <h6 className='mb-3'>{t('france.h6')}</h6>
                <p>{t('france.description1')}</p>
                <p>{t('france.description2')}</p>
                </div>
        <Row className='g-4 justify-content-center mt-4' xs={1} xl={3} md={3}>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={work_visa} alt={t('france.businesstitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/france/business-visa'>{t('france.businesstitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={medical} alt={t('france.medicaltitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/france/medical-visa'>{t('france.medicaltitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={tourist} alt={t('france.touristtitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/france/tourist-visa'>{t('france.touristtitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
        </Row>
    </div>
                </Animated>
            </Element>
        </div>
      );
};
export default France;