import React from "react";
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import china from '../../assets/img/china.jpeg';
import india from '../../assets/img/india.png';
import southafrica from '../../assets/img/south-africa.png';
import germany from '../../assets/img/germany.png';
import france from '../../assets/img/france.png';
import spain from '../../assets/img/spain.png';
import uk from '../../assets/img/uk.png';
import belgium from '../../assets/img/belgium.png';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet-async';


const Visa = () => {
  const {t} = useTranslation();
  const descriptiondata = [
    {
      name: t('registration.china'),
      image: china,
      url: '/Visa/china',
    },
    {
      name: t('registration.india'),
      image: india,
      url: '/Visa/india',
    },
    {
      name: t('registration.southafrica'),
      image: southafrica,
      url: '/Visa/south-africa',
    },
    {
      name: t('registration.germany'),
      image: germany,
      url: '/Visa/germany',
    },
    {
      name: t('registration.france'),
      image: france,
      url: '/Visa/france',
    },
    {
      name: t('registration.spain'),
      image: spain,
      url: '/Visa/spain',
    },
    {
      name: t('registration.uk'),
      image: uk,
      url: '/Visa/united-kingdom',
    },
    {
      name: t('registration.belgium'),
      image: belgium,
      url: '/Visa/belgium',
    },
  ];

  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
     <div id='visa'>
      <Helmet>
      <title>{t('registration.seoTitle')}</title>
      <meta
          name='description'
          content={t('registration.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting, China Visa, visa, visa france, france visa, tls contact, visa china, visa india, visa germany, visa south africa, visa spain, visa uk, visa united kingdom, visa germany, visa services, import and export services, embassy certification, meeting and conference organization, hotel reservations, collaboration with charitable organizations and associations,Tunis Consulting, About Us, Visa and Immigration Services, Mission, Values, Expert Team, Visa Application Process, Immigration Assistance, Trusted Consultants,تونس للإستشارات، حولنا، خدمات تأشيرات وهجرة، مهمتنا، قيمنا، فريق خبير، عملية تقديم الطلبات للتأشيرات، مساعدة في الهجرة، مستشارون موثوق بهم, تأشيرة, فيزا, تأشيرة الصين , تأشيرة فرنسا, فيزا فرنسا,تأشيرة الهند, , تأشيرة فرنسا, تأشيرة ألمانيا, تأشيرة اسبانيا, تأشيرة انقلترا, تأشيرة جنوب افريقيا, فيزا فرنسا, فيزا ألمانيا, فيزا,تأشيرة جنوب افريقيا, خدمات الإستيراد و التصدير, خدمات التأشيرات, تنظيم الإجتماعات و المؤتمرات, خدمات التصديق السفاري, التعاون مع المنظمات الخيرية و الجمعيات, حجوزات الفنادق,'        
          />
      </Helmet>
      <Element>
      <Animated animationIn="bounceInUp" animationOut="fadeOut">
            <div className="container">
            <div className='section-header pt-4 pb-3 text-center'>
      <div className='base_header mb-2'><span><small className='bor_header_left'></small>{t('registration.title')}<small className='bor_header_right'></small></span>
      </div>
          <h6 className='section-subtitle mr-auto ml-auto'>
          {t('registration.intro')}
          </h6>
        </div>
            <Row className="g-4">
      {descriptiondata.map((item, idx) => (
        <Col md={4} sm={4} xs={6} xl={4} key={idx}>
          <div className="mb-4 listing-item">
            <div className="listing-image">
            <img variant="top" src={item.image} alt={item.name} />
            </div>
            <div className="listing-item-content">
            <h2><NavLink to={item.url}>{item.name}</NavLink></h2>
            </div>
          </div>
        </Col>
      ))}
    </Row>
    </div>
    </Animated>
      </Element>
        </div>
    );
};
export default Visa;