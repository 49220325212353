import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import insurance from '../../../../assets/img/health-insurance.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import uk from '../../../../assets/img/ukcover.jpg';
import invitation from '../../../../assets/img/invitation.png';
import receipt from '../../../../assets/img/receipt.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const UnitedKingdomStudentVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [clickedImage, setClickedImage] = useState('');
    const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('uk.studentvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('uk.studentvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, United Kingdom Visa Services, UK Visa Assistance, Visa Requirements, UK Visa Application, Visa Guidance, Travel to the UK, British Visa Process, International Travel to the United Kingdom, United Kingdom Student Visa, UK Student Visa Assistance, Student Visa Requirements, UK Student Visa Application, Visa Guidance, Study in the UK, British Student Visa Process, International Student Travel to the United Kingdom, تأشيرة الدراسة في المملكة المتحدة, مساعدة في تأشيرة الدراسة, متطلبات تأشيرة الدراسة في المملكة المتحدة, طلب تأشيرة الدراسة في بريطانيا, إرشادات تأشيرة الدراسة, الدراسة في المملكة المتحدة, عملية تأشيرة الدراسة في المملكة المتحدة, السفر للدراسة في المملكة المتحدة, خدمات تأشيرة بريطانيا, مساعدة في تأشيرة بريطانيا, متطلبات تأشيرة بريطانيا, طلب تأشيرة بريطانيا, إرشادات تأشيرة بريطانيا, السفر إلى بريطانيا, عملية تأشيرة بريطانيا, السفر الدولي إلى بريطانيا'
          />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${uk})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('uk.studentvisa.title')}</h1>
            <NavLink to='/Visa/united-kingdom/student-visa/application' className="nav-link btn1"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('uk.studentvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('uk.studentvisa.sub1')}</p>
        <p>{t('uk.studentvisa.sub2')}</p>
        <h5 className='req'>{t('uk.studentvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.studentvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('uk.studentvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('uk.studentvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('uk.studentvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invitation})`}} onClick={() => handleImageClick(invitation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.studentvisa.inv')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${receipt})`}} onClick={() => handleImageClick(receipt)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.studentvisa.res')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${insurance})`}} onClick={() => handleImageClick(insurance)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.studentvisa.ins1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('uk.studentvisa.ins2')}
                </p>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default UnitedKingdomStudentVisa;