import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row, Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import birthCert from '../../../../assets/img/birth_cert.png';
import close from '../../../../assets/img/close.png';
import invitation from '../../../../assets/img/invitation.png';
import visaChina from '../../../../assets/img/family-china.jpeg';
import residencePermit from '../../../../assets/img/Residence_Permit.jpg';
import passport from '../../../../assets/img/passport.png';
import visaM from '../../../../assets/img/China-Visa.jpg';
import house from '../../../../assets/img/building.png';
import note from '../../../../assets/img/note.jpg';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';


const ChinaPersonalVisa = () => {
    const [key, setKey] = useState('tab1');
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='visachina'>
          <Helmet>
        <title>{t('chinapersonalvisa.seoTitle')}</title>
        <meta
          name='description'
          content='Your page description goes here.'
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Visa China, Visa S1 China, Visa Q China, Visa S2 China, Visa Assistance, Visa requirements, Tunis Consulting, China, Visa Assistance, China Visa Requirements, Visa Application Support, Tunis Consulting Services,Family visit visa China, China visa application guide, Document requirements for family visa, Passport, photo, birth certificate for China visa, Family visa application steps, Invitation for family visit to China, Residence permit for family visa, China visa photo requirements, Family relationship documentation, Tunis Consulting family visa services,تأشيرة الصين, إجراءات التأشيرة, قائمة الوثائق, خدمات الاستشارة, تونس للاستشارات والمساعدة, متطلبات التأشيرة, عملية التقديم, دعم تقديم التأشيرة,تأشيرة زيارة عائلية إلى الصين, دليل تقديم تأشيرة الصين, متطلبات الوثائق لتأشيرة العائلة, جواز السفر, الصورة الشخصية, شهادة الولادة لتأشيرة الصين, خطوات تقديم طلب تأشيرة العائلة, الدعوة لزيارة عائلية إلى الصين, تصريح الإقامة لتأشيرة العائلة, متطلبات الصورة لتأشيرة العائلة, وثائق علاقات العائلة, خدمات تونس لتأشيرة العائلة'
        />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
            <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
            <div className='section-header pt-4 pb-3'>
            <div className='base_header mb-4'><span>{t('chinapersonalvisa.title')}</span></div>
            <Row xl={2} xs={1} md={2}>
                <Col className='mb-4'><p className='mt-4'>
                {t('chinapersonalvisa.sub1')}
          </p>
          <p>
           {t('chinapersonalvisa.sub2')}
          </p>
          <p>
           {t('chinapersonalvisa.sub3')}
          </p>
         <NavLink to='/Visa/china/personal-visa/application' className="nav-link btn">{t('registration.button')}</NavLink>
</Col>
                <Col>
                <img src={visaChina} alt='Personal Visa to China' style={{width: '100%', height: '300px'}}/>
                </Col>
            </Row>
        </div>
<Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="tab1" title={t('chinapersonalvisa.visaS.title')}>
        <h2>{t('chinapersonalvisa.visaS.title')}</h2>
        <p>{t('chinapersonalvisa.visaS.def1')}</p>
        <h5 className='req'>{t('chinapersonalvisa.visaS.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinapersonalvisa.visaS.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinapersonalvisa.visaS.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinapersonalvisa.visaS.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${birthCert})`}} onClick={() => handleImageClick(birthCert)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.acte1')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invitation})`}} onClick={() => handleImageClick(invitation)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.invitation')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${passport})`}} onClick={() => handleImageClick(passport)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.pass3')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${residencePermit})`}} onClick={() => handleImageClick(residencePermit)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.residence')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${birthCert})`}} onClick={() => handleImageClick(birthCert)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.acte2')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${house})`}} onClick={() => handleImageClick(house)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.house')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
      </Tab>
      <Tab eventKey="tab2" title={t('chinapersonalvisa.visaS.title2')}>
        <h2>{t('chinapersonalvisa.visaS.title2')}</h2>
        <p>{t('chinapersonalvisa.visaS.def2')}</p>
        <p>{t('chinapersonalvisa.visaS.def3')}</p>
        <h5 className='req'>{t('chinapersonalvisa.visaS.requirements2')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinapersonalvisa.visaS.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinapersonalvisa.visaS.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinapersonalvisa.visaS.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${birthCert})`}} onClick={() => handleImageClick(birthCert)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.acte1')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${birthCert})`}} onClick={() => handleImageClick(birthCert)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.acte2')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invitation})`}} onClick={() => handleImageClick(invitation)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.invitation')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${passport})`}} onClick={() => handleImageClick(passport)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.passM')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${visaM})`}} onClick={() => handleImageClick(visaM)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.visaM')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
      </Tab>
      <Tab eventKey="tab3" title={t('chinapersonalvisa.visaQ.title')}>
      <h2>{t('chinapersonalvisa.visaQ.title')}</h2>
        <p>{t('chinapersonalvisa.visaQ.def')}</p>
        <h5 className='req'>{t('chinapersonalvisa.visaQ.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinapersonalvisa.visaQ.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinapersonalvisa.visaQ.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaQ.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinapersonalvisa.visaQ.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invitation})`}} onClick={() => handleImageClick(invitation)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaQ.invitation')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${passport})`}} onClick={() => handleImageClick(passport)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaQ.pass4')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${birthCert})`}} onClick={() => handleImageClick(birthCert)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaQ.kinship1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinapersonalvisa.visaQ.kinship2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${residencePermit})`}} onClick={() => handleImageClick(residencePermit)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinapersonalvisa.visaS.residence')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
      </Tab>
    </Tabs>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default ChinaPersonalVisa;