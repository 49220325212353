import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel, {
  autoplayPlugin,
  slidesToShowPlugin,
  arrowsPlugin
} from "@brainhubeu/react-carousel";
import Icon from 'react-fa';
import "@brainhubeu/react-carousel/lib/style.css";

import embassyCert from '../../../assets/img/embassyCert.jpg';
import visa from '../../../assets/img/visa.png';
import deal from '../../../assets/img/deal.jpg';
import importPic from '../../../assets/img/import.jpg';
import conference from '../../../assets/img/conference.jpg'
import hotel from '../../../assets/img/hotel.jpg'

const Service = () => {

  const {t} = useTranslation();

const data = [
  {
    id:1,
    image: visa,
    title: t('home.services.card1.title'),
    details: t('home.services.card1.description')
  },
  {
    id:2,
    image: embassyCert,
    title: t('home.services.card2.title'),
    details: t('home.services.card2.description')
  },
  {
    id:3,
    image: importPic,
    title: t('home.services.card3.title'),
    details: t('home.services.card3.description')
  },
  {
    id:4,
    image: conference,
    title: t('home.services.card4.title'),
    details: t('home.services.card4.description')
  },
  {
    id:5,
    image: hotel,
    title: t('home.services.card5.title'),
    details: t('home.services.card5.description')
  },
  {
    id:6,
    image: deal,
    title: t('home.services.card6.title'),
    details: t('home.services.card6.description')
  }
]

  return (
    <div id='service'>
      <div className='container pt-2 pb-5 mb-5'>
        <div className='section-header pt-4 pb-3 text-center'>
          <h3 className='section-title'>
            <span>
              {t('home.services.title1')}</span>
              {t('home.services.title2')}
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
            {t('home.services.description')}
          </h6>
        </div>
        <div className='section-content'>
          <Carousel
          plugins={[
            "infinite",
            "rtl",
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: <button className='arrow'><Icon name="angle-double-left" /></button>,
                arrowLeftDisabled:<button className='arrow'><Icon name="angle-left" /></button>,
                arrowRight: <button className='arrow'><Icon name="angle-double-right" /></button>,
                arrowRightDisabled: <button className='arrow'><Icon name="angle-right" /></button>,
                addArrowClickHandler: true,
              }
            },
            {
              resolve: autoplayPlugin,
              options: {
                interval: 2000,
              },
            },
            {
              resolve: slidesToShowPlugin,
              options: {
               numberOfSlides: 3
              }
            },
          ]}
          breakpoints={{
            640: {
              plugins: [
               {
                 resolve: slidesToShowPlugin,
                 options: {
                  numberOfSlides: 1
                 }
               },
             ]
            },
            900: {
              plugins: [
               {
                 resolve: slidesToShowPlugin,
                 options: {
                  numberOfSlides: 2
                 }
               },
             ]
            }
          }}
          slides={data.map((data) => (
            <div className='card rounded-0 mr-2 ml-2' key={data.id}>
            <img src={data.image} className='card-img-top' alt='Visa services' />
            <div className='card-body'>
              <h5 className='card-title'>
              {data.title}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {data.details}
                    </p>
                </div>
          </div>))}
          animationSpeed={2000}
        />
        </div>
      </div>
    </div>
  );
};

export default Service;
