import React from 'react';
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../../../languageUtils';
import Cover from '../../../sections/Cover/Cover';
import {Col, Row} from 'react-bootstrap';
import { NavLink} from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import univ from '../../../../assets/img/university.jpg';
import work_visa from '../../../../assets/img/work_visa.jpg';
import tourist from '../../../../assets/img/sftourist.jpg';
import family from '../../../../assets/img/family_visa.jpg';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const SouthAfrica = () => {
    const {t} = useTranslation();

    function handleClick() {
        window.scrollTo({ top: 0 });
      }
  
      ReactGA.pageview(window.location.pathname + window.location.search);

      return (
        <div id='country'>
          <Helmet>
        <title>{t('southafrica.seoTitle')}</title>
        <meta
          name='description'
          content={t('southafrica.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, South Africa Visa, Visa Assistance, South Africa Visa Requirements, Visa Application Process, South Africa Travel Visa, Tunis Consulting Services, South Africa Visa Documents, Visa Application Support, South Africa Business Visa, Tourist Visa for South Africa, تونس للإستشارات, تأشيرة جنوب أفريقيا, مساعدة في تأشيرة جنوب أفريقيا, متطلبات تأشيرة جنوب أفريقيا, عملية تقديم تأشيرة جنوب أفريقيا, تأشيرة سفر لجنوب أفريقيا, تونس للإستشارات و المساعدة,'
        />
      </Helmet>
                <Cover bgImage={require('../../../../assets/img/southafrica.jpeg')}
              title={t('registration.southafrica')}
              subtitle={t('southafrica.subtitle')}/>
            <div className='container mt-4 animated bounceInLeft' style={{textAlign: isArabic() ? 'right' : 'left'}}>
                <div className='details '>
                <h6 className='mb-3'>{t('southafrica.h6')}</h6>
                <p>{t('southafrica.description1')}</p>
                <p>{t('southafrica.description2')}</p>
                </div>
                <Element>
                <Animated animationIn="slideInUp" animationOut="fadeOut">
        <Row className='g-4 justify-content-center mt-4' xs={1} xl={4} md={4}>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={work_visa} alt={t('southafrica.businesstitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/south-africa/business-visa'>{t('southafrica.businesstitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={univ} alt={t('southafrica.studenttitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/south-africa/student-visa'>{t('southafrica.studenttitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={tourist} alt={t('southafrica.touristtitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/south-africa/tourist-visa'>{t('southafrica.touristtitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={family} alt={t('southafrica.familytitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/south-africa/family-visa'>{t('southafrica.familytitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
        </Row>
        </Animated>
            </Element>
    </div>
        </div>
      );
};
export default SouthAfrica;