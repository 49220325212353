
import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import germany from '../../../../assets/img/germanytourist.jpg';
import bank from '../../../../assets/img/bank-account.png';
import attestation from '../../../../assets/img/companydocument.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const GermanyTouristVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
            <title>{t('germany.touristvisa.seoTitle')}</title>
            <meta
              name='description'
              content={t('germany.touristvisa.seodescription')}
            />
            <meta
              name='keywords'
              content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA,Tunis Consulting, Germany Visa Services, German Visa Assistance, Visa Requirements, Germany Visa Application, Immigration Services, Visa Guidance, German Visa Types, Germany Tourist Visa Services, German Tourist Visa Assistance, Visa Requirements, Germany Tourist Visa Application, Tourism Visa, Visa Guidance, German Travel Visa, تونس للإستشارات, خدمات تأشيرات ألمانيا, مساعدة في تأشيرات ألمانيا, متطلبات التأشيرة, طلب تأشيرة ألمانيا, خدمات الهجرة, إرشادات التأشيرة, أنواع تأشيرات ألمانيا, تونس للإستشارات و المساعدة,  خدمات تأشيرة السياحة في ألمانيا, مساعدة في تأشيرة السياحة الألمانية, متطلبات التأشيرة, طلب تأشيرة السياحة في ألمانيا, تأشيرة السياحة, إرشادات التأشيرة, تأشيرة السفر الألمانية'
            />
          </Helmet>
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${germany})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('germany.touristvisa.title')}</h1>
            <NavLink to='/Visa/germany/tourist-visa/application' className="nav-link btn" style={{background: "#0e2b52"}}><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('germany.touristvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('germany.touristvisa.sub1')}</p>
        <p>{t('germany.touristvisa.sub2')}</p>
        <h5 className='req'>{t('germany.touristvisa.requirements')}</h5>
        <h5 className='req2'>{t('germany.touristvisa.presence')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('germany.touristvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('germany.touristvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('germany.touristvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('germany.touristvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${attestation})`}} onClick={() => handleImageClick(attestation)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('germany.medicalvisa.fam')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${attestation})`}} onClick={() => handleImageClick(attestation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('germany.touristvisa.attravail')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bank})`}} onClick={() => handleImageClick(bank)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('germany.touristvisa.bank')}
                </h5>
                </div>
          </div>
            </Col>
            </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </div>
    );
};
export default GermanyTouristVisa;