import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Animated} from "react-animated-css";
import aboutImage from '../../../assets/img/about.jpg';
import { isArabic } from '../../../languageUtils';
import { Link } from 'react-router-dom';
import { Element, animateScroll } from 'react-scroll';
import ReactGA from 'react-ga';

const About = () => {

  const {t} = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const aboutSection = document.getElementById('about');
      if(aboutSection) {
        const aboutSectionTop = aboutSection.getBoundingClientRect().top;
        if(scrollY >= aboutSectionTop) {
          setIsVisible(true);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  ReactGA.pageview(window.location.pathname + window.location.search);
  
  return (
    <div id='about'>
      <Element name='about'>
      <Animated animationIn="bounceInUp" animationOut="fadeOut" isVisible={isVisible}>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-4 text-center'>
          <h3 className='section-title'>
            <span>
            {t('home.about.title1')}</span>
              {t('home.about.title2')}
          </h3>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <div className='aboutImage'>
                <img src={aboutImage} alt='about company' />
              </div>
            </div>
            <div className='col-md-12 col-lg-6' style={{
            textAlign: isArabic() ? 'right' : 'left',
          }}>
              <h3 className='about-title'>
                {t('home.about.title3')}
              </h3>
              <div className='about-description'>
                <p>
                  {t('home.about.description1')}
                </p>
                <p>
                {t('home.about.description2')}
                </p>
                <p>
                {t('home.about.description3')}
                </p>
                <Link to="/About">
                <button className='btn about-button rounded-0'>{t('home.about.button')}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Animated>
      </Element>
    </div>
  );
};

export default About;
