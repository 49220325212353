import * as React from 'react';

const styleTitle = {
  color: '#0e2b52',
  fontFamily: 'El Messiri',
  fontSize: '1.3rem',
};


export default function card({ image, title, description }) {
  return (
    <>
      <div className="col-md-6 col-10 mx-auto">
        <div className="card">
          <img src={image} className="card-img-top" alt={title} />
          <div className="card-body">
            <h5 className="card-title" style={styleTitle}>{title}</h5>
            <p className="card-text">
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}