import React from 'react';
import { useTranslation } from 'react-i18next';

import aboutImage from '../../../assets/img/abt.png';
import { isArabic } from '../../../languageUtils';

const Abt = () => {

  const {t} = useTranslation();

  return (
    <div id='abt' className='container mb-5' style={{marginTop: '3%'}}>
        <div className={`row ${ isArabic() ? 'rtl' : ''}` }>
            <div className='col-md-12 col-lg-6 animated bounceInRight'>
                <div className='container'><img src={aboutImage} style={{width: '100%'}} alt={t('description.logo')}/></div>
            </div>
            <div className='col-md-12 col-lg-6 animated bounceInLeft'>
                <div className='container' style={{marginTop:'7%', textAlign: isArabic() ? 'right' : 'left'}}>
                    <div className='base_header'><span><small className='bor_header_left'></small>{t('about.title')}<small className='bor_header_right'></small></span>
                    <h3>{t('description.logo')}</h3>
                    </div>
                    <div className='base_footer'>
                        <p>
                        {t('about.description1')} <br /> 
                        {t('about.description2')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Abt;
