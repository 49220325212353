
import React from 'react';
import ReactGA from 'react-ga';



const BelgiumBusinessVisaForm = () => {
      
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='standard'>
            <iframe id="" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://my.forms.app/form/658aa9dd1c679e6fb9c1b98e" frameborder="0" style={{width: '100vw', minWidth: '100%', height: '373vh', border: 'none'}}></iframe>        
        </div>
    );
};
export default BelgiumBusinessVisaForm;