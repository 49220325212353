import React from "react";
import ReactGA from 'react-ga';


const IndiaMedicalVisaForm = () => {
      
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id="standard">
            <iframe id="" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://my.forms.app/form/656227f8ef3c8bbf134d9d79" frameborder="0" style={{width: '100vw', minWidth: '100%', height: '373vh', border: 'none'}}></iframe>
        </div>
    );
};
export default IndiaMedicalVisaForm;