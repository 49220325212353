import React from 'react';

const mapMarker = () => {
  return (
    <div id='map'>
      <div>
        <iframe
          title='mapMarker'
          src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1597.0127573893026!2d10.17887316244797!3d36.817908506009964!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35c266359ced%3A0x84982cd149a0301a!2z2LTYsdmD2Kkg2KrZiNmG2LMg2YTZhNil2LPYqti02KfYsdin2Kog2YjYp9mE2YXYs9in2LnYr9ip!5e0!3m2!1sfr!2stn!4v1694799999943!5m2!1sfr!2stn'
          width='100%'
          height='450'
          frameBorder='0'
          style={{ border: 0, marginBottom: '-7px' }}
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default mapMarker;
