import React from 'react';
import ReactGA from 'react-ga';



const FranceBusinessVisaForm = () => {
      
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='standard'>
            <iframe id="" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://my.forms.app/form/6576e3521545e4858cc1ed6e" frameborder="0" style={{width: '100vw', minWidth: '100%', height: '373vh', border: 'none'}}></iframe>
        </div>
    );
};
export default FranceBusinessVisaForm;