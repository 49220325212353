import React from 'react';
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../../languageUtils';
import logo from '../../../assets/img/logo.png'
import bgImage from '../../../assets/img/footer.jpg';

const Footer = () => {
  const {t} = useTranslation();
  return (
    <footer className=''>
      <div className='footer-container mt-3' style={{ backgroundImage: `url(${bgImage})` }}>
      <div className='container text-light pt-5 pb-4' style={{
            textAlign: isArabic() ? 'right' : 'left',
          }}>
        <div className='row'>
        <div className='col-sm-6 col-md-6 col-lg-5 mt-3'>
            <div className='footer-title'>
            <img src={logo} alt="شركة تونس للإستشارات والمساعدة" />
              <ul style={{listStyleType:'none', paddingRight: 0, paddingLeft: 0}}>
                <li>              
                <h6>
                {t('description.logo')}
                </h6>
                </li>
                <li className='pt-2'>
                <h6>
                  {t('description.desc1')}
                </h6>
                </li>
                <li className='mt-3'>
            <div className='d-flex pt-2 pb-2'>
            <div className='social-media d-flex align-item-center'>
              <a href='https://www.facebook.com/profile.php?id=100088803589683' className='text-light' target="_blank" rel="noreferrer">
                <i className='fab fa-facebook-f mr-4' />
              </a>
              <a href='https://www.instagram.com/tunis_consulting/' className='text-light' target="_blank" rel="noreferrer">
                <i className='fab fa-instagram mr-4' />
              </a>
              <a href='https://goo.gl/maps/5m14kUpCrmtq2hSd9' className='text-light' target="_blank" rel="noreferrer"> 
                <i className='fas fa-map-marker-alt mr-4' /> 
              </a>
          </div>
            </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 mt-3'>
            <div className='footer-title'>
              <h6>
              {t('footer.link1')}
              </h6>
            </div>
            <div className='footer-content'>
              <ul className='list-group quick-links'>
                <li>
                  <a href='/'>
                  {t('nav.home')}
                  </a>
                </li>
                <li>
                  <a href='/About'>{t('nav.about')}</a>
                </li>
                <li>
                  <a href='/Services'>{t('nav.services')}</a>
                </li>
                <li>
                  <a href='/Visa'>{t('nav.visa')}</a>
                </li>
                <li>
                  <a href='/Contact'>{t('nav.contact')}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 mt-3'>
            <div className='footer-title'>
              <h6>
                {t('footer.link2')}
              </h6>
            </div>
            <div className='footer-content' >
            <ul className='list-group quick-links'>
              <li>
                <span>
                <div className='d-flex align-item-center'>
              <i className={`fas fa-phone-alt ${isArabic() ? 'ml-2' : 'mr-2'}`}/>
              <small style={{direction:'ltr'}}>+216 28 846 888</small>
            </div>
              </span>
              </li>
              <li>
                <span>
                <div className='d-flex align-item-center'>
              <i className={`fas fa-mobile-alt ${isArabic() ? 'ml-2' : 'mr-2'}`}/>
              <small style={{direction:'ltr'}}>+216 29 190 039</small>
            </div>
              </span>
              </li>
              <li>
                <span>
                <div className='d-flex align-item-center'>
              <i className={`fas fa-envelope ${isArabic() ? 'ml-2' : 'mr-2'}`}/>
              <small style={{direction:'ltr'}}>info@tunis-consulting.com</small>
            </div>
              </span>
              </li>
              <li>
                <span>
                <div className='d-flex align-item-center'>
              <i className={`fas fa-map-marker-alt ${isArabic() ? 'ml-2' : 'mr-2'}`}/>
              <small style={{direction:'ltr'}}>
                {t('description.address')}
              </small>
            </div>
              </span>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>
          {t('footer.bottom')}
          </small>
      </div>
    </footer>
  );
};

export default Footer;
