import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import bankAccount from '../../../../assets/img/bank-account.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import spain from '../../../../assets/img/spaincover.jpg';
import birthcert from '../../../../assets/img/birth_cert.png';
import attestation from '../../../../assets/img/companydocument.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const SpainResidenceVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [clickedImage, setClickedImage] = useState('');
    const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('spain.residencevisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('spain.residencevisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, Spain Visa Services, Spanish Visa Assistance, Visa Requirements, Spain Visa Application, Visa Guidance, Travel to Spain, Spanish Tourist Visa, Spain Business Visa, Spain Residence Visa Services, Spanish Residence Visa Assistance, Residence Visa Requirements, Spain Residence Visa Application, Visa Guidance, Residency in Spain, Spanish Residence Visa Process, International Residents in Spain, تونس للإستشارات, خدمات تأشيرة إسبانيا, مساعدة في تأشيرة إسبانيا, متطلبات تأشيرة إسبانيا, طلب تأشيرة إسبانيا, إرشادات تأشيرة إسبانيا, خدمات تأشيرة الإقامة في إسبانيا, مساعدة في تأشيرة الإقامة في إسبانيا, متطلبات تأشيرة الإقامة في إسبانيا, طلب تأشيرة الإقامة في إسبانيا, إرشادات تأشيرة الإقامة في إسبانيا, الإقامة في إسبانيا, عملية تأشيرة الإقامة الإسبانية, المقيمون الدوليون في إسبانيا ,السفر إلى إسبانيا, تأشيرة سياحية إسبانية, تأشيرة عمل إسبانية, تونس للإستشارات و المساعدة'
          />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${spain})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('spain.residencevisa.title')}</h1>
            <NavLink to='/Visa/spain/residence-visa/application' className="nav-link btn"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('spain.residencevisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('spain.residencevisa.sub1')}</p>
        <h5 className='req'>{t('spain.residencevisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.residencevisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('spain.residencevisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('spain.residencevisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('spain.residencevisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${attestation})`}} onClick={() => handleImageClick(attestation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.residencevisa.att')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bankAccount})`}} onClick={() => handleImageClick(bankAccount)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.residencevisa.chamber')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${birthcert})`}} onClick={() => handleImageClick(birthcert)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.residencevisa.fam')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default SpainResidenceVisa;