import React from 'react';
import { FiPhone, FiMail, FiPhoneCall } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../../languageUtils';

const Contact_component = () => {
  const {t} = useTranslation();

    return (
        <>
        <div id='contact' className='container' style={{ marginTop: "3%" }}>
        <div className='base_header mb-4 text-center'>
                <span>
                  <small className='bor_header_left'></small>{t('contact.title3')}
                  <small className='bor_header_right'></small>
                </span>
              </div>        
        <div className='row pt-4'>
          <div className='col-md-12 col-lg-6'>
            <div className='container'>
              <div className='base_footer' style={{
            textAlign: isArabic() ? 'right' : 'left',
          }}>
                <h2>{t('contact.tunisia')}</h2>
                <br />
                <ul style={{ listStyle: "none" }}>
                  <li className='contact-icons'>
                    <FiPhone className='m-2' />
                    <label className='form-label' style={{direction:'ltr'}}>+216 28 846 888</label>
                  </li>
                  <li className="contact-icons">
                    <FiPhoneCall className='m-2' />
                    <label className="form-label" style={{direction:'ltr'}}>+216 29 190 039</label>
                  </li>
                  <li className='contact-icons'>
                    <FiMail className='m-2' />
                    <label className='form-label'>info@tunis-consulting.com</label>
                  </li>
                  <li className='contact-icons' style={{display: 'flex', flexFlow: 'nowrap'}}>
                    <IoLocationOutline className='m-2' style={{fontSize: '50px'}} />
                    <label className='form-label'>{t('description.address')}</label>
                  </li>
                </ul>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className='container'>
              <div className='base_footer' style={{
            textAlign: isArabic() ? 'right' : 'left',
          }}>
                <h2>{t('contact.india')}</h2> <br />
                <ul style={{ listStyle: "none" }}>
                  <li className='contact-icons' style={{display: 'flex', flexFlow: 'nowrap'}}>
                    <IoLocationOutline className='m-2' style={{fontSize: '50px'}} />
                    <label className='form-label'>ZM Enterprises <br /> A-42 <br />FIEE Complex, Block A, Okhla Phase II, Okhla Industrial Estate, New Delhi, Delhi 110020</label>
                  </li>
                </ul>
              </div>
              <div className='base_footer' style={{
            textAlign: isArabic() ? 'right' : 'left',
          }}>
                <h2>{t('contact.china')}</h2> <br />
                <ul style={{ listStyle: "none" }}>
                <li className="contact-icons">
                    <FiPhoneCall className='m-2' />
                    <label className="form-label" style={{direction:'ltr'}}>008613049971100</label>
                  </li>
                  <li className='contact-icons' style={{display: 'flex', flexFlow: 'nowrap'}}>
                    <IoLocationOutline className='m-2' style={{fontSize: '50px'}} />
                    <label className='form-label'>YIWU PIOEERS TRADING CO.,LIMITED <br />RM 2805, Global Building, No.168, Finance Sixth Street, YIWU City, Zhejiang Province</label>
                  </li>
                </ul>
                <br />
              </div>
            </div>
            </div>
        </div>
      </div>

      <br />
      </>
    )
}

export default Contact_component
