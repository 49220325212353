import React from "react";
import ReactGA from 'react-ga';


const SouthAfricaTouristVisaForm = () => {
      
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id="standard">
            <iframe id="" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://my.forms.app/form/65679a049d3e83bb1b2bfdc1" frameborder="0" style={{width: '90vw', minWidth: '100%', height: '373vh', border: 'none'}}></iframe>
        </div>
    );
};
export default SouthAfricaTouristVisaForm;