import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import govInv from '../../../../assets/img/gov_inv.jpg';
import oldvisa from '../../../../assets/img/visa2.jpg';
import close from '../../../../assets/img/close.png';
import norInv from '../../../../assets/img/normal-inv.jpg';
import visaChina from '../../../../assets/img/China-Visa.jpg';
import license from '../../../../assets/img/license.jpg';
import rne from '../../../../assets/img/companydocument.png';
import transfer from '../../../../assets/img/evidence.png';
import invoice from '../../../../assets/img/receipt.png';
import residence from '../../../../assets/img/Residence_Permit.jpg';
import workpermit from '../../../../assets/img/work_permit.jpg';
import house from '../../../../assets/img/building.png';
import note from '../../../../assets/img/note.jpg';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';


const ChinaBusinessVisa = () => {
    const [key, setKey] = useState('tab1');
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
  
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='visachina'>
          <Helmet>
        <title>{t('chinabusinessvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('chinabusinessvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Visa China, Business Visa, Business Visa China, Visa M China, Visa Z China, Visa Assistance, Visa requirements, Tunis Consulting, Business Visa, China, Visa Assistance, China Visa Requirements, Business Visa Process, Visa Application Support, Tunis Consulting Services, تأشيرة الصين, إجراءات التأشيرة, قائمة الوثائق, تأشيرة الأعمال, خدمات الاستشارة, تونس للاستشارات والمساعدة, متطلبات التأشيرة, عملية التقديم, طلب تأشيرة الأعمال في الصين, دعم تقديم التأشيرة, تأشيرة الأعمال الصينية'
        />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
            <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
            <div className='section-header pt-4 pb-3'>
            <div className='base_header mb-4'><span>{t('chinabusinessvisa.title')}</span></div>
            <Row xl={2} xs={1} md={2}>
                <Col className='mb-4'><p className='mt-4'>
                {t('chinabusinessvisa.sub4')}
          </p>
          <p>
           {t('chinabusinessvisa.sub5')}
          </p>
          <p>
           {t('chinabusinessvisa.sub3')}
          </p>
          <NavLink to='/Visa/china/business-visa/application' className="nav-link btn">{t('registration.button')}</NavLink>
        </Col>
                <Col>
                <img src={visaChina} alt='Business Visa to China' style={{width: '100%', height: '300px'}}/>
                </Col>
            </Row>
        </div>
<Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="tab1" title={t('chinabusinessvisa.visaM.title')}>
        <h2>{t('chinabusinessvisa.visaM.title')}</h2>
        <p>{t('chinabusinessvisa.visaM.def1')}</p>
        <p>{t('chinabusinessvisa.visaM.def2')}</p>
        <h5 className='req'>{t('chinabusinessvisa.visaM.requirements')}</h5>
        <h5 className='req2'>{t('chinabusinessvisa.visaM.initial')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaM.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinabusinessvisa.visaM.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaM.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            </Row>
            <h5 className='req2'>{t('chinabusinessvisa.visaM.preentry')}</h5>
            <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${oldvisa})`}} onClick={() => handleImageClick(oldvisa)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.oldvisa1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaM.oldvisa2')}
                    </p>
                </div>
          </div>
            </Col>
        </Row>
        <h5 className='req2'>{t('chinabusinessvisa.visaM.folder')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${govInv})`}} onClick={() => handleImageClick(govInv)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.govInv1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaM.govInv2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${norInv})`}} onClick={() => handleImageClick(norInv)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.norInv1')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <h5 className='req2'>{t('chinabusinessvisa.visaM.swift')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${oldvisa})`}} onClick={() => handleImageClick(oldvisa)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.olvisa1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaM.olvisa2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${norInv})`}} onClick={() => handleImageClick(norInv)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.norInv1')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${rne})`}} onClick={() => handleImageClick(rne)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.rne')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invoice})`}} onClick={() => handleImageClick(invoice)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.invoice')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${transfer})`}} onClick={() => handleImageClick(transfer)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaM.transfer1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaM.transfer2')}
                    </p>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>

      </Tab>
      <Tab eventKey="tab2" title={t('chinabusinessvisa.visaZ.title')}>
      <h2>{t('chinabusinessvisa.visaZ.title')}</h2>
        <p>{t('chinabusinessvisa.visaZ.def')}</p>
        <h5 className='req'>{t('chinabusinessvisa.visaZ.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaZ.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('chinabusinessvisa.visaZ.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaZ.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            </Row>
        <h5 className='req2'>{t('chinabusinessvisa.visaZ.initial')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${govInv})`}} onClick={() => handleImageClick(govInv)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.govInv1')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${oldvisa})`}} onClick={() => handleImageClick(oldvisa)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.oldvisa1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('chinabusinessvisa.visaZ.oldvisa2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${norInv})`}} onClick={() => handleImageClick(norInv)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.norInv1')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${license})`}} onClick={() => handleImageClick(license)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.license')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <h5 className='req2'>{t('chinabusinessvisa.visaZ.renewal')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${residence})`}} onClick={() => handleImageClick(residence)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.residence')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${workpermit})`}} onClick={() => handleImageClick(workpermit)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.permit')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${license})`}} onClick={() => handleImageClick(license)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.license')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${norInv})`}} onClick={() => handleImageClick(norInv)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.norInv1')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${house})`}} onClick={() => handleImageClick(house)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('chinabusinessvisa.visaZ.house')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
      </Tab>
    </Tabs>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default ChinaBusinessVisa;