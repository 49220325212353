import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import passport from '../../../../assets/img/passport.png';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import bankAccount from '../../../../assets/img/bank-account.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import belgium from '../../../../assets/img/belgium_cover.jpg';
import hospitalInv from '../../../../assets/img/hospitalInv.png';
import cert from '../../../../assets/img/medical-certificate.png';
import evidence from '../../../../assets/img/evidence.png';
import letter from '../../../../assets/img/companydocument.png';
import salary from '../../../../assets/img/receipt.png';
import certificate from '../../../../assets/img/birth_cert.png';
import note from '../../../../assets/img/note.jpg';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const BelgiumMedicalVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('belgium.medicalvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('belgium.medicalvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, Germany Medical Visa, Medical Visa Assistance, Germany Medical Visa Requirements, Medical Visa Application Process, Germany Healthcare Visa, Tunis Consulting Services, belgium Medical Visa Documents, Medical Visa Application Support, Germany Medical Treatment Visa, تونس للإستشارات, تأشيرة العلاج الطبي في ألمانيا, مساعدة في تأشيرة العلاج الطبي في ألمانيا, متطلبات تأشيرة العلاج الطبي في ألمانيا, عملية تقديم تأشيرة العلاج الطبي في ألمانيا, تأشيرة الرعاية الصحية في ألمانيا, خدمات تونس للإستشارات, وثائق تأشيرة العلاج الطبي في ألمانيا, دعم تقديم تأشيرة العلاج الطبي في ألمانيا, تأشيرة العلاج الطبي في ألمانيا, تونس للإستشارات و المساعدة,'
        />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${belgium})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('belgium.medicalvisa.title')}</h1>
            <NavLink to='/Visa/belgium/medical-visa/application' className="nav-link btn"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('belgium.medicalvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('belgium.medicalvisa.sub1')}</p>
        <p>{t('belgium.medicalvisa.sub2')}</p>
        <h5 className='req'>{t('belgium.medicalvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('belgium.medicalvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('belgium.medicalvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('belgium.medicalvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('belgium.medicalvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${hospitalInv})`}} onClick={() => handleImageClick(hospitalInv)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('belgium.medicalvisa.inv1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('belgium.medicalvisa.inv2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${cert})`}} onClick={() => handleImageClick(cert)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('belgium.medicalvisa.cert1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('belgium.medicalvisa.cert2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bankAccount})`}} onClick={() => handleImageClick(bankAccount)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('belgium.medicalvisa.transfer1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('belgium.medicalvisa.transfer2')}
                    </p>
                </div>
          </div>
            </Col>
        </Row>
        <h5 className='req2'>{t('belgium.medicalvisa.minor')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${passport})`}} onClick={() => handleImageClick(passport)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('belgium.medicalvisa.parpass')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${certificate})`}} onClick={() => handleImageClick(certificate)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('belgium.medicalvisa.cert')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${letter})`}} onClick={() => handleImageClick(letter)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('belgium.medicalvisa.fam')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${evidence})`}} onClick={() => handleImageClick(evidence)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('belgium.medicalvisa.evidence')}
                </h5>
                </div>
          </div>
            </Col>
            </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default BelgiumMedicalVisa;