import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import bankAccount from '../../../../assets/img/bank-account.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import germany from '../../../../assets/img/germanycover.jpg';
import invitation from '../../../../assets/img/invitation.png';
import attestation from '../../../../assets/img/companydocument.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const GermanyBusinessVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [clickedImage, setClickedImage] = useState('');
    const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
            <title>{t('germany.businessvisa.seoTitle')}</title>
            <meta
              name='description'
              content={t('germany.businessvisa.seodescription')}
            />
            <meta
              name='keywords'
              content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA,Tunis Consulting, Germany Visa Services, German Visa Assistance, Visa Requirements, Germany Visa Application, Immigration Services, Visa Guidance, German Visa Types,Germany Business Visa Application, Business Immigration, Visa Guidance, German Work Visa, Germany Business Visa, Germany Business Man, تونس للإستشارات, خدمات تأشيرات ألمانيا, مساعدة في تأشيرات ألمانيا, متطلبات التأشيرة, طلب تأشيرة ألمانيا, خدمات الهجرة, إرشادات التأشيرة, أنواع تأشيرات ألمانيا, تونس للإستشارات و المساعدة, تونس للإستشارات, خدمات تأشيرة العمل في ألمانيا, مساعدة في تأشيرة العمل الألمانية, متطلبات التأشيرة, طلب تأشيرة العمل في ألمانيا, هجرة الأعمال, إرشادات التأشيرة, تأشيرة العمل الألمانية'
            />
          </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${germany})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('germany.businessvisa.title')}</h1>
            <NavLink to='/Visa/germany/business-visa/application' className="nav-link btn"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('germany.businessvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('germany.businessvisa.sub1')}</p>
        <p>{t('germany.businessvisa.sub2')}</p>
        <h5 className='req'>{t('germany.businessvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('germany.businessvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('germany.businessvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('germany.businessvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('germany.businessvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bankAccount})`}} onClick={() => handleImageClick(bankAccount)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('germany.businessvisa.bank1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('germany.businessvisa.bank2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invitation})`}} onClick={() => handleImageClick(invitation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('germany.businessvisa.invitation')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${attestation})`}} onClick={() => handleImageClick(attestation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('germany.businessvisa.attravail')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default GermanyBusinessVisa;