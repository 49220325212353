import React from 'react';
import { useTranslation } from 'react-i18next';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import Card from '../UI/Card/Card';
import bgImage from '../../assets/img/Nouveau.png';
import visa from '../../assets/img/visa.png';
import embassyCert from '../../assets/img/embassyCert.jpg';
import importPic from '../../assets/img/import.jpg';
import conference from '../../assets/img/conference.jpg';
import hotel from '../../assets/img/hotel.jpg';
import deal from '../../assets/img/deal.jpg';
import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet-async';


const divStyle = {
  backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed', // This keeps the background fixed when scrolling
    height: '100%',
};

const Services = () => {

const {t} = useTranslation();

const data = [
  {
    id:1,
    image: visa,
    title: t('home.services.card1.title'),
    details: t('services.visa')
  },
  {
    id:2,
    image: importPic,
    title: t('home.services.card3.title'),
    details: t('services.import')
  },
  {
    id:3,
    image: embassyCert,
    title: t('home.services.card2.title'),
    details: t('services.embassy')
  },
  {
    id:4,
    image: conference,
    title: t('home.services.card4.title'),
    details: t('services.conference')
  },
  {
    id:5,
    image: hotel,
    title: t('home.services.card5.title'),
    details: t('services.hotel')
  },
  {
    id:6,
    image: deal,
    title: t('home.services.card6.title'),
    details: t('services.deal')
  }
]

ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div id='services'>
      <Helmet>
      <title>{t('services.seoTitle')}</title>
      <meta
          name='description'
          content={t('services.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting, China Visa, visa, visa france, france visa, tls contact, visa china, visa india, visa germany, visa south africa, visa services, import and export services, embassy certification, meeting and conference organization, hotel reservations, collaboration with charitable organizations and associations,Tunis Consulting, About Us, Visa and Immigration Services, Mission, Values, Expert Team, Visa Application Process, Immigration Assistance, Trusted Consultants,تونس للإستشارات، حولنا، خدمات تأشيرات وهجرة، مهمتنا، قيمنا، فريق خبير، عملية تقديم الطلبات للتأشيرات، مساعدة في الهجرة، مستشارون موثوق بهم, تأشيرة, فيزا, تأشيرة الصين , تأشيرة فرنسا, فيزا فرنسا,تأشيرة الهند, تأشيرة جنوب افريقيا, خدمات الإستيراد و التصدير, خدمات التأشيرات, تنظيم الإجتماعات و المؤتمرات, خدمات التصديق السفاري, التعاون مع المنظمات الخيرية و الجمعيات, حجوزات الفنادق, تجارة دولية, تصدير توريد الهند, تصدير توريد الصين'        
          />
      </Helmet>
            <Element>
      <Animated animationIn="bounceInUp" animationOut="fadeOut">
      <div className='pb-5' style={divStyle}>
      <div className='container'>
      <div className='section-header pt-4 pb-3 text-center'>
      <div className='base_header mb-2'><span><small className='bor_header_left'></small>{t('services.title')}<small className='bor_header_right'></small></span>
      </div>
          <h6 className='section-subtitle mr-auto ml-auto'>
          {t('services.intro')}
          </h6>
        </div>
        <div className='section-content row' style={{ textAlign: 'center' }}>
          {data.map((data) => (
            <Card key={data.id} image={data.image} title={data.title} description={data.details}/>
          ))}
      </div>
      </div>
      </div>
      </Animated>
      </Element>
    </div>
  );
};

export default Services;
