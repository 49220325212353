import React from 'react';

const Cover = ({ bgImage, title, subtitle }) => {
    return(
        <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${bgImage})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{title}</h1>
            <h2 className='sub-title mb-4'>{subtitle}</h2>
          </div>
        </div>
      </div>
        </div>
    );
};
export default Cover;