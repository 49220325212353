import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import passport from '../../../../assets/img/passport.png';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import southafrica from '../../../../assets/img/southafrica.jpeg';
import invitation from '../../../../assets/img/invitation.png';
import bcert from '../../../../assets/img/companydocument.png';
import mcert from '../../../../assets/img/birth_cert.png';
import crime from '../../../../assets/img/criminalrecord.png';
import insurance from '../../../../assets/img/health-insurance.png';
import bank from '../../../../assets/img/receipt.png';
import evidence from '../../../../assets/img/evidence.png';
import visa from '../../../../assets/img/oldvisa.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const SouthAfricaFamilyVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
           <Helmet>
        <title>{t('southafrica.familyvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('southafrica.familyvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, South Africa family Visa, family Visa for South Africa, Visa Assistance, South Africa family Visa Requirements, family Visa Application Process, Tunis Consulting Services, South Africa family Visa Documents, Visa Application Support, تونس للإستشارات, تأشيرة العائلة لجنوب أفريقيا, تأشيرة العائلة لجنوب أفريقيا, مساعدة في تأشيرة العائلة لجنوب أفريقيا, متطلبات تأشيرة العائلة لجنوب أفريقيا, عملية تقديم تأشيرة العائلة لجنوب أفريقيا, خدمات تونس للإستشارات, وثائق تأشيرة العائلة لجنوب أفريقيا, دعم تقديم تأشيرة العائلة لجنوب أفريقيا, تونس للإستشارات و المساعدة, تأشيرة الدراسة في جنوب افريقيا, تأشيرة الدراسة'
        />
      </Helmet>
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${southafrica})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('southafrica.familyvisa.title')}</h1>
            <NavLink to='/Visa/south-africa/family-visa/application' className="nav-link btn" style={{background: "#0e2b52"}}><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('southafrica.familyvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('southafrica.familyvisa.sub1')}</p>
        <p>{t('southafrica.familyvisa.sub2')}</p>
        <h5 className='req'>{t('southafrica.familyvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('southafrica.familyvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${passport})`}} onClick={() => handleImageClick(passport)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.passport')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('southafrica.familyvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('southafrica.familyvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${visa})`}} onClick={() => handleImageClick(visa)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.visa')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bcert})`}} onClick={() => handleImageClick(bcert)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.bcert')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${mcert})`}} onClick={() => handleImageClick(mcert)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.mcert')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${mcert})`}} onClick={() => handleImageClick(mcert)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.fcert')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${crime})`}} onClick={() => handleImageClick(crime)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.crime1')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${crime})`}} onClick={() => handleImageClick(crime)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.crime2')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${insurance})`}} onClick={() => handleImageClick(insurance)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.ins')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invitation})`}} onClick={() => handleImageClick(invitation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.inv')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bank})`}} onClick={() => handleImageClick(bank)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.bank')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${evidence})`}} onClick={() => handleImageClick(evidence)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.familyvisa.proof')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </div>
    );
};
export default SouthAfricaFamilyVisa;