import React, { useState, useEffect } from 'react';
import {Animated} from "react-animated-css";
import { useTranslation } from 'react-i18next';
import { Element} from 'react-scroll';
import ReactGA from 'react-ga';

import bgImage from '../../../assets/img/facts_bg.jpg';

const Facts = () => {
  const {t} = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const aboutSection = document.getElementById('facts');
      if(aboutSection) {
        const aboutSectionTop = aboutSection.getBoundingClientRect().top;
        if(scrollY >= aboutSectionTop) {
          setIsVisible(true);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
      
  ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div id='facts'>
      <div
        className='facts-container'
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className='container pt-5 pb-4'>
          <div className='row'>
          <div className='col-sm-6 col-md-4 text-center mb-4'>
          <Element name='facts'>
            <Animated animationIn="bounceInUp" animationOut="fadeOut" isVisible={isVisible}>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-trophy' />
              </div>
              <h5 className='facts-counter text-light'>{t('about.facts.F1')}</h5>
              <h6 className='facts-title text-light'>{t('about.facts.D1')}</h6>
              </Animated>
              </Element>
            </div>
            <div className='col-sm-6 col-md-4 text-center mb-4'>
            <Element name='facts'>
            <Animated animationIn="bounceInUp" animationOut="fadeOut" isVisible={isVisible}>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-check' />
              </div>
              <h5 className='facts-counter text-light'>{t('about.facts.F2')}</h5>
              <h6 className='facts-title text-light'>{t('about.facts.D2')}</h6>
            </Animated>
            </Element>
            </div>
            <div className='col-sm-6 col-md-4 text-center mb-4'>
            <Element name='facts'>
            <Animated animationIn="bounceInUp" animationOut="fadeOut" isVisible={isVisible}>
              <div className='mb-2 facts-icon'>
                <i className='fas fa-handshake' />
              </div>
              <h5 className='facts-counter text-light'>{t('about.facts.F3')}</h5>
              <h6 className='facts-title text-light'>{t('about.facts.D3')}</h6>
              </Animated>
              </Element>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facts;