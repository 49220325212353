import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import insurance from '../../../../assets/img/health-insurance.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import uk from '../../../../assets/img/ukcover.jpg';
import invitation from '../../../../assets/img/hospitalInv.png';
import building from '../../../../assets/img/building.png';
import report from '../../../../assets/img/medical-certificate.png';
import receipt from '../../../../assets/img/receipt.png';
import bank from '../../../../assets/img/bank-account.png';
import note from '../../../../assets/img/note.jpg';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const UnitedKingdomMedicalVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [clickedImage, setClickedImage] = useState('');
    const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('uk.medicalvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('uk.medicalvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, United Kingdom Visa Services, UK Visa Assistance, Visa Requirements, UK Visa Application, United Kingdom Medical Visa, UK Medical Visa Assistance, Medical Visa Requirements, UK Medical Visa Application, Visa Guidance, Medical Treatment in the UK, British Medical Visa Process, International Patients Travel to the United Kingdom, Visa Guidance, Travel to the UK, British Visa Process, International Travel to the United Kingdom, خدمات تأشيرة بريطانيا, مساعدة في تأشيرة بريطانيا, متطلبات تأشيرة بريطانيا, طلب تأشيرة بريطانيا, إرشادات تأشيرة بريطانيا, السفر إلى بريطانيا, عملية تأشيرة بريطانيا, تأشيرة العلاج الطبي في المملكة المتحدة, مساعدة في تأشيرة العلاج الطبي, متطلبات تأشيرة العلاج الطبي في المملكة المتحدة, طلب تأشيرة العلاج الطبي في المملكة المتحدة, إرشادات تأشيرة العلاج الطبي, العلاج الطبي في المملكة المتحدة, عملية تأشيرة العلاج الطبي في بريطانيا, السفر للعلاج الطبي في بريطانيا ,السفر الدولي إلى بريطانيا'
          />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${uk})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('uk.medicalvisa.title')}</h1>
            <NavLink to='/Visa/united-kingdom/medical-visa/application' className="nav-link btn1"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('uk.medicalvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('uk.medicalvisa.sub1')}</p>
        <p>{t('uk.medicalvisa.sub2')}</p>
        <h5 className='req'>{t('uk.medicalvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
        <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${note})`}} onClick={() => handleImageClick(note)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('registration.note')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.medicalvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('uk.medicalvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('uk.medicalvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('uk.medicalvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invitation})`}} onClick={() => handleImageClick(invitation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.medicalvisa.invitation')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${report})`}} onClick={() => handleImageClick(report)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.medicalvisa.cert1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('uk.medicalvisa.cert2')}
                </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${receipt})`}} onClick={() => handleImageClick(receipt)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.medicalvisa.payment')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${insurance})`}} onClick={() => handleImageClick(insurance)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.medicalvisa.ins1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('uk.medicalvisa.ins2')}
                </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${building})`}} onClick={() => handleImageClick(building)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.medicalvisa.prop')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bank})`}} onClick={() => handleImageClick(bank)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('uk.medicalvisa.bank1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('uk.medicalvisa.bank2')}
                </p>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default UnitedKingdomMedicalVisa;