import React from 'react';
import { Carousel } from 'react-bootstrap'; 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CarouselImg1 from "../../../assets/img/car5.jpg"
import CarouselImg3 from "../../../assets/img/car1.jpg"
import CarouselImg4 from "../../../assets/img/car4.jpg"

function Banner() {  
    const {t} = useTranslation();

    const data = [
        {
            key:1,
            image: CarouselImg1,
            title: t('home.carousel.slide1.title'),
            details: t('home.carousel.slide1.details'),
        },{
            key:2,
            image: CarouselImg3,
            title: t('home.carousel.slide2.title'),
            details: t('home.carousel.slide2.details'),
        },{
            key:3,
            image: CarouselImg4,
            title: t('home.carousel.slide3.title'),
            details: t('home.carousel.slide3.details'),
        },
    ]

    return (
      <>
   <Carousel fade id='carousel' indicators={false}>
 {data.map((data) =>(
    <Carousel.Item key = {data.key}  interval={2000}>
  <img
    className='d-block w-100'
    src={data.image}
    style={{height: '80vh'}}
    alt={data.title}
  />
<Carousel.Caption className='fadeInUp-animation'>
    <h3>{data.title}</h3>
    <p className='mb-3'>{data.details}</p>
    <Link to="/Services">
    <button className='custom-button' >{t('home.carousel.button1')}</button>
    </Link>
    <Link to="/Contact">
    <button className='custom-button m-2' >{t('home.carousel.button2')}</button>
    </Link>
</Carousel.Caption>
</Carousel.Item>
 ))}

</Carousel>

</>
  );
  }
 
export default Banner;
