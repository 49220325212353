import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import ContactComponent from '../sections/Contact/contact_component';
import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet-async';

const Contact = () => {
  const {t} = useTranslation();
  const form = useRef();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_26osgum', 'template_n3v4lsf', form.current, 'LSeIMyFfcT9AIZtLO')
      .then(
        (result) => {
          console.log(result.text);
          setIsEmailSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div id='contact'>
      <Helmet>
      <title>{t('contact.seoTitle')}</title>
      <meta
          name='description'
          content={t('contact.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting, China Visa, visa, visa france, france visa, tls contact, visa china, visa india, visa germany, visa south africa, visa services, import and export services, embassy certification, meeting and conference organization, hotel reservations, collaboration with charitable organizations and associations,Tunis Consulting, About Us, Visa and Immigration Services, Mission, Values, Expert Team, Visa Application Process, Immigration Assistance, Trusted Consultants, Contact Information, Inquiry Form, Assistance Services, Contact, تواصل، تونس للإستشارات، مساعدة في التأشيرات، استفسارات الهجرة، دعم، معلومات الاتصال، استمارة الاستفسار، خدمات المساعدة, تجارة دولية, تصدير توريد الهند, تصدير توريد الصين,تونس للإستشارات، حولنا، خدمات تأشيرات وهجرة، مهمتنا، قيمنا، فريق خبير، عملية تقديم الطلبات للتأشيرات، مساعدة في الهجرة، مستشارون موثوق بهم, تأشيرة, فيزا, تأشيرة الصين , تأشيرة فرنسا, فيزا فرنسا,تأشيرة الهند, تأشيرة جنوب افريقيا, خدمات الإستيراد و التصدير, خدمات التأشيرات, تنظيم الإجتماعات و المؤتمرات, خدمات التصديق السفاري, التعاون مع المنظمات الخيرية و الجمعيات, حجوزات الفنادق,'        
          />
      </Helmet>
      <>
      <div className='section-header text-center'>
      <div className='base_header mb-2'><span><small className='bor_header_left'></small>{t('contact.title')}<small className='bor_header_right'></small></span>
      </div>
        </div>
        <section className='register-photo'>
          <div className='form-container'>
            <div className='image-holder'></div>
            <form ref={form} onSubmit={sendEmail}>
              <h2 className='text-center'><strong>{t('contact.title2')}</strong></h2>
              <div className='mb-3'><input className='form-control' type="text" name="name" id="name" placeholder={t('contact.name')} required /></div>
              <div className='mb-3'><input className='form-control' type="text" name="phone" placeholder={t('contact.phone')} maxLength={10} required /></div>
              <div className='mb-3'><textarea className='form-control' name="message" id="message" placeholder={t('contact.message')} style={{ minHeight: '160px', maxHeight: '160px' }} required></textarea></div>
              <div className='mb-3'><button className='btn btn-primary d-block w-100' type="submit" value="Send"><i className='fas fa-paper-plane' /> {t('contact.send')}</button></div>
              {isEmailSent ? <p>Email sent</p> : <p></p> }
            </form>
          </div>
        </section>
        <ContactComponent />
      </>
    </div>
  );
};

export default Contact;
