import React from 'react';
import Home from './components/Pages/Home'
import About from './components/Pages/About';
import Services from './components/Pages/Services';
import Visa from './components/Pages/Visa';
import Contact from './components/Pages/Contact';
import China from './components/Pages/Visa/china/china';
import ChinaStudentVisa from './components/Pages/Visa/china/chinastudentvisa';
import ChinaStudentVisaForm from './components/Pages/Visa/china/chinastudentvisaform';
import ChinaBusinessVisa from './components/Pages/Visa/china/chinabusinessvisa';
import ChinaBusinessVisaForm from './components/Pages/Visa/china/chinabusinessvisaform';
import ChinaPersonalVisa from './components/Pages/Visa/china/chinapersonalvisa';
import ChinaPersonalVisaForm from './components/Pages/Visa/china/chinapersonalvisaform';
import India from './components/Pages/Visa/india/india';
import IndiaStudentVisa from './components/Pages/Visa/india/indiastudentvisa';
import IndiaStudentVisaForm from './components/Pages/Visa/india/indiastudentvisaform';
import IndiaBusinessVisa from './components/Pages/Visa/india/indiabusinessvisa';
import IndiaBusinessVisaForm from './components/Pages/Visa/india/indiabusinessvisaform';
import IndiaMedicalVisa from './components/Pages/Visa/india/indiamedicalvisa';
import IndiaMedicalVisaForm from './components/Pages/Visa/india/indiamedicalvisaform';
import SouthAfrica from './components/Pages/Visa/southafrica/southafrica';
import SouthAfricaStudentVisa from './components/Pages/Visa/southafrica/southafricastudentvisa';
import SouthAfricaStudentVisaForm from './components/Pages/Visa/southafrica/southafricastudentvisaform';
import SouthAfricaBusinessVisa from './components/Pages/Visa/southafrica/southafricabusinessvisa';
import SouthAfricaBusinessVisaForm from './components/Pages/Visa/southafrica/southafricabusinessvisaform';
import SouthAfricaTouristVisa from './components/Pages/Visa/southafrica/southafricatouristvisa';
import SouthAfricaTouristVisaForm from './components/Pages/Visa/southafrica/southafricatouristvisaform';
import SouthAfricaFamilyVisa from './components/Pages/Visa/southafrica/southafricafamilyvisa';
import SouthAfricaFamilyVisaForm from './components/Pages/Visa/southafrica/southafricafamilyvisaform';
import Germany from './components/Pages/Visa/germany/germany';
import GermanyBusinessVisa from './components/Pages/Visa/germany/germanybusinessvisa';
import GermanyBusinessVisaForm from './components/Pages/Visa/germany/germanybusinessvisaform';
import GermanyMedicalVisa from './components/Pages/Visa/germany/germanymedicalvisa';
import GermanyMedicalVisaForm from './components/Pages/Visa/germany/germanymedicalvisaform';
import GermanyTouristVisa from './components/Pages/Visa/germany/germanytouristvisa';
import GermanyTouristVisaForm from './components/Pages/Visa/germany/germanytouristvisaform';
import France from './components/Pages/Visa/france/france';
import FranceBusinessVisa from './components/Pages/Visa/france/francebusinessvisa';
import FranceBusinessVisaForm from './components/Pages/Visa/france/francebusinessvisaform';
import FranceMedicalVisa from './components/Pages/Visa/france/francemedicalvisa';
import FranceMedicalVisaForm from './components/Pages/Visa/france/francemedicalvisaform';
import FranceTouristVisa from './components/Pages/Visa/france/francetouristvisa';
import FranceTouristVisaForm from './components/Pages/Visa/france/francetouristvisaform';
import Spain from './components/Pages/Visa/spain/spain';
import SpainBusinessVisa from './components/Pages/Visa/spain/spainbusinessvisa';
import SpainBusinessVisaForm from './components/Pages/Visa/spain/spainbusinessvisaform';
import SpainStudentVisa from './components/Pages/Visa/spain/spainstudentvisa';
import SpainStudentVisaForm from './components/Pages/Visa/spain/spainstudentvisaform';
import SpainTouristVisa from './components/Pages/Visa/spain/spaintouristvisa';
import SpainTouristVisaForm from './components/Pages/Visa/spain/spaintouristvisaform';
import SpainResidenceVisa from './components/Pages/Visa/spain/spainresidencevisa';
import SpainResidenceVisaForm from './components/Pages/Visa/spain/spainresidencevisaform';
import UnitedKingdom from './components/Pages/Visa/uk/uk';
import UnitedKingdomBusinessVisa from './components/Pages/Visa/uk/ukbusinessvisa';
import UnitedKingdomBusinessVisaForm from './components/Pages/Visa/uk/ukbusinessvisaform';
import UnitedKingdomStudentVisa from './components/Pages/Visa/uk/ukstudentvisa';
import UnitedKingdomStudentVisaForm from './components/Pages/Visa/uk/ukstudentvisaform';
import UnitedKingdomMedicalVisa from './components/Pages/Visa/uk/ukmedicalvisa';
import UnitedKingdomMedicalVisaForm from './components/Pages/Visa/uk/ukmedicalvisaform';
import UnitedKingdomTouristVisa from './components/Pages/Visa/uk/uktouristvisa';
import UnitedKingdomTouristVisaForm from './components/Pages/Visa/uk/uktouristvisaform';
import Belgium from './components/Pages/Visa/belgium/belgium';
import BelgiumBusinessVisa from './components/Pages/Visa/belgium/belgiumbusinessvisa';
import BelgiumBusinessVisaForm from './components/Pages/Visa/belgium/belgiumbusinessvisaform';
import BelgiumMedicalVisa from './components/Pages/Visa/belgium/belgiummedicalvisa';
import BelgiumMedicalVisaForm from './components/Pages/Visa/belgium/belgiummedicalvisaform';
import BelgiumTouristVisa from './components/Pages/Visa/belgium/belgiumtouristvisa';
import BelgiumTouristVisaForm from './components/Pages/Visa/belgium/belgiumtouristvisaform';

import { Route, Routes, Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';


const RoutesConfig = () => {
  const {t} = useTranslation();
  return(
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/About" element={<About/>}/>
      <Route path="/Services" element={<Services/>} />
      <Route path="/Visa" element={<Visa/>} />
      <Route path="/Contact" element={<Contact/>}/>
      <Route path="/Visa/china" element={<China/>}/>
      <Route path="/Visa/china/student-visa" element={<ChinaStudentVisa/>}/>
      <Route path='/Visa/china/student-visa/application' element={<ChinaStudentVisaForm/>}/>
      <Route path="/Visa/china/business-visa" element={<ChinaBusinessVisa/>}/>
      <Route path='/Visa/china/business-visa/application' element={<ChinaBusinessVisaForm/>}/>
      <Route path='/Visa/china/personal-visa' element={<ChinaPersonalVisa/>}/>
      <Route path='/Visa/china/personal-visa/application' element={<ChinaPersonalVisaForm/>}/>
      <Route path="/Visa/india" element={<India/>}/>
      <Route path='/Visa/india/student-visa' element={<IndiaStudentVisa/>}/>
      <Route path='/Visa/india/student-visa/application' element={<IndiaStudentVisaForm/>}/>
      <Route path="Visa/india/business-visa" element={<IndiaBusinessVisa/>}/>
      <Route path="/Visa/india/business-visa/application" element={<IndiaBusinessVisaForm/>}/>
      <Route path='/Visa/india/medical-visa' element={<IndiaMedicalVisa/>}/>
      <Route path='/Visa/india/medical-visa/application' element={<IndiaMedicalVisaForm/>}/>
      <Route path='/Visa/south-africa' element={<SouthAfrica/>}/>
      <Route path='/Visa/south-africa/student-visa' element={<SouthAfricaStudentVisa/>}/>
      <Route path='/Visa/south-africa/student-visa/application' element={<SouthAfricaStudentVisaForm/>}/>
      <Route path='/Visa/south-africa/business-visa' element={<SouthAfricaBusinessVisa/>}/>
      <Route path='/Visa/south-africa/business-visa/application' element={<SouthAfricaBusinessVisaForm/>}/>
      <Route path='/Visa/south-africa/tourist-visa' element={<SouthAfricaTouristVisa/>}/>
      <Route path='/Visa/south-africa/tourist-visa/application' element={<SouthAfricaTouristVisaForm/>}/>
      <Route path='/Visa/south-africa/family-visa' element={<SouthAfricaFamilyVisa/>}/>
      <Route path='/Visa/south-africa/family-visa/application' element={<SouthAfricaFamilyVisaForm/>}/>
      <Route path='/Visa/germany' element={<Germany/>}/>
      <Route path='/Visa/germany/business-visa' element={<GermanyBusinessVisa/>}/>
      <Route path='/Visa/germany/business-visa/application' element={<GermanyBusinessVisaForm/>}/>
      <Route path='/Visa/germany/medical-visa' element={<GermanyMedicalVisa/>}/>
      <Route path='/Visa/germany/medical-visa/application' element={<GermanyMedicalVisaForm/>}/>
      <Route path='/Visa/germany/tourist-visa' element={<GermanyTouristVisa/>}/>
      <Route path='/Visa/germany/tourist-visa/application' element={<GermanyTouristVisaForm/>}/>
      <Route path='/Visa/france' element={<France/>}/>
      <Route path='/Visa/france/business-visa' element={<FranceBusinessVisa/>}/>
      <Route path='/Visa/france/business-visa/application' element={<FranceBusinessVisaForm/>}/>
      <Route path='/Visa/france/medical-visa' element={<FranceMedicalVisa/>}/>
      <Route path='/Visa/france/medical-visa/application' element={<FranceMedicalVisaForm/>}/>
      <Route path='/Visa/france/tourist-visa' element={<FranceTouristVisa/>}/>
      <Route path='/Visa/france/tourist-visa/application' element={<FranceTouristVisaForm/>}/>
      <Route path='/Visa/spain' element={<Spain/>}/>
      <Route path='/Visa/spain/business-visa' element={<SpainBusinessVisa/>}/>
      <Route path='/Visa/spain/business-visa/application' element={<SpainBusinessVisaForm/>}/>
      <Route path='/Visa/spain/student-visa' element={<SpainStudentVisa/>}/>
      <Route path='/Visa/spain/student-visa/application' element={<SpainStudentVisaForm/>}/>
      <Route path='/Visa/spain/tourist-visa' element={<SpainTouristVisa/>}/>
      <Route path='/Visa/spain/tourist-visa/application' element={<SpainTouristVisaForm/>}/>
      <Route path='/Visa/spain/residence-visa' element={<SpainResidenceVisa/>}/>
      <Route path='/Visa/spain/residence-visa/application' element={<SpainResidenceVisaForm/>}/>
      <Route path='/Visa/united-kingdom' element={<UnitedKingdom/>}/>
      <Route path='/Visa/united-kingdom/business-visa' element={<UnitedKingdomBusinessVisa/>}/>
      <Route path='/Visa/united-kingdom/business-visa/application' element={<UnitedKingdomBusinessVisaForm/>}/>
      <Route path='/Visa/united-kingdom/student-visa' element={<UnitedKingdomStudentVisa/>}/>
      <Route path='/Visa/united-kingdom/student-visa/application' element={<UnitedKingdomStudentVisaForm/>}/>
      <Route path='/Visa/united-kingdom/medical-visa' element={<UnitedKingdomMedicalVisa/>}/>
      <Route path='/Visa/united-kingdom/medical-visa/application' element={<UnitedKingdomMedicalVisaForm/>}/>
      <Route path='/Visa/united-kingdom/tourist-visa' element={<UnitedKingdomTouristVisa/>}/>
      <Route path='/Visa/united-kingdom/tourist-visa/application' element={<UnitedKingdomTouristVisaForm/>}/>
      <Route path='/Visa/belgium' element={<Belgium/>}/>
      <Route path='/Visa/belgium/business-visa' element={<BelgiumBusinessVisa/>}/>
      <Route path='/Visa/belgium/business-visa/application' element={<BelgiumBusinessVisaForm/>}/>
      <Route path='/Visa/belgium/medical-visa' element={<BelgiumMedicalVisa/>}/>
      <Route path='/Visa/belgium/medical-visa/application' element={<BelgiumMedicalVisaForm/>}/>
      <Route path='/Visa/belgium/tourist-visa' element={<BelgiumTouristVisa/>}/>
      <Route path='/Visa/belgium/tourist-visa/application' element={<BelgiumTouristVisaForm/>}/>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    );
};
export default RoutesConfig;