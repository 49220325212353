import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/UI/Header/Header';
import Footer from './components/UI/Footer/Footer';
import RoutesConfig from './router';

ReactGA.initialize('G-V30MWRKV6Y');

function App() {
  const { t, i18n } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  useEffect(() => {
    console.log("Language changed:", i18n.language);
    setIsArabic(i18n.language === 'ar');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [i18n.language]);

  const keywords="visa, visa france, france visa, tls contact, visa china, visa india, visa germany, visa south africa, تأشيرة, فيزا, تأشيرة الصين , تأشيرة فرنسا, فيزا فرنسا,تأشيرة الهند, تأشيرة جنوب افريقيا, Tunis Consulting, China Visa, visa, visa france, france visa, tls contact, visa china, visa india, visa germany, visa south africa, visa services, import and export services, embassy certification, meeting and conference organization, hotel reservations, collaboration with charitable organizations and associations,Tunis Consulting, About Us, Visa and Immigration Services, Mission, Values, Expert Team, Visa Application Process, Immigration Assistance, Trusted Consultants,تونس للإستشارات، حولنا، خدمات تأشيرات وهجرة، مهمتنا، قيمنا، فريق خبير، عملية تقديم الطلبات للتأشيرات، مساعدة في الهجرة، مستشارون موثوق بهم, تأشيرة, فيزا, تأشيرة الصين , تأشيرة فرنسا, فيزا فرنسا,تأشيرة الهند, تأشيرة جنوب افريقيا, خدمات الإستيراد و التصدير, خدمات التأشيرات, تنظيم الإجتماعات و المؤتمرات, خدمات التصديق السفاري, التعاون مع المنظمات الخيرية و الجمعيات, حجوزات الفنادق, تجارة دولية, تصدير توريد الهند, تصدير توريد الصين";   

  ReactGA.pageview(window.location.pathname + window.location.search);
  return(
    <I18nextProvider i18n={i18n}>
    <div className={isArabic ? 'rtl' : ''}>
    <BrowserRouter>
    <HelmetProvider>
      <Helmet>
      <title>{t('description.logo')}</title>
        <meta
          name='description'
          content={t('services.seodescription')}
        />
        <meta name="keywords" content={keywords}/>
      </Helmet>
    <Header/>
    <RoutesConfig/>
    <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
<div class="elfsight-app-3795453b-523d-4896-9c3c-71ac7bf2e90c" data-elfsight-app-lazy></div>
    <Footer/>
    </HelmetProvider>
    </BrowserRouter>
    </div>
  </I18nextProvider>
);
}

export default App;
