import React from 'react';
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../../../languageUtils';
import Cover from '../../../sections/Cover/Cover';
import {Col, Row} from 'react-bootstrap';
import { NavLink} from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import work_visa from '../../../../assets/img/work_visa.jpg';
import medical from '../../../../assets/img/medical.jpg';
import tourist from '../../../../assets/img/germanytourist.jpg';
import { Helmet } from 'react-helmet-async';

const Germany = () => {
    const {t} = useTranslation();

    function handleClick() {
        window.scrollTo({ top: 0 });
      }

      return (
        <div id='country'>
          <Helmet>
            <title>{t('germany.seoTitle')}</title>
            <meta
              name='description'
              content={t('germany.seodescription')}
            />
            <meta
              name='keywords'
              content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA,Tunis Consulting, Germany Visa Services, German Visa Assistance, Visa Requirements, Germany Visa Application, Immigration Services, Visa Guidance, German Visa Types, تونس للإستشارات, خدمات تأشيرات ألمانيا, مساعدة في تأشيرات ألمانيا, متطلبات التأشيرة, طلب تأشيرة ألمانيا, خدمات الهجرة, إرشادات التأشيرة, أنواع تأشيرات ألمانيا, تونس للإستشارات و المساعدة'
            />
          </Helmet>
            <Element>
                <Animated animationIn="jackInTheBox" animationOut="fadeOut">
                <Cover bgImage={require('../../../../assets/img/germanycover.jpg')}
              title={t('registration.germany')}
              subtitle={t('germany.subtitle')}/>
            <div className='container mt-4 animated bounceInLeft' style={{textAlign: isArabic() ? 'right' : 'left'}}>
                <div className='details '>
                <h6 className='mb-3'>{t('germany.h6')}</h6>
                <p>{t('germany.description1')}</p>
                <p>{t('germany.description2')}</p>
                </div>
        <Row className='g-4 justify-content-center mt-4' xs={1} xl={3} md={3}>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={work_visa} alt={t('germany.businesstitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/germany/business-visa'>{t('germany.businesstitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={medical} alt={t('germany.medicaltitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/germany/medical-visa'>{t('germany.medicaltitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={tourist} alt={t('germany.touristtitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/germany/tourist-visa'>{t('germany.touristtitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
        </Row>
    </div>
                </Animated>
            </Element>
        </div>
      );
};
export default Germany;