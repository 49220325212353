
import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import southafrica from '../../../../assets/img/southafrica.jpeg';
import bank from '../../../../assets/img/bank-account.png';
import letter from '../../../../assets/img/companydocument.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const SouthAfricaTouristVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('southafrica.touristvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('southafrica.touristvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA,Tunis Consulting, South Africa Tourist Visa, Tourist Visa for South Africa, Visa Assistance, South Africa Tourist Visa Requirements, Tourist Visa Application Process, Tunis Consulting Services, South Africa Tourist Visa Documents, Visa Application Support, تونس للإستشارات, تأشيرة السياحة لجنوب أفريقيا, تأشيرة السياحة لجنوب أفريقيا, مساعدة في تأشيرة السياحة لجنوب أفريقيا, متطلبات تأشيرة السياحة لجنوب أفريقيا, عملية تقديم تأشيرة السياحة لجنوب أفريقيا, خدمات تونس للإستشارات, وثائق تأشيرة السياحة لجنوب أفريقيا, دعم تقديم تأشيرة السياحة لجنوب أفريقيا, تونس للإستشارات و المساعدة'
        />
      </Helmet>
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${southafrica})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('southafrica.touristvisa.title')}</h1>
            <NavLink to='/Visa/south-africa/tourist-visa/application' className="nav-link btn" style={{background: "#0e2b52"}}><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('southafrica.touristvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('southafrica.touristvisa.sub1')}</p>
        <p>{t('southafrica.touristvisa.sub2')}</p>
        <h5 className='req'>{t('southafrica.touristvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.touristvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('southafrica.touristvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('southafrica.touristvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('southafrica.touristvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bank})`}} onClick={() => handleImageClick(bank)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.touristvisa.bank1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('southafrica.touristvisa.bank2')}
                </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${letter})`}} onClick={() => handleImageClick(letter)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('southafrica.touristvisa.letter1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('southafrica.touristvisa.letter2')}
                </p>
                </div>
          </div>
            </Col>
            </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </div>
    );
};
export default SouthAfricaTouristVisa;