import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Abt from '../sections/About/abt';
import Accordion from '../UI/Accordion/Accordion';
import Facts from '../sections/Facts/Facts';
import ReactGA from 'react-ga';
import SEO from '../Helmet/SEO';
import {Helmet} from 'react-helmet-async';

const About = () => {

const {t, i18n} = useTranslation();
const questionsAnswers = [
    {
      question: t('about.faq.Q1'),
      answer:t('about.faq.A1'),
    },
    {
      question: t('about.faq.Q2'),
      answer:t('about.faq.A2'),
    },
    {
      question: t('about.faq.Q3'),
      answer: t('about.faq.A3'),
    },
    {
      question: t('about.faq.Q4'),
      answer: t('about.faq.A4'),
    },
    {
      question: t('about.faq.Q5'),
      answer: t('about.faq.A5'),
    },
  ];

  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [i18n.language]);

return(
    <div id='aboutus'>
      <Helmet>
      <title>{t('about.seoTitle')}</title>
      <meta
          name='description'
          content={t('about.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting, China Visa, visa, visa france, france visa, tls contact, visa china, visa india, visa germany, visa south africa, visa services, import and export services, embassy certification, meeting and conference organization, hotel reservations, collaboration with charitable organizations and associations,Tunis Consulting, About Us, Visa and Immigration Services, Mission, Values, Expert Team, Visa Application Process, Immigration Assistance, Trusted Consultants,تونس للإستشارات، حولنا، خدمات تأشيرات وهجرة، مهمتنا، قيمنا، فريق خبير، عملية تقديم الطلبات للتأشيرات، مساعدة في الهجرة، مستشارون موثوق بهم, تأشيرة, فيزا, تأشيرة الصين , تأشيرة فرنسا, فيزا فرنسا,تأشيرة الهند, تأشيرة جنوب افريقيا, خدمات الإستيراد و التصدير, خدمات التأشيرات, تنظيم الإجتماعات و المؤتمرات, خدمات التصديق السفاري, التعاون مع المنظمات الخيرية و الجمعيات, حجوزات الفنادق,تجارة دولية, تصدير توريد الهند, تصدير توريد الصين'        
          />
      </Helmet>
        <Abt/>
        <Facts/>
        <div className='container'>
            <Accordion questionsAnswers={questionsAnswers} />
            </div>
    </div>
);
};

export default About;