import React from 'react';
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../../../languageUtils';
import Cover from '../../../sections/Cover/Cover';
import {Col, Row} from 'react-bootstrap';
import { NavLink} from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import univ from '../../../../assets/img/university.jpg';
import work_visa from '../../../../assets/img/work_visa.jpg';
import medical from '../../../../assets/img/medical.jpg';
import tourist from '../../../../assets/img/uktourist.jpg';
import { Helmet } from 'react-helmet-async';

const UnitedKingdom = () => {
    const {t} = useTranslation();

    function handleClick() {
        window.scrollTo({ top: 0 });
      }

      return (
        <div id='country'>
          <Helmet>
        <title>{t('uk.seoTitle')}</title>
        <meta
          name='description'
          content={t('uk.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, United Kingdom Visa Services, UK Visa Assistance, Visa Requirements, UK Visa Application, Visa Guidance, Travel to the UK, British Visa Process, International Travel to the United Kingdom, خدمات تأشيرة بريطانيا، مساعدة في تأشيرة بريطانيا، متطلبات تأشيرة بريطانيا، طلب تأشيرة بريطانيا، إرشادات تأشيرة بريطانيا، السفر إلى بريطانيا، عملية تأشيرة بريطانيا، السفر الدولي إلى بريطانيا'
          />
      </Helmet>
            <Element>
                <Animated animationIn="jackInTheBox" animationOut="fadeOut">
                <Cover bgImage={require('../../../../assets/img/ukcover.jpg')}
              title={t('registration.uk')}
              subtitle={t('uk.subtitle')}/>
            <div className='container mt-4 animated bounceInLeft' style={{textAlign: isArabic() ? 'right' : 'left'}}>
                <div className='details '>
                <h6 className='mb-3'>{t('uk.h6')}</h6>
                <p>{t('uk.description1')}</p>
                <p>{t('uk.description2')}</p>
                </div>
        <Row className='g-4 justify-content-center mt-4' xs={1} xl={4} md={4}>
        <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={work_visa} alt={t('uk.businesstitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/united-kingdom/business-visa'>{t('uk.businesstitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={univ} alt={t('uk.studenttitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/united-kingdom/student-visa'>{t('uk.studenttitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={medical} alt={t('uk.medicaltitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/united-kingdom/medical-visa'>{t('uk.medicaltitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mb-4 listing-item">
              <div className="listing-image">
                <img variant="top" src={tourist} alt={t('uk.touristtitle')} />
              </div>
              <div className="listing-item-content">
                <h2><NavLink onClick={handleClick} to='/visa/united-kingdom/tourist-visa'>{t('uk.touristtitle')}</NavLink></h2>
              </div>
            </div>
          </Col>
        </Row>
    </div>
                </Animated>
            </Element>
        </div>
      );
};
export default UnitedKingdom;