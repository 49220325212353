
import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import spain from '../../../../assets/img/spaincover.jpg';
import diploma from '../../../../assets/img/diploma.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const SpainStudentVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('spain.studentvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('spain.studentvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, Spain Visa Services, Spanish Visa Assistance, Visa Requirements, Spain Visa Application, Visa Guidance, Travel to Spain, Spanish Tourist Visa, Spain Student Visa Services, Spanish Student Visa Assistance, Student Visa Requirements, Spain Student Visa Application, Visa Guidance, Study in Spain, Spanish Student Visa Process, International Students in Spain, خدمات تأشيرة الدراسة في إسبانيا, مساعدة في تأشيرة الدراسة في إسبانيا, متطلبات تأشيرة الدراسة في إسبانيا, طلب تأشيرة الدراسة في إسبانيا, إرشادات تأشيرة الطالب في إسبانيا, الدراسة في إسبانيا, عملية تأشيرة الدراسة الإسبانية, الطلاب الدوليين في إسبانيا, Spain Business Visa, تونس للإستشارات, خدمات تأشيرة إسبانيا, مساعدة في تأشيرة إسبانيا, متطلبات تأشيرة إسبانيا, طلب تأشيرة إسبانيا, إرشادات تأشيرة إسبانيا, السفر إلى إسبانيا, تأشيرة سياحية إسبانية, تأشيرة عمل إسبانية, تونس للإستشارات و المساعدة'
          />
      </Helmet>
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${spain})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('spain.studentvisa.title')}</h1>
            <NavLink to='/Visa/spain/student-visa/application' className="nav-link btn" style={{background: "#0e2b52"}}><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('spain.studentvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('spain.studentvisa.sub1')}</p>
        <p>{t('spain.studentvisa.sub2')}</p>
        <h5 className='req'>{t('spain.studentvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.studentvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('spain.studentvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('spain.studentvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('spain.studentvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${diploma})`}} onClick={() => handleImageClick(diploma)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('spain.studentvisa.diploma')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </div>
    );
};
export default SpainStudentVisa;