import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'ar',
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          description: {
            logo: 'Tunis Consulting & Assisting',
            desc1: 'Business Class Services',
            desc2: 'Visa and International Trade Solutions',
            address:'Palestine St. 85 Belvedere 1002 - B2 - 3 2nd floor Alquds Building',
          },
          nav: {
            home: 'Home',
            about: 'About', 
            services: 'Services', 
            contact: 'Contact', 
            visa: 'Visa'
          },
          home: {
            carousel: {
              slide1: {
                title:'Tunis Consulting & Assisting',
                details:'Diverse solutions and services in visas and international trade'
              },
              slide2: {
                title:'Businessmen Services',
                details:'Solutions for businessmen with high efficiency and quality'
              },
              slide3: {
                title:'Cross-Border Trade',
                details:'We provide efficient solutions for importing and exporting to and from China and India',
              },
              button1: 'Discover Our Services',
              button2: 'Contact Us',
            },
            about: {
              title1: 'Our',
              title2: ' Company',
              title3: 'About us',
              description1: 'Tunis Consulting & Assisting Company was founded in 2015. Since then, we have become pioneers in delivering high-quality and reliable services thanks to a qualified and experienced team that constantly seeks to achieve clients goals with ease and efficiency.',
              description2: 'Our services include consultation and assistance in the areas of travel, work, study, medical visas abroad, embassy certifications, as well as import and export from China and India. Additionally, we offer event organization, hotel reservations, and collaboration with charitable organizations and associations.',
              description3:'If you need more information or wish to consult our services, please do not hesitate to contact us. We are here to assist you in achieving your goals with confidence and efficiency.',
              button: 'Read More'
            },
            services: {
              title1: 'Our ',
              title2: 'Services',
              description:'Our range of services includes a diverse set of solutions designed to meet the needs of our clients in various sectors. We aim to provide comprehensive and reliable solutions in the fields of business, travel, and international affairs. Our services include the following:',
              card1: {
                title:'Visa Services',
                description:'Our visa services include a wide range of options with all embassies'
              },
              card2: {
                title:'Embassy Certification',
                description:'Our services include embassy authentication for official documents, ensuring that your documents are internationally recognized and legitimate'
              },
              card3: {
                title:'Import and Export Services',
                description:'Our import and export services cover the supply and transportation of goods from China and India'
              },
              card4: {
                title:'Meeting and conference organization',
                description:'Our services include professional event planning, conferences, and meetings, with a keen attention to detail'
              },
              card5: {
                title:'Hotel Reservations',
                description:'We provide high-quality hotel booking services to ensure a comfortable and ideal stay for our clients'
              },
              card6: {
                title:'Collaboration with Charitable Organizations and Associations',
                description:'We collaborate with charitable organizations and associations to support their initiatives'
              }
            }
          },
          about: {
            seoTitle: 'About Tunis Consulting and Assisting : A Trusted Partner in Visa Solutions',
            seodescription: 'Learn more about Tunis Consulting & Assisting. Discover our mission, values, and the comprehensive visa and immigration services we offer.Our company offers consultancy and assistance in various fields, including visas and embassy attestations, aiding companies in import-export activities from China and India. We also specialize in event organization and hotel reservations, along with coordinating with organizations and charitable associations.',
            title: 'WHO ARE WE ?',
            description1: 'Tunis Consulting and Assisting is your comprehensive destination for meeting a multitude of needs. Established in 2015, we have since become leaders in providing high-quality and reliable services. Our company offers consultancy and assistance in various fields, including visas and embassy attestations, aiding companies in import-export activities from China and India. We also specialize in event organization and hotel reservations, along with coordinating with organizations and charitable associations.',
            description2: 'Our team is highly qualified and experienced, and our primary goal is to help you achieve your objectives with ease and efficiency. We uphold principles of efficiency and credibility in everything we provide, constantly striving to meet your expectations and deliver high-quality services. If you are looking for comprehensive and professional solutions in the realms of visas, export-import, and more, do not hesitate to rely on our company. We are here to assist you with confidence and effectiveness.',
            facts: {
              F1:'Leadership in service sectors',
              D1:'Our goal is to achieve excellence in our services by meeting our customers expectations and providing an effective solution',
              F2:'Providing exceptional services',
              D2:'We offer high-quality and effective consultancy and assistance services to our clients to ensure their satisfaction and achieve excellence in the market',
              F3:'International Expansion',
              D3:'The company aims to expand its international service offerings by providing customized solutions to companies and individuals operating in a global context',
            },
            faq: {
              title: 'Frequently Asked Questions',
              Q1:'How can I contact you to access your services?',
              A1:'You can contact us through the "Contact" page on the website or via email at "info@tunis-consulting.com" or by calling us at +216 28 846 888',
              Q2:'What are your main services?',
              A2:'We provide services for travel and work visas, embassy authentication, import and export to and from China and India, conference organization and hotel bookings, and support for charitable organizations.',
              Q3:'Can you provide customized solutions for businesses?',
              A3:'Yes, we offer customized solutions for businesses, including organizing special events and business support services.',
              Q4:'Can you help me get a visa for studying abroad?',
              A4:'Yes, we provide efficient and reliable visa services for studying abroad, including assistance with applications and preparation for consular interviews.',
              Q5:'Do you offer authentication services for certificates and documents?',
              A5:'Yes, we provide embassy authentication services to ensure the legitimacy and legality of official documents',
            },
          },
          services: {
            seoTitle: 'Our Services at Tunis Consulting & Assisting',
            seodescription: 'Explore the comprehensive services offered by Tunis Consulting & Assisting. From visa assistance to immigration consulting, our expert team is dedicated to providing reliable and efficient solutions for your needs.leaders in providing high-quality and reliable services. Our company offers consultancy and assistance in various fields, including visas and embassy attestations, aiding companies in import-export activities from China and India. We also specialize in event organization and hotel reservations, along with coordinating with organizations and charitable associations.',
            title: 'Our Services',
            intro: 'Tunis Consulting and Assistance Company offers a comprehensive range of services designed to efficiently and professionally meet the needs of its clients. We are here to provide support and solutions to our clients in a variety of aspects. You can rely on our expertise and commitment to successfully achieve your goals.',
            visa: 'We facilitate the application and acquisition of various types of visas, ensuring a smooth and hassle-free process for our clients. Whether you are planning to travel, work, study, or seek medical treatment abroad, our expert team will guide you through the entire visa application process, providing comprehensive support to meet your specific requirements',
            import: 'We facilitate the import and export of products between China, India, and other international destinations. Our experienced team handles logistics, customs clearance, and shipping, streamlining the process and ensuring a cost-effective and efficient supply chain for your business',
            embassy: 'Our team assists clients in obtaining necessary embassy certifications for documents such as legal contracts, academic transcripts, and other official papers. We handle the entire certification process, ensuring that your documents meet the requirements of the respective embassies, saving you time and effort',
            hotel: 'We handle hotel reservations with the utmost care to meet our clients needs. Regardless of the purpose of your stay, whether it is for business or leisure, we conduct thorough research to find suitable hotels and provide multiple options that align with your budget and preferences. We guarantee the best deals and services, making your stay unique and comfortable. Count on us to secure the perfect hotel booking that meets your expectations down to the smallest details',
            conference: 'We offer our extensive expertise in organizing and managing conferences and meetings, starting from planning and design and ending with execution and event management. We will work closely with you to meet all the needs of your event and ensure its exceptional success. If you are looking for the organization of a conference, meeting, or a special event that stands out with excellence, we are here to make it a reality',
            deal: 'We work closely with charitable organizations and associations, providing assistance in various capacities to help them achieve their goals. Our commitment to social responsibility extends to these collaborations, where we contribute our expertise and resources to make a positive impact on society',
          },
          contact: {
            seoTitle: 'Contact Tunis Consulting & Assisting',
            seodescription: 'Get in touch with Tunis Consulting & Assisting. Contact our team for visa assistance, immigration inquiries, or any other questions. We are here to provide prompt and helpful support.',
            title: 'Contact Us',
            intro: 'We are always eager to hear from you. If you have any inquiries, comments, or specific needs, please do not hesitate to get in touch with us. You can reach our dedicated and professional team through email, phone, or by filling out the form below. We will be delighted to assist you and respond to your queries as quickly as possible. Thank you for your interest in our company, and we always look forward to serving you efficiently and effectively.',
            title2: 'Any queries ?',
            name: 'Name',
            phone: 'Mobile number',
            message: 'Your Message',
            send: 'Send',
            title3: 'Contact Details',
            tunisia: 'Contact us in Tunisia',
            india: 'Our partners in India',
            china: 'Our partners in China',
          },
          registration: {
            seoTitle: 'Tunis Consulting & Assisting: Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting visa services. We provide comprehensive assistance for various visa types. Learn about visa requirements, application processes, and get expert guidance for a successful visa application.',
            title: 'Visa Services',
            intro: 'Whether you are planning a leisurely vacation, a business trip, or pursuing your academic dreams abroad, our visa services cover multiple countries, so you can embark on your adventure with confidence. Explore our diverse range of visa options and let us assist you in realizing your travel ambitions.',
            china: 'China',
            india: 'India',
            southafrica: 'South Africa',
            germany: 'Germany',
            france: 'France',
            spain: 'Spain',
            uk: 'United Kingdom',
            belgium: 'Belgium',
            businessvisa: 'Business Visa',
            studentvisa: 'Student Visa',
            visatypes: 'Visa Types',
            form: 'Whether you are submitting a personal application through us or are interested in engaging Tunis Consulting & Assisting as your organization\'s visa provider, we are excited to assist you. Please complete the form with the required documents so that we can proceed with your request.',
            button: 'Apply for Visa Now',
            note: 'All documents should be sent as scanned PDFs using a scanner.',
        },
        china: {
          seoTitle: 'Tunis Consulting & Assisting: China Visa Services',
          seodescription: 'Discover Tunis Consulting & Assisting services for obtaining a visa to China. Our expert team provides comprehensive assistance, guiding you through the visa application process. Learn about the requirements, documents, and steps for a successful China visa application.',
          subtitle: 'Catalyzing Your Chinese Journey: Seamless Work and Student Visa Solutions',
          h6: 'Visa to China',
          description1: 'Are you considering a journey to China for work or study? At Tunis Consulting & Assisting, we are your trusted partner in simplifying the visa application process, whether you are pursuing career opportunities or seeking higher education in China. Our mission is to provide tailored visa solutions that streamline your path to success.',
          description2: 'To get started, choose one of the tabs below to access all the necessary information for your specific journey:',
          businesstitle: 'Business Visa',
          studenttitle: 'Student visa',
          othertitle: 'Family visa',
        },
        chinabusinessvisa: {
          seoTitle: 'Tunis Consulting & Assisting Business China Visa: Procedures and List of Documents for Submitting a Visa Application for China | Embassy of China in Tunisia',
          seodescription: 'Explore Tunis Consulting & Assisting services for Business Visa to China. Our expert team provides comprehensive assistance, guiding you through the visa application process. Learn about requirements, documents, and steps for a successful China business visa application.',
          title: 'China Business Visa',
          sub1: 'Your journey to China begins with us !',
          sub2: 'Our team of experts is here to guide you through the entire visa application process, providing comprehensive support to meet your specific requirements.',
          sub3: 'Simply provide us with the required documents through our form link below, and let our expert team take care of every detail of your visa request.',
          sub4: 'A business visa is a type of Chinese visa designed for individuals traveling to China for business-related purposes. It allows foreign nationals to visit China for meetings, conferences, negotiations, and other commercial activities.',
          sub5: 'Visa types varies depending on the purpose, with both the M visa and Z visa designated for business purposes. Please refer to the section below for a detailed overview of the required documents for each visa.',
          visaM: {
            title: 'Visa M',
            def1: 'Visa M is a special visa issued to visit China for commercial and trade activities and it is also issued to competition participants.',
            def2: 'Also called business visas, holders can enter China with it to visit partners, attend fairs or meet with Suppliers.',
            requirements: 'Visa M Requirements :', 
            initial: 'Initial Entry :',
            preentry: 'Pre-entry :',
            swift: 'Pre-entry to China via SWIFT method for Tunisian nationality only :',
            folder: 'Folder preparation (The client has a government invitation) :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 3x4',
            govInv1:'Government Invitation',
            govInv2: 'With previous visas to China if it exists',
            oldvisa1: 'Photo of previous Visas',
            oldvisa2: 'Photo of previous visas with entry and exit stamps, and a photo of the old passport if renewed',
            norInv1: 'Normal Invitation',
            norInv2: '',
            olvisa1: 'Last Previous Visa after 2016',
            olvisa2: 'With a copy of old passport if it contains the last previous visa and has been renewed',
            rne: 'Commercial Register if it exists',
            invoice: 'Invoice from the Chinese company',
            transfer1: 'Swift Bank transfer',
            transfer2: 'Swift Bank transfer of more than $10,000 matching and with the same company name as on the invoice',
          },
          visaZ: {
            title: 'Visa Z',
            def: 'China Work visa (Visa Z) is issued to those who have obtained employment permit and intend to work in China.',
            requirements: 'Visa Z Requirements :',
            initial: 'Initial Entry :',
            renewal: 'Renewal of Visa Z :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 3x4',
            govInv1:'Government Invitation',
            govInv2: 'If you don\'t have a government invitation, send us the previous old visas to China as shown in the next section, and we will provide one for you',
            oldvisa1: 'Photo of previous Visas',
            oldvisa2: 'Photo of previous visas with entry and exit stamps, and a photo of the old passport if renewed',
            norInv1: 'Letter of Invitation from your company',
            permit: 'Valid Work Permit (currently in effect)',
            license: 'Company License',
            residence: 'Last Residence Permit',
            house: 'Rental Contract in China',
          },
        },
        chinastudentvisa: {
          seoTitle: 'Tunis Consulting & Assisting Student China Visa: Procedures and List of Documents for Submitting a Visa Application for China | Embassy of China in Tunisia',
          seodescription: 'Explore the process of obtaining a Student Visa to China with Tunis Consulting & Assisting. Our expert team provides comprehensive guidance through the visa application process. Learn about visa requirements, necessary documents, and steps to ensure a successful application for studying in China.',
          title: 'China Student Visa',
          sub1: 'Student Visa is issued to those who have been admitted by a Chinese college.',
          sub2: 'There are 2 types of student visa, X1 and X2. Please refer to the section below for a detailed overview of the required documents for each visa.',
          sub3: 'Simply provide us with the required documents through our form link below, and let our expert team take care of every detail of your visa request.',
          visaX1: {
            title: 'Visa X1',
            def: 'X1 is issued to students who goes to China for study for more than 6 months and can be issued for multiple entry with validity up to 5 years.',
            requirements: 'Visa X1 Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 3x4',
            letter1: 'Admission Notice from the Chinese university',
            letter2: 'The original admission notice is required.',
            diploma: 'Copy of last Diploma',
            bank1: 'Financial Declaration',
            bank2: 'Bank statement or currency declaration of more than $2000.',
          },
          visaX2: {
            title: 'Visa X2',
            def: 'X2 is issued to students who goes to China for study for a period of less than 6 months and it is issued for single-entry only.',
            requirements: 'Visa X2 Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 3x4',
            letter1: 'Admission Notice from the Chinese university',
            letter2: 'The original admission notice is required.',
            diploma: 'Copy of last Diploma',
            bank1: 'Financial Declaration',
            bank2: 'Bank statement or currency declaration of more than $2000.',
          }
        },
        chinapersonalvisa: {
          seoTitle: 'Tunis Consulting & Assisting Personal China Visa: Procedures and List of Documents for Submitting a Visa Application for China | Embassy of China in Tunisia',
          seodescription: 'Explore the seamless process of obtaining a family visit visa to China with Tunis Consulting. Our expert team provides detailed guidance on document submission, including passports, personal photos, birth certificates, invitations, and residence permits. Navigate the specific requirements for various family visas, ensuring a smooth application process. Trust us to make your family visit to China a reality.',
          title: 'Family Visa to China',
          sub1: 'China offers a range of private visas catering to individuals with specific family-related purposes or personal visits.',
          sub2: 'These visas, namely S1, S2, and Q, are designed to accommodate the diverse needs of those seeking to reunite with family members or engage in personal matters within the country.Please refer to the section below for a detailed overview of the required documents for each visa.',
          sub3: 'Simply provide us with the required documents through our form link below, and let our expert team take care of every detail of your visa request.',
          visaS: {
            title: 'Visa S1',
            title2: 'Visa S2',
            def1: 'The S1 visa is issued to those who intend to move to China to live with their immediate family members, who are either Chinese citizens or foreigners holding a Chinese permanent residence permit.',
            def2: 'As for the S2 visa, it is issued to those who plan to visit their family members in China for a short period.',
            def3: 'This visa is issued to individuals whose one of their immediate have obtained a Visa M.',
            requirements: 'Visa S1 Requirements :',
            requirements2: 'Visa S2 Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 3x4',
            invitation: 'An invitation letter from the inviter',
            house: 'Rental Contract in China',
            pass3: 'Copy of the inviter\'s passport',
            residence: 'Copy of the inviter\’s residence permit',
            acte1: 'Birth Certificate',
            acte2: 'Inviter\'s birth certificate ',
            passM: 'Passport of the M visa holder',
            visaM: 'Photo of the Visa M',
          },
          visaQ: {
            title: 'Visa Q',
            def: 'Visa Q is issued to foreigners who are family members of Chinese citizens or permanent residents.',
            requirements: 'Visa Q Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 3x4',
            invitation: 'An invitation letter from the inviter',
            pass4: 'Copy of the Chinese ID card or foreign passport and the permanent residence permit for the inviter',
            kinship1: 'Proof of kinship',
            kinship2: 'Copy of certification (marriage certificate, birth certificate, certification of kinship issued by Public Security Bureau or notarized certification of kinship) showing the relationship of family members between applicant and inviting individual.',
          },
        },
        india: {
          seoTitle: 'Tunis Consulting & Assisting: Visa to India',
          seodescription: 'Explore the services of Tunis Consulting & Assisting for obtaining a visa to India. Our expert team provides comprehensive assistance, guiding you through the visa application process. Learn about the requirements, documents, and steps for a successful visa application to India.',
          subtitle:'Unlocking Success, Healing, and Knowledge: Your Hassle-Free Visa Journey to India',
          h6: 'Visa to India',
          description1:'Unlock success, healing, and knowledge effortlessly with Tunis Consulting & Assisting.',
          description2: 'Our streamlined visa process ensures swift approvals, tailored to your unique needs. Your journey to India starts here – hassle-free and promising:',
          businesstitle: 'Business Visa',
          studenttitle: 'Student Visa',
          medicaltitle: 'Medical Visa',
          businessvisa: {
            seoTitle: 'Tunis Consulting & Assisting: India Business Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting services for obtaining a business visa to India. Our expert team provides comprehensive assistance, guiding you through the business visa application process. Learn about the requirements, documents, and steps for a successful India business visa application.',
            title: 'India Business Visa',
            sub1: 'The Indian Business Visa caters to individuals seeking to engage in commercial pursuits within India. Whether it\'s the exchange of goods and services, active participation in business meetings, establishment of industrial ventures, or contributing to trade fairs, this visa offers a gateway to diverse business opportunities. At TCA, we specialize in facilitating and providing seamless access to the Indian Business Visa. Our dedicated services ensure a smooth application process, allowing you to focus on your business objectives while we handle the intricacies of visa acquisition.',
            sub2: '',
            requirements: 'India Business Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 5x5',
            bank: 'Latest Bank Statement if it exists',
            invitation1: 'Business Invitation Letter from India',
            invitation2: 'if it it exists (otherwise we can provide you one)',
            registration: 'Company License (RNE for Tunisian Companies) if it exists',
            oldvisa: 'Photo of previous visas if it exists',
          },
          studentvisa: {
            seoTitle: 'Tunis Consulting & Assisting: India Student Visa Services',
            seodescription: 'Discover Tunis Consulting & Assisting services for obtaining a student visa to India. Our expert team provides comprehensive assistance, guiding you through the student visa application process. Learn about the requirements, documents, and steps for a successful India student visa application.',
            title: 'India Student Visa',
            sub1: 'Embark on your academic journey in India with the Student Visa, designed for individuals aspiring to pursue education in this culturally rich nation. Whether you aim to enroll in a university, attend specialized courses, or engage in research projects, the Student Visa opens doors to a world of learning opportunities.',
            sub2: 'At TCA, we specialize in facilitating and providing comprehensive support for the Indian Student Visa. Our tailored services ensure a hassle-free application process, allowing you to focus on your educational goals while we handle the complexities of visa acquisition.',
            requirements: 'India Student Visa Requirements',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 5x5',
            diploma: 'Copy of last Diploma',
            invitation: 'Admission Notice from the Indian university',
            receipt: 'University Paiement Receipt',
            parpass: 'Parent\'s Passeport',
            parbank: 'Parent\'s Bank Statement',
            evidence: 'Parent\'s Evidence of financial resources to cover your living expenses',
          },
          medicalvisa : {
            seoTitle: 'Tunis Consulting & Assisting: India Medical Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting services for obtaining a medical visa to India. Our expert team provides comprehensive assistance, guiding you through the medical visa application process. Learn about the requirements, documents, and steps for a successful India medical visa application.',
            title: 'India Medical Visa',
            sub1: 'Embark on a journey of healing and wellness with the Medical Visa for India, tailored for individuals seeking medical treatment in this diverse and vibrant country. Whether it\'s specialized medical procedures, consultations, or therapeutic interventions, the Medical Visa facilitates access to India\'s renowned healthcare facilities.',
            sub2: 'At TCA, we specialize in streamlining the process of obtaining the Indian Medical Visa, providing compassionate support for those in need of medical care. Our dedicated services ensure a seamless application process, allowing you to prioritize your health while we handle the intricacies of visa acquisition.',
            requirements: 'India Medical Visa Requirements',
            req1: '1) Basic Requirements',
            req2: '2) Additional Requirements if you have a Companion',
            req3: 'Coverage of Accommodation and Treatment Expenses',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 5x5',
            hospitallInv: 'Hospital Invitation Letter',
            cert1: 'Medical Report',
            cert2: 'Medical certificate describing the medical condition of the patient (in English)',
            comppass1: 'Photo of the campanion\'s passport data page',
            comppass2: 'Valid passport for at least 6 months with one blank page for official use',
            compphoto1: 'Campanion\'s Photo',
            compphoto2: 'One personal photo on white background, size 5x5',
            own: 'On your Own :',
            bank: 'Latest Personal Bank Statement',
            comp: 'Or On your Campnion :',
            compba: 'Companion Latest Bank Statement',
            compev: 'Companion\'s Evidence of financial resources to cover your expenses',
          },
        },
        southafrica: {
          seoTitle: 'Tunis Consulting & Assisting: Visa Services for South Africa',
          seodescription: 'Explore Tunis Consulting & Assisting services for obtaining a visa to South Africa. Our expert team provides comprehensive assistance, guiding you through the visa application process for South Africa. Learn about the requirements, documents, and steps for a successful South Africa visa application.',
          subtitle: 'Discover South Africa: Your Gateway to Exceptional Journeys',
          h6: 'Visa to South Africa',
          description1: 'Embark on a Journey with TCA for Seamless Tourist, Student, and Business Visa Experiences in South Africa',
          description2: 'Discover, Thrive, and Prosper – Unleash the Potential of South Africa with Us !',
          businesstitle: 'Business Visa',
          studenttitle: 'Student Visa',
          touristtitle: 'Tourist Visa',
          familytitle: 'Family Visa',
          studentvisa: {
            seoTitle: 'Tunis Consulting & Assisting: South Africa Student Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting services for obtaining a South Africa Student Visa. Our expert team provides comprehensive assistance, guiding you through the student visa application process for South Africa. Learn about the requirements, documents, and steps for a successful South Africa student visa application.',
            title: 'South Africa Student Visa',
            sub1: 'Embark on your academic journey in South Africa with the Student Visa, designed for individuals aspiring to pursue education in this culturally rich nation. Whether you aim to enroll in a university, attend specialized courses, or engage in research projects, the Student Visa opens doors to a world of learning opportunities.',
            sub2: 'At TCA, we specialize in facilitating and providing comprehensive support for the South African Student Visa. Our tailored services ensure a hassle-free application process, allowing you to focus on your educational goals while we handle the complexities of visa acquisition.',
            requirements: 'South Africa Student Visa Requirements',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 1 month with 2 blank pages for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 4x6',
            payment: 'Payment of the visa application fees',
            report: 'Medical Report with X-rays',
            cert: 'vaccination certificate (if required)',
            crime1: 'Criminal Background Check Report',
            ins1: 'Health Insurance from South Africa',
            ins2: 'With the possibility of providing it from our office',
            inv1: 'Acceptance Letter',
            inv2: 'With the duration of the course or accredited certificate from the educational institution, authenticated by the police or accredited institute with the possibility of providing it from our office.',
            parpass: 'If minor, Copy of guarantor\'s passport',
            letter: 'If minor, Guarantee letter from your guarantor',
            bank1: 'Proof of the availability of necessary financial means',
            bank2: 'Proof of the availability of necessary financial means during the stay and payment of tuition fees (bank statement for the last 3 months)',
          },
          businessvisa: {
            seoTitle: 'Tunis Consulting & Assisting: South Africa Business Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting services for obtaining a South Africa Business Visa. Our expert team provides comprehensive assistance, guiding you through the business visa application process for South Africa. Learn about the requirements, documents, and steps for a successful South Africa business visa application.',
            title: 'South Africa Business Visa',
            sub1: 'The South African Business Visa caters to individuals seeking to engage in commercial pursuits within South Africa. Whether it\'s the exchange of goods and services, active participation in business meetings, establishment of industrial ventures, or contributing to trade fairs, this visa offers a gateway to diverse business opportunities. At TCA, we specialize in facilitating and providing seamless access to the South African Business Visa. Our dedicated services ensure a smooth application process, allowing you to focus on your business objectives while we handle the intricacies of visa acquisition.',
            sub2: '',
            requirements: 'South Africa Business Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 1 month with 2 blank pages for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 4x6',
            letter1: 'Letter from the Company if it exists',
            letter2: 'This letter provides the Cause of the travel',
            inv: 'Invitation Letter from the South African Company',
            bank1: 'Bank Statement',
            bank2: 'Latest 3 Months Bank Statement',
          },
          touristvisa: {
            seoTitle: 'Tunis Consulting & Assisting: South Africa Tourist Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting services for obtaining a South Africa Tourist Visa. Our expert team provides comprehensive assistance, guiding you through the tourist visa application process for South Africa. Learn about the requirements, documents, and steps for a successful South Africa tourist visa application.',
            title: 'South Africa Tourist Visa',
            sub1: 'Our service offers a seamless and efficient process for obtaining a tourist visa to South Africa. Whether you\'re planning to explore the vibrant cities, experience the breathtaking landscapes, or indulge in the rich cultural heritage of South Africa, we are here to assist you. Our team ensures a hassle-free application process, guiding you through the necessary steps to secure your visa with ease.',
            sub2: 'Discover the beauty of South Africa with confidence, knowing that our service is dedicated to making your travel experience memorable and stress-free.',
            requirements: 'South Africa Tourist Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 1 month with 2 blank pages for official use',
            photo1: 'Personal Photo',
            photo2: 'One personal photo on white background, size 4x6',
            bank1: 'Bank Statement',
            bank2: 'Last 3 Months Bank Statement if it exists',
            letter1: 'Letter from work',
            letter2: 'Stating the date of the holiday and the corresponding salary',
          },
          familyvisa: {
            seoTitle: 'Tunis Consulting & Assisting: South Africa Family Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting services for obtaining a South Africa Family Visa. Our expert team provides comprehensive assistance, guiding you through the family visa application process for South Africa. Learn about the requirements, documents, and steps for a successful South Africa family visa application.',
            title: 'South Africa Family Visa',
            sub1: 'Our service provides a streamlined and effective process for acquiring a family visit visa to South Africa. Whether you\'re reuniting with relatives, enjoying holiday gatherings, or simply spending quality time with loved ones, we\'re here to facilitate your journey. Our team ensures a smooth application process, guiding you through all the necessary steps to obtain your visa effortlessly.',
            sub2: 'Embark on your family adventure in South Africa with confidence, knowing that our service is dedicated to making your visit unforgettable and stress-free.',
            requirements: 'South Africa Family Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 1 month with 2 blank pages for official use',
            passport: 'Copy of Parent\'s Passport',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 4x6',
            visa: 'Copy of Visa',
            bcert: 'Birth Certificate',
            mcert: 'Marriage Certificate',
            fcert: 'Family Status Certificate',
            crime1: 'Criminal Background Check Report from South Africa',
            crime2: 'Criminal Background Check Report from Libya',
            inv: 'Normal Invitation',
            ins: 'Health Insurance from South Africa',
            bank: 'Parent\'s Bank Statement for the last 3 months',
            proof: 'Undertaking by the parent that he is able to support the flight and the residence\'s fees',
          },
        },
        germany: {
          seoTitle: 'Tunis Consulting & Assisting: Germany Visa Services',
          seodescription: 'Explore Tunis Consulting & Assisting Germany visa services. Our expert team provides comprehensive assistance for various types of German visas. Learn about visa requirements, application processes, and get expert guidance for a successful Germany visa application.',
          subtitle: 'Unlock Seamless Journeys: Your Comprehensive Guide to Effortless Visa Solutions for Germany',
          h6: 'Visa to Germany',
          description1: 'At TCA, we specialize in making the visa application process easy and efficient, particularly for medical and business visas to Germany',
          description2: 'Our streamlined services ensure a smooth experience, allowing you to focus on your medical or business endeavors while we take care of the visa complexities. Choose simplicity with us.',
          businesstitle: 'Business Visa',
          medicaltitle: 'Medical Visa',
          touristtitle: 'Tourist Visa',
          businessvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Germany Business Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting Germany business visa services. Our expert team provides comprehensive assistance for obtaining a business visa to Germany. Learn about visa requirements, application processes, and get expert guidance for a successful Germany business visa application.',
            title: 'Germany Business Visa',
            sub1:'A work visa in Germany is a permit granted by the German government to individuals who wish to work in Germany either temporarily or permanently.',
            sub2: '',
            requirements: 'Germany Business Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            invitation: 'Business Invitation Letter from the german company',
            fam: 'Family Status Certificate',
            bank1: 'Bank Statement',
            bank2: 'Latest 3 Months Bank Statement',
            attravail: 'Employment Attestation or Company License',
            invoices: 'Invoices from the German company if available',
          },
          medicalvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Germany Medical Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting Germany medical visa services. Our expert team provides comprehensive assistance for obtaining a medical visa to Germany. Learn about visa requirements, application processes, and get expert guidance for a successful Germany medical visa application.',
            title: 'Germany Medical Visa',
            sub1: 'Embark on a journey of healing and wellness with the Medical Visa for Germany, tailored for individuals seeking medical treatment in this diverse and vibrant country. Whether it\'s specialized medical procedures, consultations, or therapeutic interventions, the Medical Visa facilitates access to Germany\'s renowned healthcare facilities.',
            sub2: 'At TCA, we specialize in streamlining the process of obtaining the German Medical Visa, providing compassionate support for those in need of medical care. Our dedicated services ensure a seamless application process, allowing you to prioritize your health while we handle the intricacies of visa acquisition.',
            requirements: 'Germany Medical Visa Requirements',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            cert1: 'Medical Report',
            cert2: 'Medical certificate describing the medical condition of the patient (in English)',
            inv1: 'Hospital Invitation Letter',
            inv2: 'Including the appointment details, cost of treatment and the patient’s and his companion’s details',
            transfer1: 'Swift Bank Transfer',
            transfer2: 'Swift Bank Transfer of the treatment\’s cost as mentioned in the hospital invitation letter',
            minor : 'If the patient is young :',
            parpass: 'Copy of Parent\'s passport',
            cert: 'Birth Certificate',
            fam: 'Family Status Certificate',
            evidence: 'Parent\'s Evidence of financial resources to cover your expenses',
          },
          touristvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Germany Tourist Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting Germany tourist visa services. Our expert team provides comprehensive assistance for obtaining a tourist visa to Germany. Learn about visa requirements, application processes, and get expert guidance for a successful Germany tourist visa application.',
            title: 'Germany Tourist Visa',
            sub1: 'Our service offers a seamless and efficient process for obtaining a tourist visa to Germany. Whether you\'re planning to explore the charming cities, immerse yourself in the rich history, or savor the diverse cultural experiences of Germany, we are here to assist you. Our team ensures a hassle-free application process, guiding you through the necessary steps to secure your visa with ease.',
            sub2: 'Discover the beauty of Germany with confidence, knowing that our service is dedicated to making your travel experience memorable and stress-free.',
            requirements: 'Germany Tourist Visa Requirements',
            presence: 'Possibility of applying without presence in case of having a previous visa that has not exceeded 5 years',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            fam: 'Family Status Certificate',
            attravail: 'Employment Attestation or Company License',
            bank: 'Last 3 Bank Statements',
          },
        },
        france: {
          seoTitle: 'Tunis Consulting & Assisting: France Visa Services',
          seodescription: 'Explore Tunis Consulting & Assisting France visa services. Our expert team provides comprehensive assistance for obtaining a visa to France. Learn about visa requirements, application processes, and get expert guidance for a successful France visa application.',
          subtitle: 'Unlock Seamless Journeys: Your Comprehensive Guide to Effortless Visa Solutions for France',
          h6: 'Visa to France',
          description1: 'At TCA, we specialize in making the visa application process easy and efficient, particularly for medical and business visas to France',
          description2: 'Our streamlined services ensure a smooth experience, allowing you to focus on your medical or business endeavors while we take care of the visa complexities. Choose simplicity with us.',
          businesstitle: 'Business Visa',
          medicaltitle: 'Medical Visa',
          touristtitle: 'Tourist Visa',
          businessvisa: {
            seoTitle: 'Tunis Consulting & Assisting: France Business Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting France business visa services. Our expert team provides comprehensive assistance for obtaining a business visa to France. Learn about business visa requirements, application processes, and get expert guidance for a successful France business visa application.',
            title: 'France Business Visa',
            sub1:'A work visa in France is a permit granted by the French government to individuals who wish to work in France either temporarily or permanently.',
            sub2: '',
            requirements: 'France Business Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            invitation: 'Business Invitation Letter from the French company',
            fam: 'Family Status Certificate',
            bank1: 'Bank Statement',
            bank2: 'Latest 3 Months Bank Statement',
            attravail: 'Employment Attestation or Company License',
            invoices: 'Invoices from the French company if available.',
          },
          medicalvisa: {
            seoTitle: 'Tunis Consulting & Assisting: France Medical Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting France medical visa services. Our expert team provides comprehensive assistance for obtaining a medical visa to France. Learn about medical visa requirements, application processes, and get expert guidance for a successful France medical visa application.',
            title: 'France Medical Visa',
            sub1: 'Embark on a journey of healing and wellness with the Medical Visa for France, tailored for individuals seeking medical treatment in this diverse and vibrant country. Whether it\'s specialized medical procedures, consultations, or therapeutic interventions, the Medical Visa facilitates access to France\'s renowned healthcare facilities.',
            sub2: 'At TCA, we specialize in streamlining the process of obtaining the French Medical Visa, providing compassionate support for those in need of medical care. Our dedicated services ensure a seamless application process, allowing you to prioritize your health while we handle the intricacies of visa acquisition.',
            requirements: 'France Medical Visa Requirements',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            cert1: 'Medical Report',
            cert2: 'Medical certificate describing the medical condition of the patient',
            inv1: 'Hospital Invitation Letter',
            inv2: 'Including the appointment details, cost of treatment and the patient’s and his companion’s details',
            transfer1: 'Swift Bank Transfer',
            transfer2: 'Swift Bank Transfer of the treatment\’s cost as mentioned in the hospital invitation letter',
            minor : 'If the patient is young :',
            parpass: 'Copy of Parent\'s passport',
            cert: 'Birth Certificate',
            fam: 'Family Status Certificate',
            evidence: 'Parent\'s Evidence of financial resources to cover your expenses',
          },
          touristvisa: {
            seoTitle: 'Tunis Consulting & Assisting: France Tourist Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting France tourist visa services. Our expert team provides comprehensive assistance for obtaining a tourist visa to France. Learn about tourist visa requirements, application processes, and get expert guidance for a successful France tourist visa application.',
            title: 'France Tourist Visa',
            sub1: 'Our service offers a seamless and efficient process for obtaining a tourist visa to France. Whether you\'re planning to explore the charming cities, immerse yourself in the rich history, or savor the diverse cultural experiences of France, we are here to assist you. Our team ensures a hassle-free application process, guiding you through the necessary steps to secure your visa with ease.',
            sub2: 'Discover the beauty of France with confidence, knowing that our service is dedicated to making your travel experience memorable and stress-free.',
            requirements: 'France Tourist Visa Requirements',
            presence: 'Possibility of applying without presence in case of having a previous visa that has not exceeded 5 years',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            fam: 'Family Status Certificate',
            attravail: 'Employment Attestation or Company License',
            bank: 'Last 3 months Bank Statements',
          },
        },
        spain: {
          seoTitle: 'Tunis Consulting & Assisting: Spain Visa Services',
          seodescription: 'Explore Tunis Consulting & Assisting Spain visa services. Our expert team provides comprehensive assistance for obtaining a visa to Spain. Learn about Spain visa requirements, application processes, and get expert guidance for a successful Spain visa application.',
          subtitle: 'Unlock Seamless Journeys: Your Comprehensive Guide to Effortless Visa Solutions for Spain',
          h6: 'Visa to Spain',
          description1: 'At TCA, we specialize in making the visa application process easy and efficient, particularly for medical and business visas to Spain',
          description2: 'Our streamlined services ensure a smooth experience, allowing you to focus on your medical or business endeavors while we take care of the visa complexities. Choose simplicity with us.',
          businesstitle: 'Business Visa',
          studenttitle: 'Student Visa',
          residencetitle: 'Residence Visa',
          touristtitle: 'Tourist Visa',
          businessvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Spain Business Visa Services',
            seodescription: 'Tunis Consulting & Assisting: Spain Business Visa Services',
            title: 'Spain Business Visa',
            sub1:'Business visa in Spain is a permit granted by the Spanish government to individuals who wish to work in spain either temporarily or permanently.',
            sub2: '',
            requirements: 'Spain Business Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background',
            invitation: 'Business Invitation Letter from the Spanish company',
            bank1: 'Bank Statement',
            bank2: 'Latest 3 Months Bank Statement',
            attravail: 'Employment Attestation or Company License',
          },
          studentvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Spain Student Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting Spain student visa services. Our expert team provides comprehensive assistance for obtaining a student visa to Spain. Learn about Spain student visa requirements, application processes, and get expert guidance for a successful Spain student visa application.',
            title: 'Spain Student Visa',
            sub1: 'Embark on your academic journey in Spain with the Student Visa, designed for individuals aspiring to pursue education in this culturally rich nation. Whether you aim to enroll in a university, attend specialized courses, or engage in research projects, the Student Visa opens doors to a world of learning opportunities.',
            sub2: 'At TCA, we specialize in facilitating and providing comprehensive support for the Spanish Student Visa. Our tailored services ensure a hassle-free application process, allowing you to focus on your educational goals while we handle the complexities of visa acquisition.',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background',
            diploma: 'Copy of last Diploma',
          },
          residencevisa: {
            seoTitle: 'Tunis Consulting & Assisting: Spain Residence Visa Services',
            seodescription: 'Discover Tunis Consulting & Assisting Spain residence visa services. Our expert team offers comprehensive assistance for obtaining a residence visa in Spain. Learn about Spain residence visa requirements, application processes, and get expert guidance for a successful Spain residence visa application.',
            title: 'Spain Residence Visa',
            sub1: 'The residence visa for Spain is a type of visa that allows individuals to live in Spain for an extended period.',
            requirements: 'Spain Residence Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background',
            att: 'Company License or Commercial Registry',
            chamber: 'Commercial Chamber Registration Certificate',
            fam: 'Family Status Certificate',
          },
          touristvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Spain Tourist Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting Spain tourist visa services. Our expert team provides comprehensive assistance for obtaining a tourist visa to Spain. Learn about Spain tourist visa requirements, application processes, and get expert guidance for a successful Spain tourist visa application.',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background',
            bank1: 'Bank Statement',
            bank2: 'Last 3 Months Bank Statements',
            attravail: 'Employment Attestation or Company License',
            salary :'Salary Statement for the last 3 months',
            cnss: 'National Social Security Fund Statement',
            bank1: 'Bank Statement',
            bank2: 'Latest 3 Months Bank Statement',
          },
        },
        uk: {
          seoTitle: 'Tunis Consulting & Assisting: United Kingdom Visa Services',
          seodescription: 'Explore Tunis Consulting & Assisting United Kingdom visa services. Our expert team provides comprehensive assistance for obtaining a visa to the United Kingdom. Learn about United Kingdom visa requirements, application processes, and get expert guidance for a successful UK visa application.',
          subtitle: 'Your Journey Begins Here: Navigating UK Visas with TCA',
          h6: 'Visa to United Kingdom',
          description1: 'Welcome to TCA, your dedicated ally for hassle-free visa solutions to the United Kingdom.',
          description2: 'Whether you\'re seeking medical care, pursuing education, exploring tourist attractions, or delving into business opportunities, we\'re here to simplify the visa process, ensuring your pathway to the UK is as smooth as possible.',
          businesstitle: 'Business Visa',
          studenttitle: 'Student Visa',
          medicaltitle: 'Medical Visa',
          touristtitle: 'Tourist Visa',
          businessvisa: {
            seoTitle: 'Tunis Consulting & Assisting: United Kingdom Business Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting United Kingdom business visa services. Our expert team provides comprehensive assistance for obtaining a business visa to the United Kingdom. Learn about United Kingdom business visa requirements, application processes, and get expert guidance for a successful UK business visa application.',
            title: 'UK Business Visa',
            sub1: 'At TCA, we recognize the pivotal role of global business connections.',
            sub2: 'Our Business Visa services are tailored to facilitate your entry into the vibrant business landscape of the United Kingdom. From corporate executives to entrepreneurs, our personalized support ensures your business ventures thrive in the UK market.',
            requirements: 'UK Business Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background',
            inv: 'Business Invitation Letter from the British company',
            ins1: 'Health Insurance from UK',
            ins2: 'With the possibility of providing it from our office',
            prop: 'Copy of Personal Property Certificates',
          },
          studentvisa: {
            seoTitle: 'Tunis Consulting & Assisting: United Kingdom Student Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting United Kingdom student visa services. Our expert team provides comprehensive assistance for obtaining a student visa to the United Kingdom. Learn about United Kingdom student visa requirements, application processes, and get expert guidance for a successful UK student visa application.',
            title: 'UK Student Visa',
            sub1: 'Embark on a transformative educational adventure with TCA.',
            sub2: 'Our Student Visa services are personalized to assist students in realizing their academic dreams in the United Kingdom. From application guidance to visa processing, we\'re committed to supporting you at every stage, paving the way for a successful academic journey.',
            requirements: 'UK Student Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background',
            inv: 'University Acceptance Letter with the Academic Program if it exists',
            res: 'Payment for a minimum of 3 months of residence or Family Reunification documents',
            ins1: 'Health Insurance from UK',
            ins2: 'With the possibility of providing it from our office',
          },
          medicalvisa: {
            seoTitle: 'Tunis Consulting & Assisting: United Kingdom Medical Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting United Kingdom medical visa services. Our expert team provides comprehensive assistance for obtaining a medical visa to the United Kingdom. Learn about United Kingdom medical visa requirements, application processes, and get expert guidance for a successful UK medical visa application.',
            title: 'UK Medical Visa',
            sub1: 'At TCA, we understand the significance of your health journey. Our Medical Visa services are crafted to provide a streamlined experience for individuals seeking medical treatment in the United Kingdom. ',
            sub2: 'Trust us to handle the visa process, allowing you to focus on your well-being with peace of mind.',
            requirements: 'UK Medical Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background',
            invitation: 'Hospital Invitation Letter',
            cert1: 'Medical Examination Report',
            cert2: 'Medical certificate describing the medical condition of the patient',
            payment: 'Payment of Hospital Bills',
            ins1: 'Health Insurance from UK',
            ins2: 'With the possibility of providing it from our office',
            att: 'Employment Attestation or Company License if it exists',
            prop: 'Copy of Personal Property Certificates',
            bank1: 'Bank Statement if it exists',
            bank2: 'Last 3 Months Bank Statements',
          },
          touristvisa: {
            seoTitle: 'Tunis Consulting & Assisting: United Kingdom Tourist Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting United Kingdom tourist visa services. Our expert team provides comprehensive assistance for obtaining a tourist visa to the United Kingdom. Learn about United Kingdom tourist visa requirements, application processes, and get expert guidance for a successful UK tourist visa application.',
            title: 'UK Tourist Visa',
            sub1: 'Discover the charm of the United Kingdom with ease. TCA simplifies the Tourist Visa application process, ensuring a seamless travel experience. ',
            sub2: 'Explore the wonders of the UK confidently, knowing that our dedicated team is committed to making your visa journey stress-free and enjoyable.',
            requirements: 'UK Tourist Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least 6 months with one blank page for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background',
            att: 'Employment Attestation or Company License if it exists',
            fam: 'Family Status Certificate',
            bank1: 'Bank Statement if it exists',
            bank2: 'Last 3 Months Bank Statements',
            prop: 'Copy of Personal Property Certificates',
            ins1: 'Health Insurance from UK',
            ins2: 'With the possibility of providing it from our office',
          },
        },
        belgium: {
          seoTitle: 'Tunis Consulting & Assisting: Belgium Visa Services',
          seodescription: 'Explore Tunis Consulting & Assisting Belgium visa services. Our expert team provides comprehensive assistance for various types of Belgian visas. Learn about visa requirements, application processes, and get expert guidance for a successful Belgium visa application.',
          subtitle: 'Unlock Seamless Journeys: Your Comprehensive Guide to Effortless Visa Solutions for Belgium',
          h6: 'Visa to Belgium',
          description1: 'At TCA, we specialize in making the visa application process easy and efficient, particularly for medical and business visas to Belgium',
          description2: 'Our streamlined services ensure a smooth experience, allowing you to focus on your medical or business endeavors while we take care of the visa complexities. Choose simplicity with us.',
          businesstitle: 'Business Visa',
          medicaltitle: 'Medical Visa',
          touristtitle: 'Tourist Visa',
          businessvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Belgium Business Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting Belgium business visa services. Our expert team provides comprehensive assistance for obtaining a business visa to Belgium. Learn about visa requirements, application processes, and get expert guidance for a successful Belgium business visa application.',
            title: 'Belgium Business Visa',
            sub1:'A work visa in Belgium is a permit granted by the Belgian government to individuals who wish to work in Belgium either temporarily or permanently.',
            sub2: '',
            requirements: 'Belgium Business Visa Requirements :',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            invitation: 'Business Invitation Letter from the belgian company',
            fam: 'Family Status Certificate',
            bank1: 'Bank Statement',
            bank2: 'Latest 3 Months Bank Statement',
            attravail: 'Employment Attestation or Company License',
            invoices: 'Invoices from the Belgian company if available',
          },
          medicalvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Belgium Medical Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting Belgium medical visa services. Our expert team provides comprehensive assistance for obtaining a medical visa to Belgium. Learn about visa requirements, application processes, and get expert guidance for a successful Belgium medical visa application.',
            title: 'Belgium Medical Visa',
            sub1: 'Embark on a journey of healing and wellness with the Medical Visa for Belgium, tailored for individuals seeking medical treatment in this diverse and vibrant country. Whether it\'s specialized medical procedures, consultations, or therapeutic interventions, the Medical Visa facilitates access to Belgium\'s renowned healthcare facilities.',
            sub2: 'At TCA, we specialize in streamlining the process of obtaining the Belgian Medical Visa, providing compassionate support for those in need of medical care. Our dedicated services ensure a seamless application process, allowing you to prioritize your health while we handle the intricacies of visa acquisition.',
            requirements: 'Belgium Medical Visa Requirements',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            cert1: 'Medical Report',
            cert2: 'Medical certificate describing the medical condition of the patient (in English)',
            inv1: 'Hospital Invitation Letter',
            inv2: 'Including the appointment details, cost of treatment and the patient’s and his companion’s details',
            transfer1: 'Swift Bank Transfer',
            transfer2: 'Swift Bank Transfer of the treatment\’s cost as mentioned in the hospital invitation letter',
            minor : 'If the patient is young :',
            parpass: 'Copy of Parent\'s passport',
            cert: 'Birth Certificate',
            fam: 'Family Status Certificate',
            evidence: 'Parent\'s Evidence of financial resources to cover your expenses',
          },
          touristvisa: {
            seoTitle: 'Tunis Consulting & Assisting: Belgium Tourist Visa Services',
            seodescription: 'Explore Tunis Consulting & Assisting Belgium tourist visa services. Our expert team provides comprehensive assistance for obtaining a tourist visa to Belgium. Learn about visa requirements, application processes, and get expert guidance for a successful Belgium tourist visa application.',
            title: 'Belgium Tourist Visa',
            sub1: 'Our service offers a seamless and efficient process for obtaining a tourist visa to Belgium. Whether you\'re planning to explore the charming cities, immerse yourself in the rich history, or savor the diverse cultural experiences of Belgium, we are here to assist you. Our team ensures a hassle-free application process, guiding you through the necessary steps to secure your visa with ease.',
            sub2: 'Discover the beauty of Belgium with confidence, knowing that our service is dedicated to making your travel experience memorable and stress-free.',
            requirements: 'Belgium Tourist Visa Requirements',
            presence: 'Possibility of applying without presence in case of having a previous visa that has not exceeded 5 years',
            pass1: 'Photo of the passport data page',
            pass2: 'Valid passport for at least three months beyond your planned date of return to your home country with 2 blank pages for official use',
            photo1: '2 Personal Photos',
            photo2: '2 personal photos on white background, size 3x4',
            fam: 'Family Status Certificate',
            attravail: 'Employment Attestation or Company License',
            bank: 'Last 3 Bank Statements',
          },
        },
          footer: {
            link1: 'Quick Links',
            link2: 'Contact Details',
            bottom: 'All Rights Reserved © 2023 TCA',
          }
        }
      },
      ar: {
        translation: {
          description: {
            logo: 'شركة تونس للإستشارات والمساعدة',
            desc1: 'خدمات رجال الأعمال',
            desc2: 'حلول في التأشيرات والتجارة الدولية',
            address: 'عدد 85 شارع فلسطين عمارة القدس الطابق الثاني مكتب رقم 3 تونس, 1002',
          },
          nav: {
            home: 'الرئيسية',
            about: 'عن الشركة',
            services: 'خدماتنا',
            blog: 'Blog',
            contact: 'اتصل بنا',
            visa: 'التأشيرة',
          },
          home: {
            carousel: {
              slide1: {
                title:'شركة تونس للإستشارات والمساعدة',
                details:'حلول و خدمات متنوعة في التأشيرات و التجارة الدولية'
              },
              slide2: {
                title:'خدمات رجال الأعمال',
                details:'حلول مخصصة لرجال الأعمال بكفائة و جودة عالية'
              },
              slide3: {
                title:'تجارة عبر الحدود',
                details:'نقدم حلاً شاملاً للاستيراد والتصدير من وإلى الصين والهند ',
              },
              button1: 'اكتشف خدماتنا',
              button2: 'اتصل بنا',
            },
            about: {
              title1: ' نبذة ',
              title2: 'عن الشركة',
              title3: 'عن الشركة',
              description1: 'تأسست شركة تونس للإستشارات و المساعدة و خدمات رجال الأعمال سنة 2015 و منذ ذلك الحين، أصبحنا رائدين في تقديم خدمات عالية الجودة و الموثوقة بفضل فريق عمل مؤهل وذو خبرة يسعى دائمًا لتحقيق أهداف العملاء بكل سهولة وسلاسة.',
              description2: 'تتضمن خدماتنا الاستشارة و المساعدة في مجالات التأشيرات للسفر والعمل والدراسة والعلاج بالخارج والتصديقات السفارية و التصدير و الاستيراد من الصين و الهند، بالإضافة إلى تنظيم المؤتمرات و الحجوزات الفندقية و التعامل مع المنظمات و الجمعيات الخيرية.',
              description3:'إذا كنت بحاجة إلى المزيد من المعلومات أو ترغب في استشارة خدماتنا، فلا تتردد في الاتصال بنا. نحن هنا لمساعدتك في تحقيق أهدافك بكل ثقة و فعالية.',
              button: 'إكتشف المزيد'
            },
            services: {
              title1: 'خدمات ',
              title2: 'الشركة',
              description:'تتضمن مجموعة خدماتنا مجموعة متنوعة من الحلول المصممة لتلبية احتياجات عملائنا في مختلف القطاعات. نهدف إلى تقديم حلا شاملا و موثوقا في مجالات الأعمال و السفر و الشؤون الدولية. تتضمن خدماتنا ما يلي:',
              card1: {
                title:'خدمات التأشيرات',
                description:'تشمل خدماتنا في مجال التأشيرات مجموعة واسعة من الخيارات مع جميع السفارات'
              },
              card2: {
                title:'خدمات التصديق السفاري',
                description:'خدماتنا تشمل التصديق السفاري للوثائق الرسمية، مما يضمن أن تكون وثائقك معترف بها دولياً وشرعية'
              },
              card3: {
                title:'خدمات الإستيراد و التصدير',
                description:'تغطي خدمات الاستيراد والتصدير لدينا توريد ونقل البضائع من الصين والهند'
              },
              card4: {
                title:'تنظيم الإجتماعات و المؤتمرات',
                description:'تشمل خدماتنا تنظيم المؤتمرات والاجتماعات والفعاليات الخاصة بكل احترافية واهتمام بالتفاصيل'
              },
              card5: {
                title:'حجوزات الفنادق',
                description:'نقدم خدمات حجوزات الفنادق بمهنية عالية لضمان إقامة مريحة ومثالية لعملائنا'
              },
              card6: {
                title:'التعاون مع المنظمات الخيرية و الجمعيات',
                description:'نتعاون مع المنظمات الخيرية والجمعيات لدعم مبادراتهم'
              }
            }
          },
          about: {
            seoTitle: 'عن تونس للاستشارات والمساعدة: شريك موثوق في حلول التأشيرة',
            seodescription: 'تعرف على المزيد حول شركة تونس للإستشارات والمساعدة. اكتشف مهمتنا، قيمنا، والخدمات الشاملة للتأشيرات والهجرة التي نقدمها. تقدم شركتنا خدمات استشارية ومساعدة في مجالات متنوعة، بما في ذلك التأشيرات والتصديقات السفارية، ومساعدة الشركات في التصدير والاستيراد من الصين والهند. نحن أيضًا متخصصون في تنظيم المؤتمرات وحجز الفنادق، ونقدم خدمات تنسيق مع المنظمات والجمعيات الخيرية.',
            title: 'من نحن؟',
            description1:'شركة تونس للاستشارات والمساعدة وخدمات رجال الأعمال هي وجهتك الشاملة لتلبية احتياجاتك في مجالات متعددة. تأسست في عام 2015 ومنذ ذلك الحين، أصبحنا رائدين في تقديم خدمات عالية الجودة والموثوقة.تقدم شركتنا خدمات استشارية ومساعدة في مجالات متنوعة، بما في ذلك التأشيرات والتصديقات السفارية، ومساعدة الشركات في التصدير والاستيراد من الصين والهند. نحن أيضًا متخصصون في تنظيم المؤتمرات وحجز الفنادق، ونقدم خدمات تنسيق مع المنظمات والجمعيات الخيرية.',
            description2: 'فريق عملنا مؤهل وذو خبرة، وهدفنا الأساسي هو تحقيق أهدافك بكل سهولة وسلاسة. نحن نعتمد على مبادئ الكفاءة والمصداقية في كل ما نقدمه، ونسعى جاهدين لتلبية توقعاتك وتقديم خدمات عالية الجودة.إذا كنت تبحث عن حلول شاملة واحترافية في مجالات التأشيرات والتصدير والتوريد، فلا تتردد في الاعتماد على شركتنا. نحن هنا لمساعدتك في تحقيق أهدافك بكل ثقة وفعالية.',
            facts: {
              F1:'الريادة في مجالات الخدمات',
              D1:'هدفنا هو تحقيق التميز في مجالات خدماتنا، وذلك من خلال تلبية توقعات عملائنا وتقديم حلاً شاملاً وفعّالًا',
              F2:'تقديم خدمات متميزة',
              D2:'نقدم خدمات استشارية ومساعدات عالية الجودة وفعّالة للعملاء، لضمان رضاهم وتحقيق التميز في السوق',
              F3:'التوسع الدولي',
              D3:'تهدف الشركة إلى توسيع نطاق خدماتها الدولية عبر توفير حلول مخصصة للشركات والأفراد الذين يعملون في سياق عالمي',
            },
            faq: {
              title: 'أسئلة شائعة',
              Q1:'كيف يمكنني التواصل معكم للحصول على خدماتكم؟',
              A1:'يمكنكم التواصل معنا عبر صفحة "اتصل بنا" أو عبر البريد الإلكتروني info@tunis-consulting.com أو الاتصال بنا على 888 846 28 216+ ',
              Q2:'ما هي خدماتكم الرئيسية؟',
              A2:'نقدم خدمات تأشيرات السفر والعمل، والتصديق السفاري، والاستيراد والتصدير من وإلى الصين والهند، وتنظيم المؤتمرات والحجوزات الفندقية، والدعم للمنظمات الخيرية.',
              Q3:'هل يمكنكم توفير حلول مخصصة للشركات؟',
              A3:'نعم، نحن نقدم حلولًا مخصصة للشركات بما في ذلك تنظيم فعاليات خاصة وخدمات دعم الأعمال',
              Q4:'هل يمكنكم مساعدتي في الحصول على تأشيرة للدراسة في الخارج؟',
              A4:'نعم، نحن نقدم خدمات تأشيرات للدراسة في الخارج بكفاءة وموثوقية، بما في ذلك المساعدة في تقديم الطلبات والاستعداد للمقابلات القنصلية.',
              Q5:'هل تقدمون خدمات التصديق للشهادات والوثائق؟',
              A5:'نعم، نحن نقدم خدمات التصديق السفاري لضمان شرعية وقانونية الوثائق الرسمية',
            },
          },
          services: {
            seoTitle: 'خدماتنا في تونس للإستشارات والمساعدة',
            seodescription: 'استكشف الخدمات الشاملة التي تقدمها شركة تونس للإستشارات والمساعدة.  رائدين في تقديم خدمات عالية الجودة والموثوقة.تقدم شركتنا خدمات استشارية ومساعدة في مجالات متنوعة، بما في ذلك التأشيرات والتصديقات السفارية، ومساعدة الشركات في التصدير والاستيراد من الصين والهند. نحن أيضًا متخصصون في تنظيم المؤتمرات وحجز الفنادق، ونقدم خدمات تنسيق مع المنظمات والجمعيات الخيرية.',
            title: 'خدماتنا',
            intro: 'تقدم شركة تونس للاستشارات والمساعدة مجموعة شاملة من الخدمات المصممة لتلبية احتياجات عملائها بكفاءة واحترافية. نحن هنا لتقديم الدعم والحلول لعملائنا في مجموعة متنوعة من الجوانب. يُمكنك الاعتماد على خبرتنا واهتمامنا بتحقيق أهدافك بنجاح.',
            visa: 'نقوم بتيسير عملية التقديم والحصول على أنواع مختلفة من التأشيرات، مضمونين تأمين عملية سهلة وخالية من المتاعب لعملائنا. سواء كنت تخطط للسفر، أو العمل، أو الدراسة، أو البحث عن علاج في الخارج، سيقوم فريق الخبراء لدينا بإرشادك خلال عملية تقديم التأشيرة بأكملها، وتوفير الدعم الشامل لتلبية متطلباتك الخاصة',
            import: 'نقوم بتسهيل عملية استيراد وتصدير المنتجات بين الصين والهند ووجهات دولية أخرى. يتعامل فريقنا ذو الخبرة مع اللوجستيات وإجراءات التخليص الجمركي والشحن، مما يسهل العملية ويضمن سلسلة توريد فعالة من حيث التكلفة لشركتك',
            embassy: 'يساعد فريقنا العملاء في الحصول على التصديقات السفارية الضرورية للوثائق مثل العقود القانونية والسجلات الأكاديمية وغيرها من الوثائق الرسمية. ندير عملية التصديق بأكملها، مضمونين تلبية وثائقك لمتطلبات السفارات المعنية، مما يوفر لك الوقت والجهد',
            hotel: 'نتعامل مع حجوزات الفنادق بعناية فائقة لتلبية احتياجات العملاء. بغض النظر عن غرض إقامتك، سواء كانت للعمل أو للترفيه، نحن نقوم بالبحث عن الفنادق المناسبة وتقديم خيارات متعددة تناسب ميزانيتك وتفضيلاتك. نضمن توفير أفضل العروض والخدمات، مما يجعل إقامتك مميزة ومريحة. اعتمد علينا لضمان حجز فندق مثالي يلبي توقعاتك بكل تفاصيله',
            conference: 'نحن نقدم خبرتنا الواسعة في تنظيم وإدارة المؤتمرات والاجتماعات، بدءًا من التخطيط والتصميم وانتهاءً بالتنفيذ وإدارة الأحداث. سوف نعمل جنبًا إلى جنب معك لتلبية جميع احتياجات فعاليتك وضمان أنها تنجح بشكل استثنائي. إذا كنت تبحث عن تنظيم مؤتمر أو اجتماع أو فعالية خاصة تتميز بالتميز، فنحن هنا لنجعل ذلك حقيقة',
            deal: 'نعمل بشكل وثيق مع المنظمات الخيرية والجمعيات، ونقدم المساعدة في مجموعة متنوعة من الجوانب لمساعدتهم في تحقيق أهدافهم. التزامنا بالمسؤولية الاجتماعية يمتد إلى هذه التعاونات، حيث نساهم بخبرتنا ومواردنا لتحقيق تأثير إيجابي على المجتمع',
          },
          contact: {
            seoTitle: 'تواصل مع تونس للإستشارات والمساعدة',
            seodescription: 'تواصل مع شركة تونس للإستشارات والمساعدة. اتصل بفريقنا للحصول على مساعدة في التأشيرات، استفسارات الهجرة، أو أي أسئلة أخرى. نحن هنا لتقديم الدعم بسرعة وبشكل مفيد.',
            title: 'اتصل بنا',
            intro: 'نحن دائمًا في انتظار التواصل معك. إذا كان لديك أي استفسارات، تعليقات، أو احتياجات خاصة، فلا تتردد في الاتصال بنا. يمكنك التواصل مع فريقنا المخصص والمهني عبر البريد الإلكتروني أو الهاتف أو ملء النموذج أدناه. سنكون سعداء بمساعدتك والإجابة على استفساراتك في أقرب وقت ممكن. شكرًا لاهتمامك بشركتنا، ونتطلع دائمًا إلى خدمتك بكفاءة وفعالية.',
            title2: 'أي استفسار؟',
            name: 'الإسم',
            phone: 'رقم الهاتف',
            message: 'الرسالة',
            send: 'ارسال',
            title3: 'معلومات التواصل',
            tunisia: 'اتصل بنا في تونس',
            india: 'شركائنا في الهند',
            china: 'شركائنا في الصين',
          },
          registration: {
            seoTitle: 'تونس للإستشارات والمساعدة: خدمات التأشيرات',
            seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال التأشيرات. نقدم مساعدة شاملة لمختلف أنواع التأشيرات. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة ناجح.',
            title: 'خدمات التأشيرات',
            intro: 'سواء كنت تخطط لعطلة ممتعة، رحلة عمل، أو متابعة أحلامك الأكاديمية في الخارج، خدمات التأشيرات لدينا تغطي العديد من البلدان، حتى تتمكن من الانطلاق في مغامرتك بثقة. استكشف مجموعة متنوعة من خيارات التأشيرات لدينا ودعنا نساعدك في تحقيق أمانيك في السفر.',
            china: 'الصين',
            india: 'الهند',
            southafrica: 'جنوب افريقيا',
            germany: 'ألمانيا',
            france: 'فرنسا',
            spain: 'اسبانيا',
            uk: 'بريطانيا',
            belgium: 'بلجيكا',
            businessvisa: 'تأشيرة عمل',
            studentvisa: 'تأشيرة دراسة',
            visatypes: 'أنواع التأشيرة',
            form: 'سواء كنت تقدم طلبًا شخصيًا من خلالنا أو ترغب في التعاقد مع شركة تونس للاستشارات والمساعدة كمزود لتأشيرات منظمتك، فنحن متحمسون لمساعدتك. يُرجى ملء النموذج بالمستندات اللازمة حتى نتمكن من متابعة طلبك.',
            button: 'تقدم بطلب الحصول على التأشيرة الآن',
            note: 'كل المستندات ترسل سكانر PDF بالماسح الضوئي',
          },
          china : {
            seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة الى الصين',
            seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة الى الصين. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب التأشيرة. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة الى الصين.',  
            subtitle: 'تسهيل رحلتك الصينية: حلول سلسة للتأشيرات للعمل و الدراسة',
            h6: 'تأشيرة إلى الصين',
            description1: 'هل تفكر في السفر إلى الصين للعمل أو الدراسة؟ شركة تونس للاستشارات و المساعدة هي شريكك الموثوق لتبسيط عملية تقديم التأشيرة, سواء كنت تسعى لفرص وظيفية أم تتطلع إلى مواصلة تعليمك العالي في الصين, مهمتنا هي تقديم حلا مخصصا للتأشيرة يبسط مسار نجاحك.',
            description2: 'للبدء, اختر أحد الإختيارات أدناه للوصول إلى جميع المعلومات اللازمة لرحلتك الخاصة:',
            businesstitle: 'تأشيرة الأعمال',
            studenttitle: 'تأشيرة الدراسة',
            othertitle: 'تأشيرات عائلية',
            },
            chinabusinessvisa: {
              seoTitle: 'شركة تونس للإستشارات و المساعدة فيزا أعمال الصين: إجراءات وقائمة الوثائق لتقديم طلب تأشيرة للصين | سفارة الصين في تونس',
              seodescription: 'استكشف خدمات تونس للاستشارات والمساعدة للحصول على تأشيرة الأعمال إلى الصين. يقدم فريقنا الخبير دعمًا شاملاً، موجِّهًا إياك خلال عملية تقديم طلب التأشيرة. تعرَّف على الشروط والوثائق والخطوات اللازمة لتقديم طلب ناجح لتأشيرة الأعمال في الصين.',
              title: 'تأشيرة الأعمال في الصين',
              sub1: 'رحلتك إلى الصين تبدأ معنا !',
              sub2: 'فريق خبرائنا هنا ليقودك خلال عملية تقديم التأشيرة بأكملها، وتوفير دعم شامل لتلبية متطلباتك الخاصة.',
              sub3: 'كل ما عليك هو تقديم الوثائق المطلوبة من خلال رابط النموذج السهل الاستخدام الخاص بنا، ودع فريق الخبراء لدينا يتولى كل تفصيل في طلب تأشيرتك. ',
              sub4: 'تعتبر تأشيرة الأعمال نوعًا من تأشيرات الصين المصممة للأفراد الذين يسافرون إلى الصين لأغراض متعلقة بالأعمال. تسمح للأجانب بزيارة الصين لحضور الاجتماعات والمؤتمرات والمفاوضات وأنشطة تجارية أخرى.',
              sub5: 'تختلف نوع التأشيرة حسب الغرض, حيث يوجد التأشيرة M و التأشيرة Z كتأشيرات تجارية, يمكنكم التطلع على المستندات اللازمة لكل تأشيرة من الخانة أدناه.',
              visaM: {
                title: 'تأشيرة M',
                def1: 'تأشيرة M هي تأشيرة خاصة تُصدر لزيارة الصين لأغراض التجارة والأنشطة التجارية و يتم أيضًا إصدارها لمشاركي الأنشطة.',
                def2: 'تُعرف أيضًا باسم تأشيرات الأعمال، ويمكن لحامليها دخول الصين بها لزيارة الشركاء التجاريين، وحضور المعارض التجارية، أو للاجتماع مع الموردين.',
                requirements: 'متطلبات التأشيرة M :',
                initial: 'دخول أول :',
                preentry: 'دخول مسبق :',
                swift: 'دخول مسبق الصين بطريقة سويفت للجنسية التونسية فقط :',
                folder: 'تجهيز ملف (الحريف لديه دعوة حكومية) :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                govInv1: 'دعوة حكومية',
                govInv2: 'مع صورة التأشيرات السابقة ان وجدت',
                oldvisa1: 'صورة التأشيرات السابقة',
                oldvisa2: 'صورة التأشيرات السابقة مع اختتام الدخول و الخروج و صورة من جواز السفر القديم اذا تم تجديده',
                norInv1: 'دعوة عادية',
                norInv2: '',
                olvisa1: 'صورة آخر تأشيرة بعد سنة 2016',
                olvisa2: 'مع صورة من جواز السفر القديم إذا كان يحتوي على التأشيرة و تم تجديده',
                rne: 'صورة من السجل التجاري ان وجد',
                invoice: 'فاتورة من الشركة الصينية',
                transfer1: 'تحويل بنكي سويفت',
                transfer2: 'تحويل بنكي لأكثر من 10000$ مطابقة و بنفس اسم الشركة في الفاتورة (سويفت)',
              },
              visaZ: {
                title: 'تأشيرة Z',
                def: 'يتم إصدارها لأولئك الذين حصلوا على تصريح العمل و ينوون العمل في الصين.',
                requirements: 'متطلبات التأشيرة Z :',
                initial: 'تجهيز ملف لأول مرة :',
                renewal: 'تجديد تأشيرة Z :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                govInv1: 'دعوة حكومية',
                oldvisa1: 'صورة التأشيرات السابقة',
                oldvisa2: 'صورة التأشيرات السابقة مع اختتام الدخول و الخروج و صورة من جواز السفر القديم اذا تم تجديده',
                norInv1: 'دعوة عادية من الشركة',
                permit: 'صورة من بطاقة العمل صالحة (سارية المفعول)',
                license: 'رخصة الشركة',
                residence: 'صورة من أخر تأشيرة اقامة',
                house: 'عقد الايجار في الصين',
              },
            },
            chinastudentvisa: {
              seoTitle: 'شركة تونس للإستشارات و المساعدة فيزا دراسة الصين: إجراءات وقائمة الوثائق لتقديم طلب تأشيرة للصين | سفارة الصين في تونس',
              seodescription: 'استكشف عملية الحصول على تأشيرة دراسية إلى الصين بسهولة مع شركة تونس للإستشارات والمساعدة. يقدم فريقنا الخبير إرشادًا مفصلًا حول تقديم الوثائق، بما في ذلك متطلبات التأشيرة، الوثائق الضرورية، والخطوات لضمان تقديم ناجح للدراسة في الصين.',
              title: 'تأشيرة الدراسة في الصين',
              sub1: 'تصدر تأشيرة الدراسة إلى الذين تم تم قبولهم في كليات صينية.',
              sub2: 'هناك نوعان من تأشيرات الدراسة X1 و X2. لذلك يرجى الرجوع إلى القسم أدناه للحصول على نظرة مفصلة على الوثائق المطلوبة لكل نوع من التأشيرات.',
              sub3: 'كل ما عليك هو تقديم الوثائق المطلوبة من خلال رابط النموذج السهل الاستخدام الخاص بنا، ودع فريق الخبراء لدينا يتولى كل تفصيل في طلب تأشيرتك. ',
              visaX1: {
                title: 'تأشيرة X1',
                def: 'تصدر التأشيرة X1 للطلاب الذين لذين يتوجهون إلى الصين للدراسة لمدة أكثر من 6 أشهر، ويمكن أن تُصدر لعدة مرات مع صلاحية تصل إلى 5 سنوات.',
                requirements: 'متطلبات التأشيرة X1 :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                letter1: 'إشعار القبول من الجامعة الصينية',
                letter2:'يجب أن يكون خطاب القبول الآصلي.',
                diploma: 'صورة من آخر شهادة متحصل عليها',
                bank1: 'تصريح مالي',
                bank2: 'كشف حساب أو تصريح عملة أكثر من 2000$.',
              },
              visaX2: {
                title: 'تأشيرة X2',
                def: 'تصدر التأشيرة X1 للطلاب الذين لذين يتوجهون إلى الصين للدراسة لفترة تقل عن 6 أشهر، و تُصدر لمرة واحدة فقط. .',
                requirements: 'متطلبات التأشيرة X2 :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                letter1: 'إشعار القبول من الجامعة الصينية',
                letter2:'يجب أن يكون خطاب القبول الآصلي.',
                diploma: 'صورة من آخر شهادة متحصل عليها',
                bank1: 'تصريح مالي',
                bank2: 'كشف حساب أو تصريح عملة أكثر من 2000$.',
              }
            },
            chinapersonalvisa: {
              seoTitle: 'شركة تونس للإستشارات و المساعدة فيزا عائلية الصين: إجراءات وقائمة الوثائق لتقديم طلب تأشيرة للصين | سفارة الصين في تونس',
              seodescription: 'استكشف عملية الحصول على تأشيرة زيارة عائلية إلى الصين بسهولة مع شركة تونس للإستشارات والمساعدة. يقدم فريقنا الخبير إرشادًا مفصلًا حول تقديم الوثائق، بما في ذلك الجوازات، الصور الشخصية، شهادات الولادة، الدعوات، وتصاريح الإقامة. تنقل متطلبات التأشيرة الخاصة بالعائلة بدقة، مما يضمن عملية تقديم سلسة. ثق بنا لتحقيق زيارة عائلية ناجحة إلى الصين.',
              title: 'تأشيرات عائلية للصين',
              sub1: 'تقدم الصين مجموعة من التأشيرات الخاصة التي تلبي احتياجات الأفراد ذوي الأغراض العائلية المحددة أو الزيارات الشخصية.',
              sub2: 'تتمثل هذه التأشيرات، وهي S1 وS2 وQ، في تلبية الاحتياجات المتنوعة لأولئك الذين يسعون للمشاركة في لقاءات عائلية أو القيام بزيارات قصيرة الأمد لذلك يرجى الرجوع إلى القسم أدناه للحصول على نظرة مفصلة على الوثائق المطلوبة لكل نوع من التأشيرات.',
              sub3: 'كل ما عليك هو تقديم الوثائق المطلوبة من خلال رابط النموذج السهل الاستخدام الخاص بنا، ودع فريق الخبراء لدينا يتولى كل تفصيل في طلب تأشيرتك. ',
              visaS: {
                title: 'تأشيرة S1',
                title2: 'تأشيرة S2',
                def1: 'تُمنح تأشيرة S1 لأولئك الذين ينوون الانتقال إلى الصين للعيش مع أفراد أسرهم من الدرجة الأولى الذين هم إما مواطنون صينيون أو أجانب يحملون تصريح إقامة صيني دائم.',
                def2: 'التأشيرة S2 فتصدر لأولئك الذين يخططون لزيارة أفراد عائلتهم في الصين لفترة قصيرة.',
                def3: 'تصدر هذه التأشيرة للأفراد الذين تحصل أحد أفراد عائلاتهم من الدرجة الأولى على تأشيرة M.',
                requirements: 'متطلبات التأشيرة S1 :',
                requirements2: 'متطلبات التأشيرة S2 :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                invitation: 'رسالة دعوة من الداعي',
                house: 'عقد الايجار في الصين',
                pass3: 'صورة من جواز سفر صاحب الدعوة',
                residence: 'صورة من إقامة صاحب الدعوة',
                acte1: 'شهادة ميلاد',
                acte2: 'شهادة ميلاد الداعي',
                passM: 'صورة من جواز سفر صاحب التأشيرة M',
                visaM: 'صورة التأشيرة M',
              },
              visaQ: {
                title: 'تأشيرة Q',
                def: 'تُصدر تأشيرة Q للأجانب الذين يعتبرون أفرادًا من عائلة مواطنين صينيين أو المقيمين الدائمين.',
                requirements: 'متطلبات التأشيرة Q :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                invitation: 'رسالة دعوة من الداعي',
                pass4: 'صورة من بطاقة الهوية الصينية أو جواز السفر الأجنبي وتصريح الإقامة الدائمة للداعي',
                kinship1: 'إثبات للعلاقة العائلية',
                kinship2: 'صورة من الشهادة (شهادة الزواج، شهادة الميلاد، شهادة القرابة الصادرة عن مكتب الأمن العام أو شهادة القرابة الموثقة) توضح علاقة أفراد الأسرة بين مقدم الطلب والفرد الداعي.',
              },
            },
            india: {
              seoTitle: 'تونس للإستشارات والمساعدة: تأشيرة الى الهند',
              seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة الى الهند. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب التأشيرة. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة الى الهند.',
              subtitle:'إطلاق النجاح، الشفاء، والمعرفة: رحلتك السهلة وخالية من التعقيد للحصول على تأشيرة للهند',
              h6: 'تأشيرة إلى الهند',
              description1:'اكتشف النجاح، الشفاء، والمعرفة بسهولة مع شركة تونس للإستشارات والمساعدة.',
              description2: 'عمليتنا الميسرة تضمن الموافقات السريعة، مصممة وفقًا لاحتياجاتك الفريدة. رحلتك إلى الهند تبدأ هنا - بدون عناء وواعدة:',
              businesstitle: 'تأشيرة الأعمال',
              studenttitle: 'تأشيرة الدراسة',
              medicaltitle: 'تأشيرة علاج',
              businessvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة الأعمال إلى الهند',
                seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة الأعمال إلى الهند. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب تأشيرة الأعمال. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة الأعمال إلى الهند.',
                title: 'تأشيرة الأعمال في الهند',
                sub1: 'تأشيرة الأعمال الهندية تخدم الأفراد الراغبين في الانخراط في أنشطة تجارية داخل الهند. سواء كان الأمر يتعلق بتبادل السلع والخدمات، أو المشاركة الفعّالة في اجتماعات الأعمال، أو إقامة مشاريع صناعية، أو المشاركة في المعارض التجارية، توفر هذه التأشيرة بوابة لفرص تجارية متنوعة.',
                sub2: ' في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير الوصول السلس إلى تأشيرة الأعمال الهندية. خدماتنا المتخصصة تضمن عملية طلب سهلة، مما يتيح لك التركيز على أهداف عملك بينما نتولى تفاصيل حصولك على التأشيرة.',
                requirements: 'متطلبات تأشيرة الأعمال في الهند :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 5x5',
                bank:'صورة أخر كشف حساب بنكي ان وجد',
                invitation1: 'رسالة الدعوة من الهند',
                invitation2: 'ان وجدت مع امكانية توفيرها من قبل مكتبنا',
                registration: 'سجل تجاري (رخصة الشركة أو RNE) ان وجد',
                oldvisa: 'صورة التأشيرات السابقة ان وجدت',
              },
              studentvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة الطلاب إلى الهند',
                seodescription: 'اكتشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة الدراسة إلى الهند. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب تأشيرة الدراسة. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة الدراسة إلى الهند.',
                title: 'تأشيرة الدراسة في الهند',
                sub1: 'ابدأ رحلتك الأكاديمية في الهند مع تأشيرة الدراسة، التي صممت خصيصًا للأفراد الطامحين في متابعة تعليمهم في هذه الدولة الغنية ثقافيًا. سواء كنت تهدف إلى التسجيل في جامعة، أو حضور دورات متخصصة، أو المشاركة في مشاريع البحث، تفتح تأشيرة الدراسة أبوابًا لعالم من فرص التعلم.',
                sub2: 'في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير دعم شامل لتأشيرة الدراسة الهندية. خدماتنا المخصصة تضمن عملية طلب خالية من المتاعب، مما يتيح لك التركيز على أهداف تعليمك بينما نتولى التفاصيل الصعبة للحصول على التأشيرة',
                requirements: 'متطلبات تأشيرة الدراسة في الهند', 
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 5x5',
                diploma: 'صورة من آخر شهادة متحصل عليها',
                invitation: 'دعوة من الجامعة الهندية',
                receipt: 'وصل خلاص الجامعة الهندية',
                parpass: 'صورة جواز سفر ولي الأمر',
                parbank: 'صورة كشف حساب ولي الأمر',
                evidence: 'شهادة كفالة المصاريف لتغطية نفقات معيشتك من ولي الأمر (تحرر عند محرر العقود)',
              },
              medicalvisa : {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العلاج الطبي في الهند',
                seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة علاج طبي إلى الهند. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب تأشيرة العلاج الطبي. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة العلاج الطبي إلى الهند.',
                title: 'تأشيرة العلاج في الهند',
                sub1: 'ابدأ رحلة الشفاء والعافية مع تأشيرة العلاج الطبي في الهند، المصممة للأفراد الذين يسعون لتلقي العلاج الطبي في هذا البلد الغني بالتنوع والحيوية. سواء كان الأمر يتعلق بإجراءات طبية متخصصة، أو استشارات طبية، أو تداخلات علاجية، تسهل تأشيرة العلاج الطبي الوصول إلى مرافق الرعاية الصحية المشهورة في الهند.',
                sub2: 'في شركة تونس للإستشارات و المساعدة، نتخصص في تسهيل عملية الحصول على تأشيرة العلاج الطبي الهندي، وتقديم الدعم الرفيق لأولئك الذين يحتاجون إلى رعاية طبية. خدماتنا المخصصة تضمن عملية طلب سلسة، مما يتيح لك أن تولي اهتمامك لصحتك بينما نتعامل مع تفاصيل الحصول على التأشيرة.',
                requirements: 'متطلبات تأشيرة العلاج في الهند',
                req1: ' 1) المتطلبات الأساسية',
                req2: '2) المتطلبات التي يجب اضافتها في حال وجود مرافق',
                req3: '3) تغطية مصاريف الإقامة و العلاج',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 5x5',
                hospitalInv: 'دعوة من المستشفى الهندية',
                cert1: 'تقرير طبي',
                cert2: 'تقرير طبي يوضح الحالة الطبية للمريض (بالإنقليزية)',
                comppass1: 'صورة صفحة البيانات من جواز سفر المرافق',
                comppass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                compphoto1: 'صورة شخصية للمرافق',
                compphoto2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 5x5',
                own: 'على حسابك الخاص :',
                bank:'صورة أخر كشف حساب بنكي',
                comp: 'أو على حساب مرافقك :',
                compba: 'صورة أخر كشف حساب بنكي للمرافق',
                compev: 'شهادة كفالة المصاريف لتغطية نفقات معيشتك من المرافق (تحرر عند محرر العقود)',
              },
            },
            southafrica: {
              seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرات جنوب أفريقيا',
              seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة لجنوب أفريقيا. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب تأشيرة لجنوب أفريقيا. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة لجنوب أفريقيا.',
              subtitle: 'اكتشف جنوب أفريقيا: بوابتك إلى رحلات استثنائية',
              h6: 'تأشيرة إلى جنوب افريقيا',
              description1: 'انطلق في رحلة مع شركة تونس للاستشارات و المساعدة لتجارب سلسة في الحصول على تأشيرات سياحية، دراسية، وتجارية في جنوب أفريقيا.',
              description2: 'اكتشف، نجح، وازدهر - اطلق العنان لإمكانيات جنوب أفريقيا معنا !',
              businesstitle: 'تأشيرة الأعمال',
              studenttitle: 'تأشيرة الدراسة',
              touristtitle: 'تأشيرة سياحية',
              familytitle: 'تأشيرة العائلة',
              studentvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة الدراسة في جنوب أفريقيا',
                seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة الدراسة في جنوب أفريقيا. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب تأشيرة الدراسة في جنوب أفريقيا. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة الدراسة في جنوب أفريقيا.',
                title: 'تأشيرة الدراسة في جنوب افريقيا',
                sub1: 'ابدأ رحلتك الأكاديمية في جنوب افريقيا مع تأشيرة الدراسة، التي صممت خصيصًا للأفراد الطامحين في متابعة تعليمهم في هذه الدولة الغنية ثقافيًا. سواء كنت تهدف إلى التسجيل في جامعة، أو حضور دورات متخصصة، أو المشاركة في مشاريع البحث، تفتح تأشيرة الدراسة أبوابًا لعالم من فرص التعلم.',
                sub2: 'في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير دعم شامل لتأشيرة الدراسة في جنوب افريقيا. خدماتنا المخصصة تضمن عملية طلب خالية من المتاعب، مما يتيح لك التركيز على أهداف تعليمك بينما نتولى التفاصيل الصعبة للحصول على التأشيرة',
                requirements: 'متطلبات تأشيرة الدراسة في جنوب افريقيا', 
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة شهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x6',
                payment: 'دفع رسوم طلب الحصول على فيزا جنوب افريقيا',
                report: 'تقرير طبي مع أشعة',
                cert: 'شهادة تطعيم (اذا طلبت)',
                crime1: 'الحالة الجنائية',
                ins1: 'تأمين صحي من جنوب افريقيا',
                ins2: 'مع امكانية توفيرها من مكتبنا',
                inv1: 'خطاب قبول',
                inv2: 'خطاب قبول بمدة الدورة أو شهادة الاعتماد من المؤسسة التعليمية مصدقة من الشرطة أو من معهد المعتمد مع امكانية توفيرها من مكتبنا',
                parpass: 'في حالة قاصر, صورة من جواز الكفيل',
                letter: 'في حالة قاصر, صورة من شهادة كفالة من الكفيل',
                bank1: 'دليل على توفر الوسائل المالية',
                bank2: 'دليل على توفر الوسائل المالية اللازمة أثناء الإقامة و دفع رسوم التعليم (كشف حساب بنكي أخر 3 أشهر)',
              },
              businessvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة أعمال جنوب أفريقيا',
                seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة أعمال لجنوب أفريقيا. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب تأشيرة أعمال لجنوب أفريقيا. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة أعمال لجنوب أفريقيا.',
                title: 'تأشيرة الأعمال في جنوب افريقيا',
                sub1: 'تأشيرة الأعمال في جنوب افريقيا تخدم الأفراد الراغبين في الانخراط في أنشطة تجارية. سواء كان الأمر يتعلق بتبادل السلع والخدمات، أو المشاركة الفعّالة في اجتماعات الأعمال، أو إقامة مشاريع صناعية، أو المشاركة في المعارض التجارية، توفر هذه التأشيرة بوابة لفرص تجارية متنوعة.',
                sub2: ' في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير الوصول السلس إلى تأشيرة العمل في جنوب افريقيا. خدماتنا المتخصصة تضمن عملية طلب سهلة، مما يتيح لك التركيز على أهداف عملك بينما نتولى تفاصيل حصولك على التأشيرة.',
                requirements: 'متطلبات تأشيرة الأعمال في جنوب افريقيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة شهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x6',
                letter1: 'رسالة من العمل (ان وجدت)',
                letter2: 'تتضمن هذه الرسالة سبب الزيارة',
                inv: 'دعوة من الشركة من جنوب افريقيا',
                bank1:'كشف حساب بنكي',
                bank2: 'صورة كشف حساب بنكي أخر 3 أشهر',
              },
              touristvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة السياحة لجنوب أفريقيا',
                seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة السياحة لجنوب أفريقيا. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب تأشيرة السياحة لجنوب أفريقيا. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة السياحة لجنوب أفريقيا.',
                title: 'تأشيرة السياحة في جنوب افريقيا',
                sub1: 'تقدم خدمتنا عملية سلسة للحصول على تأشيرة سياحية إلى جنوب أفريقيا. سواء كنت تخطط لاستكشاف المدن النابضة بالحياة، أو لتجربة المناظر الطبيعية الرائعة، أو للاستمتاع بالتراث الثقافي الغني لجنوب أفريقيا، نحن هنا لمساعدتك. يضمن فريقنا عملية التقديم بدون عناء، موجهينك خلال الخطوات الضرورية لتأمين تأشيرتك بسهولة.',
                sub2: 'اكتشف جمال جنوب أفريقيا بثقة، علمًا بأن خدمتنا مكرسة لجعل تجربتك السفر لا تُنسى وخالية من الإجهاد.',
                requirements: 'متطلبات تأشيرة السياحة جنوب افريقيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة شهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صورة شخصية',
                photo2: '1 صورة شخصية على خلفية بيضاء وبأبعاد 4x6',
                bank1:'كشف حساب بنكي',
                bank2: 'صورة أخر 3 أشهر كشف حساب بنكي ان وجد',
                letter1: 'رسالة من العمل',
                letter2: ' يذكر فيها تاريخ العطلة و الأجر المتقاضي',
              },
              familyvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العائلة لجنوب أفريقيا',
                seodescription: 'استكشف خدمات شركة تونس للإستشارات والمساعدة للحصول على تأشيرة العائلة لجنوب أفريقيا. يقدم فريقنا الخبير مساعدة شاملة، موجهًا إياك خلال عملية تقديم طلب تأشيرة العائلة لجنوب أفريقيا. تعرف على المتطلبات، الوثائق، والخطوات اللازمة لتقديم طلب ناجح للحصول على تأشيرة العائلة لجنوب أفريقيا.',
                title: 'تأشيرة العائلة في جنوب افريقيا',
                sub1: 'خدمتنا توفر عملية ميسّرة وفعّالة للحصول على تأشيرة زيارة العائلة إلى جنوب أفريقيا. سواء كنت تتجمع مع أقاربك، أو تستمتع بالاجتماعات العائلية، أو ببساطة تقضي وقتًا ممتعًا مع الأحباء، فإننا هنا لنسهل رحلتك. يضمن فريقنا عملية تقديم سلسة، مرافقة خطواتك الضرورية للحصول على التأشيرة بيسر.',
                sub2: 'توجه في مغامرة عائلية في جنوب أفريقيا بثقة، علمًا بأن خدمتنا ملتزمة بجعل زيارتك لا تُنسى وخالية من الضغوط.',
                requirements: 'متطلبات تأشيرة العائلة جنوب افريقيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة شهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                passport: 'صورة من جواز سفر الأب',
                photo1: '2 صورة شخصية',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x6',
                visa: 'صورة من التأشيرة',
                bcert: 'شهادة ميلاد',
                mcert: 'عقد زواج',
                fcert: 'شهادة الوضع العائلي',
                crime1: 'شهادة الحالة الجنائية من جنوب افريقيا',
                crime2: 'شهادة الحالة الجنائية من ليبيا',
                inv: 'دعوة عادية ',
                ins: 'تأمين من جنوب افريقيا',
                bank: '(كشف حساب الأب من جنوب افريقيا (أخر 3 أشهر',
                proof: 'تعهد من الأب بتحمل مصاريف السفر و الإقامة من جنوب افريقيا',
              },
            },
            germany: {
              seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرات ألمانيا',
              seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات ألمانيا. يقدم فريقنا المتخصص مساعدة شاملة لمختلف أنواع التأشيرات الألمانية. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة ألمانيا ناجح.',
              subtitle: 'فتح أفق الرحلات بسهولة: دليلك الشامل لحلول التأشيرات السهلة إلى ألمانيا',
              h6: 'تأشيرة إلى ألمانيا',
              description1: 'في شركة تونس للاستشارات و المساعدة، نختص في تسهيل عملية تقديم الفيزا، خاصة لتأشيرة العلاج وتأشيرة الأعمال المتجهة إلى ألمانيا.',
              description2: 'خدماتنا الميسّرة تضمن لك تجربة سهلة وفعّالة، مما يتيح لك التركيز على أهدافك الطبية أو التجارية بينما نهتم بتسهيل إجراءات الحصول على التأشيرة. اختر البساطة معنا.',
              businesstitle: 'تأشيرة الأعمال',
              medicaltitle: 'تأشيرة علاج',
              touristtitle: 'تأشيرة سياحية',
              businessvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العمل في ألمانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات العمل في ألمانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة عمل إلى ألمانيا. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة العمل في ألمانيا بنجاح.',
                title: 'تأشيرة الأعمال في ألمانيا',
                sub1: 'تأشيرة العمل في المانيا هي تصريح تمنحه الحكومة الألمانية للأشخاص الذين يرغبون في العمل في ألمانيا بصفة مؤقتة أو دائمة.',
                sub2: ' في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير الوصول السلس إلى تأشيرة العمل في ألمانيا. خدماتنا المتخصصة تضمن عملية طلب سهلة، مما يتيح لك التركيز على أهداف عملك بينما نتولى تفاصيل حصولك على التأشيرة.',
                requirements: 'متطلبات تأشيرة الأعمال في ألمانيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من ألمانيا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                invitation: 'دعوة عمل من الشركة الألمانية',
                fam: 'شهادة وضع عائلي',
                bank1:'كشف حساب بنكي',
                bank2: 'صورة كشف حساب بنكي أخر 3 أشهر',
                attravail: 'إفادة عمل أو رخصة شركة',
                invoices: 'فواتير من الشركة الألمانية ان وجدت',
              },
              medicalvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العلاج الطبي في ألمانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات العلاج الطبي في ألمانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة علاج طبي إلى ألمانيا. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة العلاج الطبي في ألمانيا بنجاح.',
                title: 'تأشيرة العلاج في ألمانيا',
                sub1: 'ابدأ رحلة الشفاء والعافية مع تأشيرة العلاج الطبي في ألمانيا، المصممة للأفراد الذين يسعون لتلقي العلاج الطبي في هذا البلد الغني بالتنوع والحيوية. سواء كان الأمر يتعلق بإجراءات طبية متخصصة، أو استشارات طبية، أو تداخلات علاجية، تسهل تأشيرة العلاج الطبي الوصول إلى مرافق الرعاية الصحية المشهورة في ألمانيا.',
                sub2: 'في شركة تونس للإستشارات و المساعدة، نتخصص في تسهيل عملية الحصول على تأشيرة العلاج الطبي في ألمانيا، وتقديم الدعم الرفيق لأولئك الذين يحتاجون إلى رعاية طبية. خدماتنا المخصصة تضمن عملية طلب سلسة، مما يتيح لك أن تولي اهتمامك لصحتك بينما نتعامل مع تفاصيل الحصول على التأشيرة.',
                requirements: 'متطلبات تأشيرة العلاج في ألمانيا',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من ألمانيا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                cert1: 'تقرير طبي',
                cert2: 'تقرير طبي يوضح الحالة الطبية للمريض (بالإنقليزية)',
                inv1: 'دعوة من المستشفى',
                inv2: 'تحتوي على تفاصيل الموعد مع الطبيب و قيمة العلاج و اسم المريض و مرافقه و رقم الجوازات',
                transfer1: 'تحويل البنكي SWIFT',
                transfer2: 'التحويل البنكي لقيمة العلاج المذكورة في دعوة المستشفى',
                minor: 'اذا كان المريض صغير :',
                parpass: 'صورة من جواز ولي الأمر',
                cert: 'شهادة ميلاد',
                fam: 'شهادة وضع عائلي',
                evidence: 'شهادة كفالة المصاريف من الأب (تحرر عند محرر العقود)',
              },
              touristvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة السياحة في ألمانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات السياحة في ألمانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة سياحية إلى ألمانيا. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة السياحة في ألمانيا بنجاح.',
                title: 'تأشيرة سياحية لألمانيا',
                sub1: 'تقدم خدمتنا عملية سلسة وفعالة للحصول على تأشيرة سياحية إلى ألمانيا. سواء كنت تخطط لاستكشاف المدن الساحرة، أو للانغماس في التاريخ الغني، أو لتذوق التجارب الثقافية المتنوعة في ألمانيا، نحن هنا لمساعدتك. يضمن فريقنا عملية تقديم خالية من المتاعب، مرافقينك خلال الخطوات الضرورية لتأمين تأشيرتك بسهولة.',
                sub2: 'اكتشف جمال ألمانيا بثقة، علمًا بأن خدمتنا مخصصة لجعل تجربتك السفر لا تنسى وخالية من التوتر.',
                presence: 'امكانية تقديم بدون حضور في حالة وجود تأشيرة سابقة لم تتجاوز 5 سنوات',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من ألمانيا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                fam: 'شهادة وضع عائلي',
                attravail: 'إفادة عمل أو رخصة شركة',
                bank: 'كشف حساب بنكي أخر 3 أشهر',
              },
            },
            france: {
              seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة فرنسا',
              seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات فرنسا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة إلى فرنسا. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة فرنسا بنجاح.',
              subtitle: 'فتح أفق الرحلات بسهولة: دليلك الشامل لحلول التأشيرات السهلة إلى فرنسا',
              h6: 'تأشيرة إلى فرنسا',
              description1: 'في شركة تونس للاستشارات و المساعدة، نختص في تسهيل عملية تقديم الفيزا، خاصة لتأشيرة العلاج وتأشيرة الأعمال المتجهة إلى فرنسا.',
              description2: 'خدماتنا الميسّرة تضمن لك تجربة سهلة وفعّالة، مما يتيح لك التركيز على أهدافك الطبية أو التجارية بينما نهتم بتسهيل إجراءات الحصول على التأشيرة. اختر البساطة معنا.',
              businesstitle: 'تأشيرة الأعمال',
              medicaltitle: 'تأشيرة علاج',
              touristtitle: 'تأشيرة سياحية',
              businessvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العمل في فرنسا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات العمل في فرنسا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة العمل إلى فرنسا. تعرف على متطلبات تأشيرة العمل، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة العمل في فرنسا بنجاح.',
                title: 'تأشيرة الأعمال في فرنسا',
                sub1: 'تأشيرة العمل في فرنسا هي تصريح تمنحه الحكومة الفرنسية للأشخاص الذين يرغبون في العمل في فرنسا بصفة مؤقتة أو دائمة.',
                sub2: ' في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير الوصول السلس إلى تأشيرة العمل في فرنسا. خدماتنا المتخصصة تضمن عملية طلب سهلة، مما يتيح لك التركيز على أهداف عملك بينما نتولى تفاصيل حصولك على التأشيرة.',
                requirements: 'متطلبات تأشيرة الأعمال في فرنسا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من فرنسا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                invitation: 'دعوة عمل من الشركة الفرنسية',
                fam: 'شهادة وضع عائلي',
                bank1:'كشف حساب بنكي',
                bank2: 'صورة كشف حساب بنكي أخر 3 أشهر',
                attravail: 'إفادة عمل أو رخصة شركة',
                invoices: 'فواتير من الشركة الفرنسية ان وجدت',
              },
              medicalvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العلاج الطبي في فرنسا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات العلاج الطبي في فرنسا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة العلاج الطبي إلى فرنسا. تعرف على متطلبات تأشيرة العلاج الطبي، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة العلاج الطبي في فرنسا بنجاح.',
                title: 'تأشيرة العلاج في فرنسا',
                sub1: 'ابدأ رحلة الشفاء والعافية مع تأشيرة العلاج الطبي في فرنسا، المصممة للأفراد الذين يسعون لتلقي العلاج الطبي في هذا البلد الغني بالتنوع والحيوية. سواء كان الأمر يتعلق بإجراءات طبية متخصصة، أو استشارات طبية، أو تداخلات علاجية، تسهل تأشيرة العلاج الطبي الوصول إلى مرافق الرعاية الصحية المشهورة في فرنسا.',
                sub2: 'في شركة تونس للإستشارات و المساعدة، نتخصص في تسهيل عملية الحصول على تأشيرة العلاج الطبي في فرنسا، وتقديم الدعم الرفيق لأولئك الذين يحتاجون إلى رعاية طبية. خدماتنا المخصصة تضمن عملية طلب سلسة، مما يتيح لك أن تولي اهتمامك لصحتك بينما نتعامل مع تفاصيل الحصول على التأشيرة.',
                requirements: 'متطلبات تأشيرة العلاج في فرنسا',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من فرنسا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                cert1: 'تقرير طبي',
                cert2: 'تقرير طبي يوضح الحالة الطبية للمريض',
                inv1: 'دعوة من المستشفى',
                inv2: 'تحتوي على تفاصيل الموعد مع الطبيب و قيمة العلاج و اسم المريض و مرافقه و رقم الجوازات',
                transfer1: 'تحويل البنكي SWIFT',
                transfer2: 'التحويل البنكي لقيمة العلاج المذكورة في دعوة المستشفى',
                minor: 'اذا كان المريض صغير :',
                parpass: 'صورة من جواز ولي الأمر',
                cert: 'شهادة ميلاد',
                fam: 'شهادة وضع عائلي',
                evidence: 'شهادة كفالة المصاريف من الأب (تحرر عند محرر العقود)',
              },
              touristvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة السياحة في فرنسا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات السياحة في فرنسا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة السياحة إلى فرنسا. تعرف على متطلبات تأشيرة السياحة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة السياحة في فرنسا بنجاح.',
                title: 'تأشيرة سياحية لفرنسا',
                sub1: 'تقدم خدمتنا عملية سلسة وفعالة للحصول على تأشيرة سياحية إلى فرنسا. سواء كنت تخطط لاستكشاف المدن الساحرة، أو للانغماس في التاريخ الغني، أو لتذوق التجارب الثقافية المتنوعة في فرنسا، نحن هنا لمساعدتك. يضمن فريقنا عملية تقديم خالية من المتاعب، مرافقينك خلال الخطوات الضرورية لتأمين تأشيرتك بسهولة.',
                sub2: 'اكتشف جمال فرنسا بثقة، علمًا بأن خدمتنا مخصصة لجعل تجربتك السفر لا تنسى وخالية من التوتر.',
                requirements: 'متطلبات تأشيرة سياحية في فرنسا :',
                presence: 'امكانية تقديم بدون حضور في حالة وجود تأشيرة سابقة لم تتجاوز 5 سنوات',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من فرنسا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                fam: 'شهادة وضع عائلي',
                attravail: 'إفادة عمل أو رخصة شركة',
                bank: 'كشف حساب بنكي أخر 3 أشهر',
              },
            },
            spain: {
              seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة إسبانيا',
              seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات إسبانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة إسبانيا. تعرف على متطلبات تأشيرة إسبانيا، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة إسبانيا بنجاح.',
              subtitle: 'فتح أفق الرحلات بسهولة: دليلك الشامل لحلول التأشيرات السهلة إلى اسبانيا',
              h6: 'تأشيرة إلى اسبانيا',
              description1: 'في شركة تونس للاستشارات و المساعدة، نختص في تسهيل عملية تقديم الفيزا، خاصة لتأشيرة الدراسة وتأشيرة الأعمال المتجهة إلى اسبانيا',
              description2: 'خدماتنا الميسّرة تضمن لك تجربة سهلة وفعّالة، مما يتيح لك التركيز على أهدافك الدراسية أو التجارية بينما نهتم بتسهيل إجراءات الحصول على التأشيرة. اختر البساطة معنا.',
              businesstitle: 'تأشيرة الأعمال',
              studenttitle: 'تأشيرة دراسة',
              residencetitle: 'تأشيرة إقامة',
              touristtitle: 'تأشيرة سياحية',
              businessvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العمل في إسبانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات العمل في إسبانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة العمل في إسبانيا. تعرف على متطلبات تأشيرة العمل في إسبانيا، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة العمل في إسبانيا بنجاح.',
                title: 'تأشيرة الأعمال في اسبانيا',
                sub1: 'تأشيرة الأعمال في اسبانيا هي تصريح تمنحه الحكومة الاسبانية للأشخاص الذين يرغبون في العمل في اسبانيا بصفة مؤقتة أو دائمة.',
                sub2: ' في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير الوصول السلس إلى تأشيرة العمل في اسبانيا. خدماتنا المتخصصة تضمن عملية طلب سهلة، مما يتيح لك التركيز على أهداف عملك بينما نتولى تفاصيل حصولك على التأشيرة.',
                requirements: 'متطلبات تأشيرة الأعمال في اسبانيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صور شخصية ',
                photo2: '2 صور شخصية على خلفية بيضاء',
                invitation: 'دعوة عمل من الشركة الاسبانية',
                bank1:'كشف حساب بنكي',
                bank2: 'صورة كشف حساب بنكي أخر 3 أشهر',
                attravail: 'إفادة عمل أو رخصة شركة',  
              },
              studentvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة الدراسة في إسبانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات الطلاب في إسبانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة الدراسة في إسبانيا. تعرف على متطلبات تأشيرة الدراسة في إسبانيا، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة الدراسة في إسبانيا بنجاح.',
                title: 'تأشيرة الدراسة في اسبانيا',
                sub1: 'ابدأ رحلتك الأكاديمية في اسبانيا مع تأشيرة الدراسة، التي صممت خصيصًا للأفراد الطامحين في متابعة تعليمهم في هذه الدولة الغنية ثقافيًا. سواء كنت تهدف إلى التسجيل في جامعة، أو حضور دورات متخصصة، أو المشاركة في مشاريع البحث، تفتح تأشيرة الدراسة أبوابًا لعالم من فرص التعلم.',
                sub2: 'في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير دعم شامل لتأشيرة الدراسة في اسبانيا. خدماتنا المخصصة تضمن عملية طلب خالية من المتاعب، مما يتيح لك التركيز على أهداف تعليمك بينما نتولى التفاصيل الصعبة للحصول على التأشيرة',
                requirements: 'متطلبات تأشيرة الدراسة في اسبانيا :', 
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صور شخصية2 ',
                photo2: '2 صور شخصية على خلفية بيضاء',
                diploma: 'صورة من شهادة ختم التعليم الثانوي أو  آخر شهادة متحصل عليها',
              },
              residencevisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة الإقامة في إسبانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات الإقامة في إسبانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة الإقامة في إسبانيا. تعرف على متطلبات تأشيرة الإقامة في إسبانيا، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة الإقامة في إسبانيا بنجاح.',
                title: 'تأشيرة الإقامة في اسبانيا',
                sub1: 'تأشيرة الإقامة في إسبانيا هي نوع من التأشيرات تسمح للأفراد بالعيش في إسبانيا لفترة طويلة.',
                requirements: 'متطلبات تأشيرة الإقامة في اسبانيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صور شخصية ',
                photo2: '2 صور شخصية على خلفية بيضاء',
                att: 'رخصة الشركة أو السجل التجاري',
                chamber: 'شهادة تسجيل من الغرفة التجارية', 
                fam: 'شهادة وضع عائلي',
              },
              touristvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة السياحة في إسبانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات السياحة في إسبانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة سياحية إلى إسبانيا. تعرف على متطلبات تأشيرة السياحة في إسبانيا، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة السياحة في إسبانيا بنجاح.',
                title: 'تأشيرة سياحية لاسبانيا',
                sub1: 'تقدم خدمتنا عملية سلسة وفعالة للحصول على تأشيرة سياحية إلى اسبانيا. سواء كنت تخطط لاستكشاف المدن الساحرة، أو للانغماس في التاريخ الغني، أو لتذوق التجارب الثقافية المتنوعة في اسبانيا، نحن هنا لمساعدتك. يضمن فريقنا عملية تقديم خالية من المتاعب، مرافقينك خلال الخطوات الضرورية لتأمين تأشيرتك بسهولة.',
                sub2: 'اكتشف جمال اسبانيا بثقة، علمًا بأن خدمتنا مخصصة لجعل تجربتك السفر لا تنسى وخالية من التوتر.',
                requirements: 'متطلبات تأشيرة سياحية في اسبانيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صور شخصية ',
                photo2: '2 صور شخصية على خلفية بيضاء',
                salary: 'شهادة مرتب أخر 3 أشهر',
                cnss: 'كشف الصندوق الوطني للضمان الاجتماعي',
                bank1:'كشف حساب بنكي',
                bank2: 'صورة كشف حساب بنكي أخر 3 أشهر',
                attravail: 'إفادة عمل أو رخصة شركة',  
              },
            },
            uk: {
              seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة بريطانيا',
              seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات بريطانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة إلى بريطانيا. تعرف على متطلبات تأشيرة بريطانيا، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة بريطانيا بنجاح.',
              subtitle: 'رحلتك تبدأ هنا: توجيهك في تأشيرات بريطانيا معنا',
              h6: 'تأشيرة إلى بريطانيا',
              description1: 'مرحبًا بك في شركة تونس للإستشارات و المساعدة، حليفك المخصص لحلاوة الحصول على تأشيرات للمملكة المتحدة.',
              description2: 'سواء كنت تبحث عن الرعاية الطبية، أو تسعى للتعليم، أو تستكشف المعالم السياحية، أو تستكشف فرص الأعمال، نحن هنا لتبسيط عملية الحصول على التأشيرة، مما يضمن أن طريقك إلى بريطانيا يكون أملسًا قدر الإمكان.',
              businesstitle: 'تأشيرة الأعمال',
              studenttitle: 'تأشيرة دراسة',
              medicaltitle: 'تأشيرة علاج',
              touristtitle: 'تأشيرة سياحية',
              businessvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة الأعمال في بريطانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات الأعمال في بريطانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة عمل إلى المملكة المتحدة. تعرف على متطلبات تأشيرة الأعمال في المملكة المتحدة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة الأعمال بنجاح.',
                title: 'تأشيرة الأعمال في بريطانيا',
                sub1: 'في شركة تونس للإستشارات و المساعدة, ندرك الدور الحيوي للتواصل العالمي في مجال الأعمال.',
                sub2: 'تم تصميم خدمات تأشيرات الأعمال لدينا لتسهيل دخولك إلى المشهد الأعمال الديناميكي في بريطانيا. سواء كنت تنتمي إلى مجال التنفيذيين الشركاتيين أو رواد الأعمال، فإن دعمنا المخصص يضمن ازدهار مشاريعك في سوق بريطانيا.',
                requirements: 'متطلبات تأشيرة رجال الأعمال في بريطانيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صور شخصية ',
                photo2: '2 صور شخصية على خلفية بيضاء',
                inv: 'دعوة عمل من الشركة البريطانية',
                ins1: 'تأمين صحي من بريطانيا',
                ins2: 'مع امكانية توفيرها من مكتبنا',
                prop: 'نسخ من شهائد الأملاك الشخصية',
              },
              studentvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة الدراسة في بريطانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات الطلاب في المملكة المتحدة. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة دراسية إلى المملكة المتحدة. تعرف على متطلبات تأشيرة الطالب في المملكة المتحدة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة الطالب بنجاح.',
                title: 'تأشيرة الدراسة في بريطانيا',
                sub1: 'ابدأ في رحلة تحول تعليمية مع شركة تونس للإستشارات و المساعدة. تم تصميم خدمات تأشيرات الطلاب لدينا لمساعدة الطلاب في تحقيق أحلامهم الأكاديمية في بريطانيا.',
                sub2: ' من إرشادات التقديم إلى معالجة التأشيرة، نلتزم بدعمك في كل مرحلة، مما يفتح الطريق لرحلة أكاديمية ناجحة.',
                requirements: 'متطلبات تأشيرة الدراسة في بريطانيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صور شخصية ',
                photo2: '2 صور شخصية على خلفية بيضاء',
                inv: 'رسالة القبول الجامعي مع البرنامج الدراسي ان وجدت',
                res: 'دفع 3 أشهر مدة الإقامة على الأقل أو مستندات الدمج العائلي',
                ins1: 'تأمين صحي من بريطانيا',
                ins2: 'مع امكانية توفيرها من مكتبنا',
              },
              medicalvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العلاج الطبي في بريطانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات العلاج الطبي في بريطانيا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة علاج طبي إلى بريطانيا. تعرف على متطلبات تأشيرة العلاج الطبي في المملكة المتحدة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة العلاج الطبي بنجاح.',
                title: 'تأشيرة العلاج في بريطانيا',
                sub1: 'في شركة تونس للإستشارات و المساعدة، ندرك أهمية رحلتك الصحية. تم تصميم خدمات تأشيرات الرعاية الطبية لدينا لتوفير تجربة ميسرة للأفراد الذين يبحثون عن العلاج الطبي في بريطانيا. ',
                sub2: 'ثق بنا في التعامل مع عملية الحصول على التأشيرة، مما يتيح لك التركيز على رعايتك بسلام البال.',
                requirements: 'متطلبات تأشيرة العلاج في بريطانيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صور شخصية ',
                photo2: '2 صور شخصية على خلفية بيضاء',
                invitation: 'دعوة من المستشفى',
                cert1: 'تقرير الكشف الطبي',
                cert2: 'تقرير طبي يوضح الحالة الطبية للمريض',
                payment: 'دفع فواتير المستشفى',
                ins1: 'تأمين صحي من بريطانيا',
                ins2: 'مع امكانية توفيرها من مكتبنا',
                prop: 'نسخ من شهائد الأملاك الشخصية',
                att: 'إفادة عمل أو رخصة شركة ان وجدت',
                bank1:'كشف حساب بنكي ان وجد',
                bank2: 'صورة كشف حساب بنكي أخر 3 أشهر',
              },
              touristvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة السياحة في بريطانيا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات السياحة في المملكة المتحدة. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة سياحية إلى المملكة المتحدة. تعرف على متطلبات تأشيرة السياحة في المملكة المتحدة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة السياحة إلى المملكة المتحدة بنجاح.',
                title: 'تأشيرة سياحية لبريطانيا',
                sub1: 'اكتشف سحر بريطانيا بسهولة. شركة تونس للإستشارات و المساعدة يبسط عملية التقديم لتأشيرة السياحة، مما يضمن تجربة سفر ميسرة.',
                sub2: 'استكشف عجائب بريطانيا بثقة، على علم بأن فريقنا المخصص ملتزم بجعل رحلتك بدون ضغوط وممتعة.',
                requirements: 'متطلبات التأشيرة السياحية لبريطانيا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: ' يجب أن يكون جواز السفر ساري المفعول لمدة 6 أشهر على الأقل و يحتوي على صفحات فارغة لوضع التأشيرة.',    
                photo1: 'صور شخصية ',
                photo2: '2 صور شخصية على خلفية بيضاء',
                att: 'رسالة عمل أو رخصة شركة ان وجدت',
                fam: 'شهادة وضع عائلي',
                bank1:'كشف حساب بنكي ان وجد',
                bank2: 'صورة كشف حساب بنكي أخر 3 أشهر',
                prop: 'نسخ من شهائد الأملاك الشخصية',
                ins1: 'تأمين صحي من بريطانيا',
                ins2: 'مع امكانية توفيرها من مكتبنا',
              },
            },
            belgium: {
              seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرات بلجيكا',
              seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات بلجيكا. يقدم فريقنا المتخصص مساعدة شاملة لمختلف أنواع التأشيرات البلجيكية. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة بلجيكا ناجح.',
              subtitle: 'فتح أفق الرحلات بسهولة: دليلك الشامل لحلول التأشيرات السهلة إلى بلجيكا',
              h6: 'تأشيرة إلى بلجيكا',
              description1: 'في شركة تونس للاستشارات و المساعدة، نختص في تسهيل عملية تقديم الفيزا، خاصة لتأشيرة العلاج وتأشيرة الأعمال المتجهة إلى بلجيكا.',
              description2: 'خدماتنا الميسّرة تضمن لك تجربة سهلة وفعّالة، مما يتيح لك التركيز على أهدافك الطبية أو التجارية بينما نهتم بتسهيل إجراءات الحصول على التأشيرة. اختر البساطة معنا.',
              businesstitle: 'تأشيرة الأعمال',
              medicaltitle: 'تأشيرة علاج',
              touristtitle: 'تأشيرة سياحية',
              businessvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العمل في بلجيكا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات العمل في بلجيكا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة عمل إلى بلجيكا. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة العمل في بلجيكا بنجاح.',
                title: 'تأشيرة الأعمال في بلجيكا',
                sub1: 'تأشيرة العمل في بلجيكا هي تصريح تمنحه الحكومة البلجيكية للأشخاص الذين يرغبون في العمل في بلجيكا بصفة مؤقتة أو دائمة.',
                sub2: ' في شركة تونس للإستشارات و المساعدة، نختص في تسهيل وتوفير الوصول السلس إلى تأشيرة العمل في بلجيكا. خدماتنا المتخصصة تضمن عملية طلب سهلة، مما يتيح لك التركيز على أهداف عملك بينما نتولى تفاصيل حصولك على التأشيرة.',
                requirements: 'متطلبات تأشيرة الأعمال في بلجيكا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من بلجيكا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                invitation: 'دعوة عمل من الشركة البلجيكية',
                fam: 'شهادة وضع عائلي',
                bank1:'كشف حساب بنكي',
                bank2: 'صورة كشف حساب بنكي أخر 3 أشهر',
                attravail: 'إفادة عمل أو رخصة شركة',
                invoices: 'فواتير من الشركة البلجيكية ان وجدت',
              },
              medicalvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة العلاج الطبي في بلجيكا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات العلاج الطبي في بلجيكا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة علاج طبي إلى بلجيكا. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة العلاج الطبي في بلجيكا بنجاح.',
                title: 'تأشيرة العلاج في بلجيكا',
                sub1: 'ابدأ رحلة الشفاء والعافية مع تأشيرة العلاج الطبي في بلجيكا، المصممة للأفراد الذين يسعون لتلقي العلاج الطبي في هذا البلد الغني بالتنوع والحيوية. سواء كان الأمر يتعلق بإجراءات طبية متخصصة، أو استشارات طبية، أو تداخلات علاجية، تسهل تأشيرة العلاج الطبي الوصول إلى مرافق الرعاية الصحية المشهورة في بلجيكا.',
                sub2: 'في شركة تونس للإستشارات و المساعدة، نتخصص في تسهيل عملية الحصول على تأشيرة العلاج الطبي في بلجيكا، وتقديم الدعم الرفيق لأولئك الذين يحتاجون إلى رعاية طبية. خدماتنا المخصصة تضمن عملية طلب سلسة، مما يتيح لك أن تولي اهتمامك لصحتك بينما نتعامل مع تفاصيل الحصول على التأشيرة.',
                requirements: 'متطلبات تأشيرة العلاج في بلجيكا',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من بلجيكا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                cert1: 'تقرير طبي',
                cert2: 'تقرير طبي يوضح الحالة الطبية للمريض (بالإنقليزية)',
                inv1: 'دعوة من المستشفى',
                inv2: 'تحتوي على تفاصيل الموعد مع الطبيب و قيمة العلاج و اسم المريض و مرافقه و رقم الجوازات',
                transfer1: 'تحويل البنكي SWIFT',
                transfer2: 'التحويل البنكي لقيمة العلاج المذكورة في دعوة المستشفى',
                minor: 'اذا كان المريض صغير :',
                parpass: 'صورة من جواز ولي الأمر',
                cert: 'شهادة ميلاد',
                fam: 'شهادة وضع عائلي',
                evidence: 'شهادة كفالة المصاريف من الأب (تحرر عند محرر العقود)',
              },
              touristvisa: {
                seoTitle: 'تونس للإستشارات والمساعدة: خدمات تأشيرة السياحة في بلجيكا',
                seodescription: 'استكشف خدمات تونس للإستشارات والمساعدة في مجال تأشيرات السياحة في بلجيكا. يقدم فريقنا المتخصص مساعدة شاملة للحصول على تأشيرة سياحية إلى بلجيكا. تعرف على متطلبات التأشيرة، وعمليات التقديم، واحصل على إرشادات خبيرة لتقديم طلب تأشيرة السياحة في بلجيكا بنجاح.',
                title: 'تأشيرة سياحية لبلجيكا',
                sub1: 'تقدم خدمتنا عملية سلسة وفعالة للحصول على تأشيرة سياحية إلى بلجيكا. سواء كنت تخطط لاستكشاف المدن الساحرة، أو للانغماس في التاريخ الغني، أو لتذوق التجارب الثقافية المتنوعة في بلجيكا، نحن هنا لمساعدتك. يضمن فريقنا عملية تقديم خالية من المتاعب، مرافقينك خلال الخطوات الضرورية لتأمين تأشيرتك بسهولة.',
                sub2: 'اكتشف جمال بلجيكا بثقة، علمًا بأن خدمتنا مخصصة لجعل تجربتك السفر لا تنسى وخالية من التوتر.',
                presence: 'امكانية تقديم بدون حضور في حالة وجود تأشيرة سابقة لم تتجاوز 5 سنوات',
                requirements: 'متطلبات تأشيرة سياحية في بلجيكا :',
                pass1: 'صورة صفحة البيانات من جواز السفر',
                pass2: 'يجب على الجواز أن يكون صالحاً لمدّة ثلاثة أشهر على الأقل ابتداءً من تاريخ العودة المترقبة من بلجيكا (أو مساحة شنغن) وأن يحتوي على الأقل على صفحتين فارغتين.',
                photo1: 'صور شخصية ',
                photo2: '2 صورة شخصية على خلفية بيضاء وبأبعاد 4x3',
                fam: 'شهادة وضع عائلي',
                attravail: 'إفادة عمل أو رخصة شركة',
                bank: 'كشف حساب بنكي أخر 3 أشهر',
              },
            },
          footer: {
            link1: 'روابط سريعة',
            link2: 'بيانات الاتصال',
            bottom: 'جميع الحقوق محفوظة © 2023 شركة تونس للإستشارات و المساعدة'
          }
        }
      }
    }
  });

export default i18n;