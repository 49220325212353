import React, { useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import pass from '../../../../assets/img/pass.jpg';
import passport from '../../../../assets/img/passport.png';
import { isArabic } from '../../../../languageUtils';
import persoPhoto from '../../../../assets/img/personal_photo.png';
import close from '../../../../assets/img/close.png';
import bankAccount from '../../../../assets/img/financial.png';
import { NavLink } from 'react-router-dom';
import { Element} from 'react-scroll';
import {Animated} from "react-animated-css";
import india from '../../../../assets/img/india1.jpg';
import invitation from '../../../../assets/img/invitation.png';
import diploma from '../../../../assets/img/diploma.png';
import receipt from '../../../../assets/img/receipt.png';
import evidence from '../../../../assets/img/evidence.png';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';

const IndiaStudentVisa = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
const [clickedImage, setClickedImage] = useState('');
const handleImageClick = (imageUrl) => {
    setClickedImage(imageUrl);
    setShowModal(true);
  };
    
  ReactGA.pageview(window.location.pathname + window.location.search);

    return(
        <div id='indiavisa'>
          <Helmet>
        <title>{t('india.studentvisa.seoTitle')}</title>
        <meta
          name='description'
          content={t('india.studentvisa.seodescription')}
        />
        <meta
          name='keywords'
          content='Tunis Consulting & Assisting, Tunis Consulting and Assisting, TCA, Tunis Consulting, India Student Visa, Student Visa Assistance, India Student Visa Requirements, Student Visa Application Process, India Student Travel, Tunis Consulting Services, India Student Visa Documents, Student Visa Application Support, India Study Visa, تونس للإستشارات, تونس للإستشارات و المساعدة, تأشيرة الطلاب إلى الهند, تونس للإستشارات و المساعدة, مساعدة في تأشيرة الطلاب إلى الهند, تونس للإستشارات و المساعدة, متطلبات تأشيرة الطلاب إلى الهند, تونس للإستشارات و المساعدة, عملية تقديم تأشيرة الطلاب إلى الهند, تونس للإستشارات و المساعدة, السفر الطلاب إلى الهند, تونس للإستشارات و المساعدة, خدمات تونس للإستشارات, تونس للإستشارات و المساعدة, وثائق تأشيرة الطلاب إلى الهند, تونس للإستشارات و المساعدة, دعم تقديم تأشيرة الطلاب إلى الهند, تونس للإستشارات و المساعدة, تأشيرة دراسة الهند'
        />
      </Helmet>
          <Element>
          <Animated animationIn="slideInLeft" animationOut="fadeOut">
          <div id='cover'>
           <div>
        <div
          className='cover-content'
          style={{ backgroundImage: `url(${india})` }}
        >
            <div className='intro container text-center text-light'>
            <h1 className='title'>{t('india.studentvisa.title')}</h1>
            <NavLink to='/Visa/india/student-visa/application' className="nav-link btn"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> {t('registration.button')}</NavLink>
          </div>
        </div>
      </div>
        </div>
          <div className='container' style={{textAlign: isArabic() ? 'right' : 'left'}}>
        <h2 className='mt-4'>{t('india.studentvisa.title')}</h2>
        <p style={{marginBottom: 0}}>{t('india.studentvisa.sub1')}</p>
        <p>{t('india.studentvisa.sub2')}</p>
        <h5 className='req'>{t('india.studentvisa.requirements')}</h5>
        <Row xs={2} md={3} xl={3}>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${pass})`}} onClick={() => handleImageClick(pass)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.studentvisa.pass1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('india.studentvisa.pass2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${persoPhoto})`}} onClick={() => handleImageClick(persoPhoto)}></div>
              <h5 className='card-title text-center mt-4'>
                {t('india.studentvisa.photo1')}
                </h5>
                <p style={{textAlign: 'center'}} className='card-text'>
                {t('india.studentvisa.photo2')}
                    </p>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${diploma})`}} onClick={() => handleImageClick(diploma)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.studentvisa.diploma')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${invitation})`}} onClick={() => handleImageClick(invitation)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.studentvisa.invitation')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${receipt})`}} onClick={() => handleImageClick(receipt)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.studentvisa.receipt')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${passport})`}} onClick={() => handleImageClick(passport)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.studentvisa.parpass')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${bankAccount})`}} onClick={() => handleImageClick(bankAccount)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.studentvisa.parbank')}
                </h5>
                </div>
          </div>
            </Col>
            <Col>
            <div className='card rounded-0 mr-2 ml-2'>
            <div className='card-body'>
                <div className='photo-icon' style={{backgroundImage: `url(${evidence})`}} onClick={() => handleImageClick(evidence)}></div>
              <h5 className='card-title text-center mt-2'>
                {t('india.studentvisa.evidence')}
                </h5>
                </div>
          </div>
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header style={{ position: 'relative' }}>
  <button
    className="close"
    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1050' }}
    onClick={() => setShowModal(false)}
  >
    <span aria-hidden="true"><img src={close} alt='close'/></span>
  </button>
  </Modal.Header>
  <Modal.Body>
    <img src={clickedImage} alt="Clicked" style={{ width: '100%', height: 'auto' }} />
  </Modal.Body>
</Modal>
        </div>
        </Animated>
      </Element>
        </div>
    );
};
export default IndiaStudentVisa;